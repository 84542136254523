import { Box, Divider, Stack } from "@mui/material";
import AtsBackLink from "../../../../../../components/AtsBackLink";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { setIsInvoiceView } from "../../../../../../redux/slices/invoicesSlice";
import { useAppDispatch } from "../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../theme";
import InvoiceInfo from "../components/DetailsOfInvoiceComponents/InvoiceInfo";
import InvoiceItemsList from "../components/DetailsOfInvoiceComponents/InvoiceItemsList";
import TotalOfInvoice from "../components/DetailsOfInvoiceComponents/TotalOfInvoice";

const InvoiceDetails = () => {
  const dispatch = useAppDispatch();
  return (
    <Box mb={"10px"}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={{ xs: "center", md: "space-between" }}
        pt={"4px"}
        pb={"18px"}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          gap={"20px"}
        >
          <AtsTitleText
            text={"#00000002"}
            fs={18}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Divider variant="fullWidth" orientation="vertical" flexItem />
          <AtsRoundedButton
            text="Not Paid"
            buttonVariant="primaryContained"
            buttonHeight={30}
          />
          <Divider variant="fullWidth" orientation="vertical" flexItem />
          <AtsRoundedButton
            text="Proced to Payment"
            buttonHeight={30}
            buttonWidth={175}
          />
        </Stack>
        <Box>
          <AtsBackLink
            text="Back to invoices"
            onClick={() => dispatch(setIsInvoiceView(false))}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          borderRadius: "6px",
          border: BORDERS.GRAY,
          pt: "39px",
          pb: "54px",
          px: "40px",
        }}
      >
        <Box pb={"50px"}>
          <InvoiceInfo />
        </Box>
        <Box pb={"50px"}>
          <InvoiceItemsList />
        </Box>
        <Box>
          <TotalOfInvoice />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDetails;
