import React from "react";
import Chart from "react-apexcharts";

interface DonutChartProps {
  title: string;
  series: number[];
  labels: string[];
}

const DonutChart: React.FC<DonutChartProps> = ({ title, series, labels }) => {
  console.log("series, labels", series, labels);
  if (!series || !labels || series.length === 0 || labels.length === 0) {
    console.error("Invalid series or labels provided:", { series, labels });
    return <div>Error: Invalid data</div>;
  }
  const seriesArr = series || [];
  const labelsArr = labels || [];
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "donut",
      height: 120,
      fontFamily: "Inter",
    },
    labels: labelsArr,
    legend: {
      position: "right",
      offsetX: 20,
      offsetY: 0,
      markers: {
        shape: "circle",
        size: 5,
        offsetX: -5,
        offsetY: 0,
      },
      itemMargin: {
        vertical: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0, // Remove any stroke around the slices
    },
    colors: ["#3366cc", "#63abfd", "#2ba899"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <Chart options={options} series={seriesArr} type="donut" height={120} />
    </div>
  );
};

export default DonutChart;
