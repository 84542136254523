import { Stack } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";

const ColleaguesPavilionDetails = () => {
  const { font14, font16 } = FontUtil();
  const colleaguesPavilion = `Your resources are protected by Colleagues Pavilion. For contractors or permanent workers booked through Esteemed Talent, Pavilion provides the most comprehensive freelance protection on the market, including liability insurance, worker’s compensation, workplace theft protection, and well-being services.`;
  return (
    <>
      <Stack gap={1.5}>
        <AtsText
          text={"Colleagues Pavilion"}
          sx={{
            ...font16,
            color: COLORS.BLACK,
            fontWeight: 600,
          }}
        />
        <AtsText
          text={colleaguesPavilion}
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
      </Stack>
    </>
  );
};

export default ColleaguesPavilionDetails;
