import { Stack } from "@mui/material";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { COLORS } from "../theme";
import AtsTitleText from "./AtsTitleText";

interface AtsInternationalInputProps extends PhoneInputProps {
  title?: string;
  subText?: string;
}

const AtsInternationalInput = ({
  title,
  subText,
  ...rest
}: AtsInternationalInputProps) => {
  return (
    <>
      <Stack gap={1}>
        {title && (
          <AtsTitleText
            text={title}
            fs={14}
            fw={600}
            textColor={COLORS.BLACK}
          />
        )}
        <PhoneInput country={"us"} {...rest} enableSearch />
        {subText && (
          <AtsTitleText
            text={subText}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        )}
      </Stack>
    </>
  );
};

export default AtsInternationalInput;
