import { getInvoicesState } from "../../../../../redux/slices/invoicesSlice";
import { useAppSelector } from "../../../../../redux/store";
import InvoiceDetails from "./modules/InvoiceDetails";
import InvoicesList from "./modules/InvoicesList";

const Invoices = () => {
  const { isInvoiceView } = useAppSelector(getInvoicesState);
  return <>{isInvoiceView ? <InvoiceDetails /> : <InvoicesList />}</>;
};

export default Invoices;
