import { Box, Stack } from "@mui/material";
import React from "react";
import StarIconRating from "../../../../../../../assets/images/startIcon.svg";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { SaveAlt, Share } from "@mui/icons-material";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsTitleText from "../../../../../../../components/AtsTitleText";

interface CompanyDetails {
  name: string;
  address: string;
  state: string;
  phone: string;
  email: string;
  website: string;
}

interface InfoSection {
  title: string;
  details: CompanyDetails;
}

interface Info {
  companyInfo: InfoSection;
  billedToInfo: InfoSection;
}

const info: Info = {
  companyInfo: {
    title: "Esteemed Inc.",
    details: {
      name: "Esteemed Inc.",
      address: "5430 66th Ave NW",
      state: "Olympia, WA 98502 US",
      phone: "+1 3607127417",
      email: "finance@esteemed.io",
      website: "www.esteemed.io",
    },
  },
  billedToInfo: {
    title: "Billed to",
    details: {
      name: "Nvidia Corporation",
      address: "2701 San Tomas Expressway",
      state: "Santa Clara, CA 95050 US",
      phone: "+1 408-486-2000",
      email: "finance@nvidia.com",
      website: "www.nvidia.com",
    },
  },
};

const InvoiceInfo = () => {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <img src={StarIconRating} alt="Nvidia Logo" style={{ width: 57 }} />
        </Box>
        <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
          <AtsCustomButton px={0.5} py={0.5}>
            <SaveAlt
              sx={{
                fontSize: 16,
                color: COLORS.LIGHT_GRAY,
              }}
            />
          </AtsCustomButton>
          <AtsCustomButton px={0.5} py={0.5}>
            <AtsIcon icon="icon-printer" height="14px" />
          </AtsCustomButton>
          <AtsCustomButton px={0.5} py={0.5}>
            <Share
              sx={{
                fontSize: 16,
                color: COLORS.LIGHT_GRAY,
              }}
            />
          </AtsCustomButton>
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={"end"}
        flexWrap={"wrap"}
      >
        <Stack>
          <Stack
            mt={{ xs: "10px", md: "24px" }}
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
            gap={"20px"}
          >
            <Stack gap={"12px"} pb={"28px"}>
              {renderCompanyInfo(info, "companyInfo")}
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
            gap={"20px"}
          >
            <Stack gap={"12px"}>
              {renderCompanyInfo(info, "billedToInfo")}
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            py: { xs: 2, md: "28px" },
            px: { xs: 2, md: "24px" },
            border: BORDERS.GRAY,
            bgcolor: "#f9f9f9",
            borderRadius: "6px",
            minWidth: { xs: "100%", md: 285 },
          }}
        >
          <Stack gap={"25px"}>
            <AtsTitleText text={"Invoice #00000002"} fs={18} fw={600} />
            <Stack gap={"10px"}>
              {renderInvoiceComponent("Invoice Date", "Jul 19, 2024")}
              {renderInvoiceComponent("Due Date", "Aug 18, 2024")}
              {renderInvoiceComponent("Terms", "Net 30")}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default InvoiceInfo;

const renderInvoiceComponent = (key: string, value: string) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box>
        <AtsTitleText
          text={key}
          fs={16}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
        />
      </Box>
      <Box>
        <AtsTitleText text={value} fs={16} fw={400} />
      </Box>
    </Stack>
  );
};

const renderText = (
  line1: string,
  line2: string,
  line3: string,
  isLink?: boolean
) => {
  return (
    <Stack gap={1} sx={{ minWidth: { xs: "100%", sm: 150, md: 200 } }}>
      <AtsTitleText text={line1} fs={14} fw={500} textColor="#5d5d5d" />
      <AtsTitleText text={line2} fs={14} fw={500} textColor="#5d5d5d" />
      {isLink ? (
        <a
          target="_blank"
          href={`https://${line3}`}
          style={{
            color: COLORS.PRIMARY,
            fontSize: 14,
            textDecoration: "none",
          }}
          rel="noreferrer"
        >
          {line3}
        </a>
      ) : (
        <AtsTitleText text={line3} fs={14} fw={500} textColor="#5d5d5d" />
      )}
    </Stack>
  );
};

const renderCompanyInfo = (info: Info, key: string) => {
  const data: InfoSection = info[key];
  const details: CompanyDetails = data.details;
  return (
    <>
      <AtsTitleText text={data.title} fs={16} fw={600} />
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        gap={"60px"}
      >
        {renderText(details.name, details.address, details.state)}
        {renderText(details.phone, details.email, details.website, true)}
      </Stack>
    </>
  );
};
