import { MoreVert } from "@mui/icons-material";
import { Box, Collapse, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { ApproveIcon, ArchiveIcon, PinIcon } from "../../../assets/icons/Icons";
import AtsCard from "../../../components/AtsCard";
import AtsLabel from "../../../components/AtsLabel";
import AtsText from "../../../components/AtsText";
import { BORDERS } from "../../../theme";
import { useClickOutSide } from "../../../utils/common";
import FontUtil from "../../../utils/FontUtil";
import AtsIcon from "../../../components/AtsIcon";

interface ITaskProps {
  index: number;
  status: string;
  taskDes: string;
  dueTime: string;
  onClose: (index: number) => void;
}

const TaskCard = ({ index, status, taskDes, dueTime, onClose }: ITaskProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  const { font11, font12, font16, font10 } = FontUtil();
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const onSelectTask = () => {
    setShow(!show);
  };
  return (
    <>
      <AtsCard
        key={index}
        sx={{
          minHeight: 104,
          width: !isLargeXl && isLarge ? 190 : 184,
        }}
      >
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AtsLabel
              text={status}
              variant={status === "PENDING" ? "warninig" : "success"}
            />
            <Box ref={domNode} sx={{ position: "relative", cursor: "pointer" }}>
              <Box onClick={() => onSelectTask()}>
                <MoreVert sx={{ ...font16 }} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "0px",
                  top: "20px",
                  zIndex: 9999,
                  width: "100px",
                }}
              >
                <Collapse
                  sx={{
                    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                    borderRadius: "6px",
                  }}
                  in={show}
                >
                  <Box
                    sx={{
                      border: BORDERS.GRAY,
                      borderRadius: "6px",
                      boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      bgcolor: "white",
                    }}
                  >
                    <Stack gap={1}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <PinIcon />
                        <AtsText
                          text="Pin it"
                          sx={{ ...font10, fontWeight: 600 }}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AtsIcon
                          icon="icon-profile-add"
                          width="14px"
                          height="14px"
                        />
                        <AtsText
                          text="Assign to"
                          sx={{ ...font10, fontWeight: 600 }}
                        />
                      </Stack>
                      <Stack
                        pb={1}
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
                      >
                        <Box mr={"2px"}>
                          <ArchiveIcon />
                        </Box>
                        <AtsText
                          text="Archive"
                          sx={{ ...font10, fontWeight: 600 }}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AtsIcon icon="icon-trash" />

                        <AtsText
                          text="Delete"
                          sx={{ ...font10, fontWeight: 600 }}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Stack>
          <Stack
            pt={1}
            pb={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ borderBottom: BORDERS.LIGHT_GRAY }}
          >
            <Stack sx={{ minHeight: 32 }}>
              <AtsText
                text={taskDes}
                className="truncate_two_line"
                sx={{
                  ...font12,
                  fontWeight: 600,
                  lineHeight: 1.33,
                  width: "153px",
                  textDecoration: status === "CLOSED" ? "line-through" : "",
                  color: status === "CLOSED" ? "var(--text-gray)" : "black",
                }}
              />
            </Stack>
            {status === "CLOSED" ? (
              <ApproveIcon />
            ) : (
              <Stack
                sx={{
                  height: "17px",
                  width: "17px",
                  flexGrow: 0,
                  border: BORDERS.GRAY,
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
                onClick={() => onClose(index)}
              ></Stack>
            )}
          </Stack>
          <Stack gap={0.5} pt={0.5} direction={"row"} alignItems={"center"}>
            <AtsText
              text="Due On:"
              sx={{ color: "var(--text-gray)", ...font11, fontWeight: 400 }}
            />
            <AtsText text={dueTime} sx={{ ...font11, fontWeight: 400 }} />
          </Stack>
        </Stack>
      </AtsCard>
    </>
  );
};

export default TaskCard;
