import { Avatar, Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";

interface IProps {
  contractorName: string;
  projectName: string;
  role: string;
  renderBottomView: JSX.Element;
}

const ContractorComponent = ({
  contractorName,
  projectName,
  role,
  renderBottomView,
}: IProps) => {
  return (
    <>
      <Box
        sx={{
          p: "10px",
          bgcolor: BG_COLORS.GRAY,
          borderRadius: "5PX",
        }}
      >
        <Stack
          gap={"16px"}
          sx={{
            borderBottom: BORDERS.GRAY,
            pb: "15px",
          }}
        >
          {renderImgTextWrapComponent("Contractor name", contractorName)}
          {renderTextWrapComponent("Project name", projectName)}
          {renderTextWrapComponent("Role", role)}
        </Stack>
        {renderBottomView ?? renderBottomView}
      </Box>
    </>
  );
};

export default ContractorComponent;

const renderImgTextWrapComponent = (title: string, value: string) => {
  return (
    <Stack gap={"8px"}>
      <AtsTitleText text={title} fs={14} fw={600} textColor="#000" />
      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        <Avatar src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" />
        <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
      </Stack>
    </Stack>
  );
};
const renderTextWrapComponent = (title: string, value: string) => {
  return (
    <Stack gap={"8px"}>
      <AtsTitleText text={title} fs={14} fw={600} textColor="#000" />
      <AtsTitleText text={value} fs={14} fw={400} textColor={COLORS.BLACK} />
    </Stack>
  );
};
