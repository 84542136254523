import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import AtsStepper from "../../../../../../../../../../components/AtsStepper";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import {
  ArrowForwardIos,
  DownloadForOfflineOutlined,
} from "@mui/icons-material";

const StatusDetails = () => {
  const [activeStep, setActiveStep] = useState<number>(4);

  const statusStep = [
    {
      status: "Offer Extended",
      date: "June 20, 2024  |  09:00 am",
    },
    {
      status: "Under Negotiation",
      date: "June 21, 2024  |  09:06 am",
    },
    {
      status: "Offer Revised",
      date: "June 21, 2024  |  06:35 pm",
    },
    {
      status: "Offer Accepted",
      date: "June 22, 2024  |  11:30 pm",
    },
  ];
  return (
    <>
      <Stack mx={1}>
        <AtsStepper activeStep={activeStep} stepData={statusStep} />
      </Stack>
      <Stack gap={2} pt={7} mx={1}>
        <AtsRoundedButton
          text="Download Offer"
          buttonVariant="outline"
          startIcon={<DownloadForOfflineOutlined />}
        />
        <AtsRoundedButton
          text="Onboard Contractor"
          endIcon={<ArrowForwardIos />}
        />
      </Stack>
    </>
  );
};

export default StatusDetails;
