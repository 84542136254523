import { Stack } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";

const ShortTermDetails = () => {
  const { font14, font16 } = FontUtil();
  const shortTermContent = `For payments under $2000, you are required to add a payment method. By adding a payment method, you agree that you will allow Esteemed to charge your credit card, bank account or payment method after the project has been completed.`;
  return (
    <>
      <Stack gap={1.5}>
        <AtsText
          text={"Short-term engagements"}
          sx={{
            ...font16,
            color: COLORS.BLACK,
            fontWeight: 600,
          }}
        />
        <AtsText
          text={shortTermContent}
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
      </Stack>
    </>
  );
};

export default ShortTermDetails;
