import { Add } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AtsRoundedButton from "../../components/AtsRoundedButton";
import AtsTitleText from "../../components/AtsTitleText";
import { getAuthState } from "../../redux/slices/authSlice";
import {
  getDashboardState,
  setIsOpenSubMenu,
} from "../../redux/slices/dashboardSlice";
import { setInitialPostOpportunityState } from "../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ROUTES, RoutesProps, SUB_ROUTES } from "../../router/ATS_routes";
import { ATS_ROUTES } from "../../router/routes";
import { COLORS } from "../../theme";

interface Props {
  isOpen: boolean;
}

const AtsSidebar = ({ isOpen }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { isOpenSubMenu } = useAppSelector(getDashboardState);

  const onRoute = () => {
    dispatch(setInitialPostOpportunityState());
    navigate(ATS_ROUTES.POST_OPPORTUNITY);
  };
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",

      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Stack
      gap={1}
      sx={{
        mt: 2,
        height: "100%",
        ...(isOpen ? { mx: "35px" } : { mx: "15px" }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        className="mb-20"
        onClick={onRoute}
      >
        <AnimatePresence>
          {isOpen ? (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="white_space"
            >
              <AtsRoundedButton text={"Post Opportunity"} startIcon={<Add />} />
            </motion.div>
          ) : (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <Box
                sx={{
                  height: "30px",
                  width: "30px",
                  bgcolor: "var(--primary)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                }}
              >
                <Add />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          gap: "5px",
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "5px",
          }}
        >
          {ROUTES.filter((route) => !route.isHide).map(
            (route: RoutesProps, index) => {
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  onClick={() => {
                    dispatch(setIsOpenSubMenu(false));
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      ...(!isOpen && { marginLeft: "2px" }),
                    }}
                  >
                    {route.path === pathname ? route.activIcon : route.icon}
                  </div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        <AtsTitleText
                          text={route.label}
                          fs={14}
                          fw={route.path === pathname ? 700 : 500}
                          textColor={
                            route.path === pathname
                              ? COLORS.PRIMARY
                              : COLORS.BLACK
                          }
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            }
          )}
        </div>
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginTop: "60px",
          }}
        >
          {SUB_ROUTES.filter((route) =>
            route.access.includes(
              userInfo?.sub_role ? userInfo?.sub_role?.role_name : ""
            )
          ).map((route: RoutesProps, index) => {
            if (route.path.length === 0 && route.children) {
              return (
                <Stack
                  sx={{
                    bgcolor: isOpenSubMenu ? "#f2f6ff" : "white",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    className="link2"
                    onClick={() => {
                      dispatch(setIsOpenSubMenu(!isOpenSubMenu));
                      // setSelectedRoute()
                    }}
                  >
                    <div
                      className="icon"
                      style={{
                        marginTop: "3px",
                        ...(!isOpen && { marginLeft: "2px" }),
                      }}
                    >
                      {route.icon}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          <AtsTitleText
                            text={route.label}
                            fs={14}
                            fw={route?.isMatch.includes(pathname) ? 700 : 500}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>

                  {isOpenSubMenu &&
                    route.children.map((subRoute, index) => (
                      <NavLink to={subRoute.path} key={index} className="link2">
                        <div
                          className="icon"
                          style={{
                            ...(!isOpen && { marginLeft: "2px" }),
                            marginTop: "3px",
                          }}
                        >
                          {subRoute.path === pathname
                            ? subRoute.activIcon
                            : subRoute.icon}
                        </div>
                        <AnimatePresence>
                          {isOpen && (
                            <motion.div
                              variants={showAnimation}
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                              className="link_text"
                            >
                              <AtsTitleText
                                text={subRoute.label}
                                fs={14}
                                fw={subRoute.path === pathname ? 700 : 500}
                                textColor={
                                  subRoute.path === pathname
                                    ? COLORS.PRIMARY
                                    : COLORS.BLACK
                                }
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </NavLink>
                    ))}
                </Stack>
              );
            }
            return (
              <NavLink
                to={route.path}
                key={index}
                className="link2"
                onClick={() => {
                  dispatch(setIsOpenSubMenu(false));
                }}
              >
                <div
                  className="icon"
                  style={{
                    ...(!isOpen && { marginLeft: "2px" }),
                    marginTop: "3px",
                  }}
                >
                  {route.path === pathname ? route.activIcon : route.icon}
                </div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      <AtsTitleText
                        text={route.label}
                        fs={14}
                        fw={route.path === pathname ? 700 : 500}
                        textColor={
                          route.path === pathname
                            ? COLORS.PRIMARY
                            : COLORS.BLACK
                        }
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </div>
      </div>
    </Stack>
  );
};

export default AtsSidebar;
