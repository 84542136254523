import { Stack } from "@mui/material";
import React from "react";
import AtsText from "../../../../../../../../../../components/AtsText";
import { ArrowForwardIos } from "@mui/icons-material";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import { BORDERS_2X, COLORS } from "../../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../../utils/FontUtil";
import { WarningIcon } from "../../../../../../../../assets/Icons";

const OfferDetailsInfo = () => {
  const { font10, font14, font18 } = FontUtil();
  return (
    <>
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsText
            sx={{
              ...font18,
              fontWeight: 600,
              color: COLORS.BLACK,
            }}
            text="Offer Details"
          />
        </Stack>
        <Stack gap={2} sx={{ pt: 2, pb: 2 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
              }}
              text="Duration"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 400,
              }}
              text="40 Hrs/wk for 2 weeks"
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
              }}
              text="Dates"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 400,
              }}
              text="June 24, 2024 -  July 07, 2024"
            />
          </Stack>

          <Stack pt={2}>
            <div className="deviderBorder"></div>
          </Stack>
        </Stack>
        <Stack gap={2} sx={{ pb: 2 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
                borderBottom: "1px solid",
              }}
              text="Rate per hour"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 400,
              }}
              text="$65.00"
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
                borderBottom: "1px solid",
              }}
              text="40 Hrs x 2 Wk"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 400,
              }}
              text="$5,200.00"
            />
          </Stack>
          <Stack pt={2}>
            <div className="deviderBorder"></div>
          </Stack>
        </Stack>
        <Stack gap={2} pb={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={2}
            sx={{ borderBottom: BORDERS_2X.GRAY }}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
              text="Total"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 600,
              }}
              text="$5,200.00"
            />
          </Stack>

          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <WarningIcon />
            <AtsText
              text={
                "Your project won’t be confirmed until your service provider accepts your request (within 24 hours)."
              }
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 400,
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OfferDetailsInfo;
