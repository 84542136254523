import { Box, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import AtsSwitch from "../../../../../../../../../components/AtsSwitch";

import { useState } from "react";
import InterviewDetails from "./InterviewDetails";
import Scorecard from "./Scorecard";

const InterviewInfo = () => {
  const [active, setActive] = useState<string>("INTERVIEW_DETAILS");
  const tabs = [
    {
      title: "Interview Details",
      type: "INTERVIEW_DETAILS",
    },
    {
      title: "Scorecard",
      type: "SCORECARD",
    },
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            borderRadius: 3,
            borderTopLeftRadius: 0,
            mb: 2,
            mx: 1,
          }}
        >
          <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
            <Stack sx={{ width: "100%" }}>
              <Box sx={{ py: 2, px: 2.5 }}>
                <Box sx={{ mt: 2 }}>
                  <AtsSwitch
                    switchWidth={120}
                    switchData={tabs}
                    active={active}
                    onChange={(value) => {
                      setActive(value.title);
                    }}
                  />
                </Box>
                {active === "SCORECARD" ? <Scorecard /> : <InterviewDetails />}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

export default InterviewInfo;
