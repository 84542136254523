import { Box, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import AtsTextInput from "../../../../components/AtsTextInput";
import AtsIcon from "../../../../components/AtsIcon";
import { COLORS } from "../../../../theme";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import {
  createProjectParams,
  ProjectAddressProps,
} from "../../../../types/projectTypes";

const ProjectLoaction = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  const [mapCenter, setMapCenter] = useState(center);
  const [searchQuery, setSearchQuery] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);

  const getCoordinatesFromAddress = async () => {
    const address = `${createProjectState.location.address_line1} ${createProjectState.location.locality} ${createProjectState.location.administrative_area} ${createProjectState.location.country}`;
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setMapCenter({ lat: location.lat, lng: location.lng });
      } else {
        console.error("Geocoding failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
    }
  };

  useEffect(() => {
    const address = createProjectState.location as ProjectAddressProps;
    if (
      address.locality ||
      address.address_line1 ||
      address.country ||
      address.administrative_area ||
      address.postal_code
    ) {
      setSearchQuery(
        `${address.address_line1} ${address.locality} ${address.administrative_area} ${address.country}`
      );
      getCoordinatesFromAddress();
    }
  }, [createProjectState.location]);

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const getLocation = place.geometry.location;
      setMapCenter({ lat: getLocation.lat(), lng: getLocation.lng() });
      setSearchQuery(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const location = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
      } as ProjectAddressProps;

      const projectAddresObj = {
        ...createProjectState,
        location,
      } as createProjectParams;
      dispatch(setCreateProjectState(projectAddresObj));
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };
  if (!isLoaded) {
    return <Skeleton />;
  }

  return (
    <JobCardWrapper
      header="Where is the project located?"
      subText="Your address will only be shared with potential prospects when they are hired."
    >
      <Stack pr={1} mb={1} gap={1}>
        <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceSelect}>
          <AtsTextInput
            height="44px"
            placeholder="Search location"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            startAdornment={
              <AtsIcon
                icon="icon-location"
                color={COLORS.PRIMARY}
                fw={600}
                height="20px"
              />
            }
          />
        </Autocomplete>

        <Box
          sx={{
            minHeight: "400px",
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={10}
          >
            <Marker position={mapCenter} />
          </GoogleMap>
        </Box>
      </Stack>
    </JobCardWrapper>
  );
};

export default ProjectLoaction;
