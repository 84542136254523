import { Box, Grid, Stack } from "@mui/material";
import TopPrioritiesComponnet from "../../../../../components/common/TopPrioritiesComponnet";
import { BORDERS } from "../../../../../theme";
import { PRIORITIES } from "../../../../Dashboard/TopPriorities/TopPriorities";
import AboutThisProject from "./components/AboutThisProject";
import PipelineProject from "./components/PipelineProject";
import RecentActivity from "./components/RecentActivity";
import RecentlyPostedJobs from "./components/RecentlyPostedJobs";

const Overview = () => {
  return (
    <>
      <Grid container spacing={"20px"}>
        <Grid item xs={12} sm={6} md={8} lg={9} xl={9.5} xxl={10}>
          <Stack gap={"20px"}>
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "6px",
                py: "24px",
                px: "30px",
              }}
            >
              <TopPrioritiesComponnet
                headerText="Top priorities"
                prioritiesData={PRIORITIES}
              />
            </Box>
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "6px",
                py: "24px",
                px: "30px",
              }}
            >
              <RecentActivity />
            </Box>
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "6px",
                py: "24px",
                px: "30px",
                mb: "10px",
              }}
            >
              <PipelineProject />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.5} xxl={2}>
          <Stack gap={"20px"}>
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "6px",
                py: "24px",
                px: "30px",
              }}
            >
              <AboutThisProject />
            </Box>
            <Box
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "6px",
                py: "24px",
                px: "30px",
              }}
            >
              <RecentlyPostedJobs />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
