import { Box, Stack } from "@mui/material";
import CountCard from "./CountCard";
import JobFilterSection from "./JobFilterSection";
import CandidateList from "./Candidate/CandidateList";

const Pipeline = () => {
  return (
    <Box>
      <CountCard />
      <JobFilterSection />
      <CandidateList />
    </Box>
  );
};

export default Pipeline;
