import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  createProjectParams,
  ProjectFilterState,
  ProjectListProps,
} from "../../types/projectTypes";
import { ProjectListStatusEnum } from "../../enums/projectEnums";

export const initailCreateProjectState = {
  title: "",
  description: "",
  company_id: "",
  client_email: "",
  start_date: "",
  end_date: "",
  status: ProjectListStatusEnum.IN_PROGRESS,
  location: {
    address_line1: "",
    country: "",
    administrative_area: "",
    locality: "",
    postal_code: "",
  },
  job_id: [],
  budget_low: 0,
  budget_high: 100,
  visibility: "",
  team_members: [],
  document: {
    title: "",
    description: "",
    attachments_fid: [],
  },
} as createProjectParams;

export const initilaProjectFilterstate = {
  end_date: "",
  start_date: "",
  country_code: "",
  budget_high: 0,
  budget_low: 0,
  status: ProjectListStatusEnum.IN_PROGRESS,
} as ProjectFilterState;

interface ProjectSliceState {
  activeProjectStep: number;
  activeProjectStep2: number;
  activeProjectStep3: number;
  isCreatedProject: boolean;
  isEditProjectStep: boolean;
  isProjectQuestionRoute: boolean;
  createProjectState: createProjectParams;
  selectedProjectDetailsConfirm: string[];
  selectedProjectTab: number;
  selectedTeamTab: number | null;
  requiredDocument: any[];
  chooseProfileImg: any[];
  projectsDetails: Partial<ProjectListProps>;
  createdProject: ProjectListProps | null;
  projectFilter: ProjectFilterState;
}

const initialState: ProjectSliceState = {
  activeProjectStep: 1,
  activeProjectStep2: 0,
  activeProjectStep3: 0,
  isCreatedProject: false,
  isProjectQuestionRoute: false,
  isEditProjectStep: false,
  createProjectState: initailCreateProjectState,
  selectedProjectDetailsConfirm: [],
  selectedProjectTab: 0,
  selectedTeamTab: null,
  requiredDocument: [],
  chooseProfileImg: [],
  projectsDetails: {},
  createdProject: null,
  projectFilter: initilaProjectFilterstate,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setActiveProjectStep: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.activeProjectStep = payload;
    },
    setActiveProjectStep2: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.activeProjectStep2 = payload;
    },
    setActiveProjectStep3: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.activeProjectStep3 = payload;
    },
    setIsCreatedProject: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.isCreatedProject = payload;
    },
    setIsProjectQuestionRoute: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.isProjectQuestionRoute = payload;
    },
    setIsEditProjectStep: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.isEditProjectStep = payload;
    },
    setCreateProjectState: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.createProjectState = payload;
    },
    setSelectedProjectDetailsConfirm: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.selectedProjectDetailsConfirm = payload;
    },

    // PROJECT_DETAILS
    setSelectedProjectTab: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.selectedProjectTab = payload;
    },
    setSelectedTeamTab: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.selectedTeamTab = payload;
    },
    setRequiredDocument: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.requiredDocument = payload;
    },
    setChooseProfileImg: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.chooseProfileImg = payload;
    },
    setCreatedProject: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.createdProject = payload;
    },
    setProjectFilter: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.projectFilter = payload;
    },
    setProjectsDetails: (state: ProjectSliceState, action) => {
      const { payload } = action;
      state.projectsDetails = payload;
    },

    setInitialProjectState: () => initialState,
  },
});

export const {
  setInitialProjectState,
  setActiveProjectStep,
  setActiveProjectStep2,
  setActiveProjectStep3,
  setIsCreatedProject,
  setIsProjectQuestionRoute,
  setIsEditProjectStep,
  setCreateProjectState,
  setSelectedProjectDetailsConfirm,
  setSelectedProjectTab,
  setSelectedTeamTab,
  setChooseProfileImg,
  setRequiredDocument,
  setCreatedProject,
  setProjectFilter,
  setProjectsDetails,
} = projectsSlice.actions;
export const getProjectsState = (state: RootState) => state.projects;
export default projectsSlice.reducer;
