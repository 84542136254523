export enum AppointmentStatusCode {
  Overdue = "OVERDUE",
  due = "DUE",
  contractStart = "CONTRACT_START",
  contractEnd = "CONTRACT_END",
  interview = "INTERVIEW",
}
export enum CalendarEventTypes {
  All = "All",
  Interviews = "Interviews",
  Reminders = "Reminders",
  Invoices = "Invoices",
}

export const EVENT_STATUS_BG_COLORS = {
  OVERDUE: "#ffd6d6",
  DUE: "#def5e6",
  CONTRACT_START: "#d9d2ff",
  CONTRACT_END: "#d9d2ff",
  INTERVIEW: "#cdf4ff",
};
export const EVENT_STATUS_COLORS = {
  OVERDUE: "#af1f1f",
  DUE: "#0e4f28",
  CONTRACT_START: "#381d7f",
  CONTRACT_END: "#381d7f",
  INTERVIEW: "#3c4f74",
};
export type Appointment = {
  id: number;
  status: string;
  text: string;
  length: string;
};

export type Blockout = { id: number; name: string };

export type EventItem = {
  start?: Date;
  end?: Date;
  data?: { appointment?: Appointment; blockout?: Blockout };
  isDraggable?: boolean;
  allDay?: boolean;
  resourceId?: number;
};
