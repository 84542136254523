import {
  ChevronLeft,
  ChevronRight,
  Close,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useState } from "react";
import {
  MakeAnOfferIcon,
  ScheduleIcon,
} from "../../../../../../assets/icons/Icons";
import ThreeDotIcon from "../../../../../../assets/icons/threeDot.svg";
import Xfhghggh from "../../../../../../assets/images/candidate.png";
import StarIconRating from "../../../../../../assets/images/startIcon.svg";
import ThumbUp from "../../../../../../assets/images/thumbup.svg";
import TopCandidate from "../../../../../../assets/images/topCandidate.svg";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { setCondidateTabValue } from "../../../../../../redux/slices/candidateSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../theme";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../../../../../../redux/slices/submissionSlice";
import AtsIcon from "../../../../../../components/AtsIcon";
import { Skeleton } from "@mui/material";
import { getDateFormatMMMMDYYYYY } from "../../../../../../utils/dateUtils";
import { RateAsEnum } from "../../../../../../enums/jobsEnums";
import { renderStatisticsBgcolor } from "../CandidateList";
import {
  formateOneDigitNum,
  isNotNullOrUndefined,
} from "../../../../../../utils/stringUtils";

const matchData = [
  {
    key: RateAsEnum.GOOD_FIT,
  },
  {
    key: RateAsEnum.NOT_FIT,
  },
  {
    key: RateAsEnum.MAYBE,
  },
];

const CandidateDetailsSection = () => {
  const dispatch = useAppDispatch();
  const {
    isCandidateDetailsDrawerOpen,
    isCandidateDetailsLoading,
    submissionDetails,
    candidateDetails,
  } = useAppSelector(getSubmissionState);
  const [keySkills, setSkills] = useState<string>(submissionDetails?.rate_as);
  const handleKeySkillsChange = (event) => {
    setSkills(event.target.value);
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
      >
        <AtsTitleText
          text={"Candidate Detail"}
          fs={22}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={0.3}
          sx={{ cursor: "pointer" }}
        >
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#000",
            }}
            text="1"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: "#000",
              marginRight: "4px",
            }}
            text="of"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#000",
            }}
            text="59"
          />
          <AtsText
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: "#000",
              marginRight: "10px",
            }}
            text="Candidates"
          />
          <Stack
            gap={0}
            direction={"row"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            marginRight={"12px"}
          >
            <ChevronLeft
              sx={{ width: "17px", height: "39px", color: "#6d6d6d" }}
            />
            <AtsText
              sx={{
                fontSize: 12,
                color: "#6d6d6d",
                marginRight: "15px",
              }}
              text="Prev"
            />
            <AtsText
              sx={{
                fontSize: 12,
                color: "#000",
              }}
              text="Next"
            />
            <ChevronRight
              sx={{ width: "17px", height: "39px", color: "#000" }}
            />
          </Stack>
          <IconButton
            color="inherit"
            onClick={() =>
              dispatch(
                setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen)
              )
            }
          >
            <Close
              sx={{
                fontSize: "25px",
              }}
            />
          </IconButton>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </Stack>
      </Stack>

      <Stack
        py={2}
        sx={{ borderBottom: 1, borderColor: "#f5f5f5", pl: 4, pr: 4 }}
      >
        <Grid
          container
          spacing={2}
          style={{ position: "relative", height: "-webkit-fill-available" }}
        >
          <Grid item xs={12} md={6} sm={6} sx={{ alignContent: "center" }}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <Stack>
                {isCandidateDetailsLoading ? (
                  <Skeleton variant="circular" width={110} height={110} />
                ) : isNotNullOrUndefined(candidateDetails?.image) ? (
                  <img
                    src={candidateDetails?.image}
                    alt="Nvidia Logo"
                    style={{
                      height: "auto",
                      marginRight: "10px",
                      width: "110px",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <img
                    src={Xfhghggh}
                    alt="Nvidia Logo"
                    style={{
                      height: "auto",
                      marginRight: "10px",
                      width: "110px",
                      borderRadius: "100%",
                    }}
                  />
                )}
              </Stack>
              <Stack>
                {isCandidateDetailsLoading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <AtsText
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#000000",
                      mb: 0.5,
                    }}
                    text={`${submissionDetails?.candidate_first_name ?? ""} ${
                      submissionDetails?.candidate_last_name ?? ""
                    }`}
                  />
                )}
                <AtsText
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#6d6d6d",
                    mb: 0,
                  }}
                  text={submissionDetails?.candidate_job_title ?? ""}
                />
                <Stack direction={"row"} gap={0.3} py={1}>
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    text={
                      submissionDetails?.rating
                        ? formateOneDigitNum(submissionDetails?.rating)
                        : 0
                    }
                  />
                  <img
                    src={StarIconRating}
                    alt="Nvidia Logo"
                    style={{ width: 15 }}
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#6d6d6d",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                    text="|"
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#6d6d6d",
                    }}
                    text="Applied:"
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    text={getDateFormatMMMMDYYYYY(
                      submissionDetails?.applied_on
                    )}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{ alignContent: "center", width: "100%" }}
                >
                  <Grid item xs>
                    <FormControl fullWidth size="small">
                      <Select
                        labelId="skills-label"
                        value={keySkills}
                        onChange={handleKeySkillsChange}
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                        renderValue={(selected) => {
                          const selectedMatch = matchData.find(
                            (match) => match.key === selected
                          );
                          return (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="8px"
                            >
                              <AtsIcon
                                height="18px"
                                icon={`icon-${
                                  selectedMatch?.key === RateAsEnum.NOT_FIT
                                    ? "dislike"
                                    : selectedMatch?.key === RateAsEnum.MAYBE
                                    ? "message-question"
                                    : "like-1"
                                }`}
                              />
                              <AtsTitleText
                                text={selectedMatch?.key}
                                fs={12}
                                fw={400}
                              />
                            </Stack>
                          );
                        }}
                        sx={{
                          fontSize: "12px", // Font size for the select input
                          fontWeight: "500",
                          padding: "0px 0px 0px 0px", // Padding for the select input
                          borderRadius: "20px", // Rounded corners
                          backgroundColor: "inherit",
                          color: "#6d6d6d",
                          width: "122px",
                          height: "32px",
                          border: "1px solid #dadada",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiSelect-select": {
                            borderRadius: "20px", // Ensure dropdown options have rounded corners
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "8px", // Rounded corners for the dropdown menu
                              "& .MuiMenuItem-root": {
                                fontSize: "12px",
                              },
                            },
                          },
                        }}
                      >
                        {matchData.map((match) => (
                          <MenuItem key={match.key} value={match.key}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={"8px"}
                            >
                              <AtsIcon
                                height="20px"
                                icon={`icon-${
                                  match.key === RateAsEnum.NOT_FIT
                                    ? "dislike"
                                    : match.key === RateAsEnum.MAYBE
                                    ? "message-question"
                                    : "like-1"
                                }`}
                              />
                              <AtsTitleText
                                lineHeight={"0px"}
                                text={match.key}
                                fs={12}
                                fw={400}
                              />
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs="auto" sx={{ alignContent: "center" }}>
                    <AtsRoundedButton
                      buttonHeight={30}
                      fs={"12"}
                      fw={600}
                      text={submissionDetails?.stage}
                      buttonVariant={renderStatisticsBgcolor(
                        submissionDetails?.stage
                      )}
                    />
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sm={6} sx={{ alignContent: "center" }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={2}
              sx={{ py: 0, position: "absolute", right: 0 }}
              className="messageBtnTopRight"
            >
              {/* <AtsRoundedButton
                onClick={() => dispatch(setCondidateTabValue(5))}
                text=""
                buttonWidth={"185px"}
                buttonVariant="whiteContained"
                startIcon={<MessageIcon />}
              /> */}
              <AtsRoundedButton
                onClick={() => dispatch(setCondidateTabValue(5))}
                buttonWidth={"149px"}
                text="Make an Offer"
                buttonVariant="outline"
                isInitial
                startIcon={<MakeAnOfferIcon />}
              />

              <AtsRoundedButton
                onClick={() => dispatch(setCondidateTabValue(4))}
                buttonWidth={"185px"}
                text="Schedule Interview"
                startIcon={<ScheduleIcon />}
              />

              <ListItemIcon sx={{ minWidth: "20px", mt: 0 }}>
                <img
                  src={ThreeDotIcon}
                  alt="Three Dot"
                  style={{ height: "33px", width: "26px" }}
                />
              </ListItemIcon>
            </Stack>

            <div style={{ height: "-webkit-fill-available" }}>
              <Stack
                direction={"row"}
                sx={{ py: 0, position: "absolute", bottom: 0, right: 0 }} // Stack positioned at the bottom right
              >
                <Stack direction={"row"} sx={{ alignContent: "center" }}>
                  <img
                    src={TopCandidate}
                    alt="Path"
                    style={{ height: "auto", marginRight: "5px" }}
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                      marginRight: "4px",
                    }}
                    text="Top Candidate in"
                  />
                  <AtsText
                    sx={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#000",
                    }}
                    text="2 other jobs"
                  />
                </Stack>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CandidateDetailsSection;
