import React, { useState } from "react";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { Box, Collapse, Stack } from "@mui/material";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsCheckbox from "../../../components/AtsCheckbox";
import { countryAbbreviationsTwoDigit } from "../../../utils/countryAbbreviations";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getProjectsState,
  setProjectFilter,
} from "../../../redux/slices/projectsSlice";
import { ProjectFilterState } from "../../../types/projectTypes";
import { useClickOutSide } from "../../../utils/common";

const location = [
  { country: "United States", abbrevation: "US" },
  { country: "United Kingdom", abbrevation: "GB" },
  { country: "South Africa", abbrevation: "ZA" },
  { country: "Australia", abbrevation: "AU" },
  { country: "Germany", abbrevation: "DE" },
];

const FilterLoacation = () => {
  const disaptch = useAppDispatch();
  const { projectFilter } = useAppSelector(getProjectsState);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const filteredCountries = Object.entries(countryAbbreviationsTwoDigit).filter(
    ([country]) => country.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (country: string) => {
    if (countryAbbreviationsTwoDigit[country] === projectFilter?.country_code) {
      setSearch("");
      const projectPreState = {
        ...projectFilter,
        country_code: "",
      } as ProjectFilterState;
      disaptch(setProjectFilter(projectPreState));
    } else {
      setSearch(country);
      const projectPreState = {
        ...projectFilter,
        country_code: countryAbbreviationsTwoDigit[country],
      } as ProjectFilterState;
      disaptch(setProjectFilter(projectPreState));
    }
  };
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0} onClick={() => setIsCollapse(!isCollapse)}>
          <AtsIcon
            icon={isCollapse ? "icon-arrow-up-2" : "icon-arrow-down-1"}
            height="16px"
            fw={600}
          />
        </AtsCustomButton>
        <AtsTitleText
          text={"Location"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Collapse in={isCollapse}>
        <Stack>
          <Box
            ref={domNode}
            sx={{
              position: "relative",
            }}
            onClick={() => setShow(true)}
          >
            <AtsTextInput
              height="42px"
              placeholder="Search location"
              value={search}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length) {
                  setShow(true);
                } else {
                  setShow(false);
                }
                setSearch(value);
              }}
              endAdornment={
                <AtsIcon
                  icon="icon-search-normal-1"
                  height="18px"
                  color={COLORS.PRIMARY}
                />
              }
            />

            <Box mt={1}>
              <Collapse in={show}>
                <Stack
                  gap={1}
                  sx={{
                    p: 2,
                    border: BORDERS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  {filteredCountries.map(([country, abbreviation]) => (
                    <Stack
                      key={country}
                      direction={"row"}
                      alignItems={"center"}
                      gap={"8px"}
                    >
                      <AtsCheckbox
                        p={0}
                        checked={abbreviation === projectFilter?.country_code}
                        onChange={() => handleSelect(country)}
                      />
                      <AtsTitleText
                        text={country}
                        fs={12}
                        fw={400}
                        textColor={"#000"}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          </Box>
          <Stack gap={"10px"} mt={"10px"}>
            {location.map((country) => (
              <Stack
                key={country.abbrevation}
                direction={"row"}
                alignItems={"center"}
                gap={"8px"}
              >
                <AtsCheckbox
                  p={0}
                  checked={country.abbrevation === projectFilter?.country_code}
                  onChange={() => handleSelect(country.country)}
                />
                <AtsTitleText
                  text={country.country}
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default FilterLoacation;
