import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BORDERS, COLORS } from "../../../../../../theme";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import { motion } from "framer-motion";

const projectData = [
  {
    title: "Start date",
    value: "24/01/2024",
  },
  {
    title: "End date",
    value: "24/01/2024",
  },
  {
    title: "Budget",
    value: "$100,000.00",
  },
  {
    title: "Contractors",
    value: "40",
  },
  {
    title: "Active Jobs",
    value: "50",
  },
  {
    title: "New submissions",
    value: "140",
  },
  {
    title: "Invoices",
    value: "12",
  },
  {
    title: "Project Manager",
    value: "Fredrick San...",
  },
];

const AboutThisProject = () => {
  const [isCollapse, setIsCollapse] = useState(false);
  return (
    <Box>
      <Stack
        sx={{
          ...(isCollapse && {
            borderBottom: BORDERS.GRAY,
            pb: "19px",
            gap: "20px",
          }),
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText text={"About this project"} fs={18} fw={600} />
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <AtsIcon
              icon={`icon-arrow-circle-${!isCollapse ? "up" : "down"}`}
              height="16px"
            />
          </AtsCustomButton>
        </Stack>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: isCollapse ? "auto" : 0,
            opacity: isCollapse ? 1 : 0,
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ overflow: "hidden" }}
        >
          <Stack gap={"14px"} direction={"row"} alignItems={"center"}>
            <Avatar src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              You have{" "}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                full project access
              </span>
            </Typography>
          </Stack>
        </motion.div>
      </Stack>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isCollapse ? "auto" : 0,
          opacity: isCollapse ? 1 : 0,
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ overflow: "hidden" }}
      >
        <Box mt={"30px"}>
          <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
            {projectData.map((details, index) => (
              <Grid item xs={6} key={index}>
                <Stack gap={1}>
                  <AtsTitleText
                    text={details.title}
                    fs={12}
                    fw={400}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                  <AtsTitleText text={details.value} fs={14} fw={400} />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </motion.div>
    </Box>
  );
};

export default AboutThisProject;
