import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Container,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import AtsDateCalenderPicker from "../../../../../../../../components/AtsDateCalenderPicker";
import AtsText from "../../../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  getInterviewState,
  setCount,
  setSelectedDate,
  setSelectedSlot,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import InterviewButton from "./InterviewButton";

const InterviewDateTimeSelection = () => {
  const dispatch = useAppDispatch();
  const { count, selectedDate, selectedSlot } =
    useAppSelector(getInterviewState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const timeSlot = [
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 AM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
  ];

  const min = [15, 30, 60];
  const [minIndex, setMinIndex] = useState<number>(0);

  const handleMinChange = (direction: "NEXT" | "PREV") => {
    if (direction === "NEXT") {
      setMinIndex((prevIndex) => (prevIndex + 1) % min.length);
    } else {
      setMinIndex((prevIndex) => (prevIndex - 1 + min.length) % min.length);
    }
  };

  const handleDateChange = (newDate) => {
    dispatch(setSelectedDate(newDate));
  };

  const selecteSlot = (slot: string) => {
    if (selectedSlot === slot) {
      dispatch(setSelectedSlot(""));
    } else {
      dispatch(setSelectedSlot(slot));
    }
  };

  useEffect(() => {
    dispatch(setCount(min[minIndex]));
  }, [minIndex]);

  return (
    <Stack>
      <Stack sx={{ pt: "30px", pb: 4 }}>
        <AtsTitleText
          fs={14}
          fw={600}
          textColor={COLORS.LIGHT_GRAY}
          text="Select Duration of the interview"
        />
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={minIndex === 0}
            onClick={() => handleMinChange("PREV")}
          >
            <RemoveCircleOutline />
          </IconButton>
          <AtsTitleText
            fs={14}
            fw={400}
            textColor={"#000"}
            text={`${min[minIndex]} Mins`}
          />

          <IconButton
            disabled={minIndex === 2}
            onClick={() => {
              handleMinChange("NEXT");
            }}
          >
            <AddCircleOutline />
          </IconButton>
        </Stack>
      </Stack>
      <Stack>
        <AtsTitleText
          fs={14}
          fw={600}
          textColor={COLORS.LIGHT_GRAY}
          text="Select a Date and Time"
        />
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} gap={2}>
        <Stack sx={{ flex: 1 }}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{
                margin: 0,
                ".MuiPickersCalendarHeader-root": {
                  marginTop: 0,
                  borderBottom: BORDERS.GRAY,
                },
                ".Mui-selected": {
                  color: "#3366cc !important",
                  bgcolor: "#e2ebff !important",
                  fontWeight: 600,
                },
                ".MuiPickersCalendarHeader-label": {
                  fontSize: 14,
                  color: COLORS.BLACK,
                },
              }}
              value={selectedDate}
              onChange={handleDateChange}
              minDate={dayjs(new Date())}
              shouldDisableDate={disableWeekends}
            />
          </LocalizationProvider> */}
          <AtsDateCalenderPicker
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
          />
        </Stack>
        <Container maxWidth="xs" sx={{ pt: "7px" }}>
          <Stack gap={1}>
            <Stack
              sx={{
                pb: 2,
                borderBottom: BORDERS.GRAY,
              }}
            >
              <AtsText
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: COLORS.BLACK,
                }}
                text={dateFormatDDDDMMMMD(selectedDate)}
              />
            </Stack>
            <Stack pt={2}>
              <AtsText
                sx={{
                  fontSize: 11,
                  fontWeight: 500,
                  color: COLORS.LIGHT_GRAY,
                }}
                text="SELECT TIME SLOT"
              />
              <Grid container>
                {timeSlot.map((slot) => (
                  <Grid item xs={4}>
                    <InterviewButton
                      value={slot}
                      isSelected={selectedSlot === slot}
                      onClick={() => selecteSlot(slot)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default InterviewDateTimeSelection;
