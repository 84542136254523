import { Box, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, BOX_SHADOW, COLORS } from "../../../../../../theme";
import PieChart from "./PieChart";
import DonutChart from "./DonutChart";
export const initialCountDetails = [
  {
    title: "New Submission",
    value: 0,
    color: "#b2edff",
  },
  {
    title: "Interviewing",
    value: 0,
    color: "#ddb2ff",
  },

  {
    title: "Offers Extended",
    value: 0,
    color: "#f8ea6c",
  },
  {
    title: "Offer Accepted",
    value: 0,
    color: "#b2a6fa",
  },
  {
    title: "Hired",
    value: 0,
    color: "#81ea8c",
  },
];

const graphData = [
  {
    title: "Top job titles",
    series: [20, 25, 35],
    labels: ["Content A", "Content B", "Content C"],
  },
  {
    title: "Years of experience",
    series: [23, 28, 25],
    labels: ["Content A", "Content B", "Content C"],
  },
  {
    title: "Locations",
    series: [32, 26, 43],
    labels: ["Content A", "Content B", "Content C"],
  },
  {
    title: "Top skills",
    series: [25, 35, 57],
    labels: ["Content A", "Content B", "Content C"],
  },
];

const PipelineProject = () => {
  return (
    <>
      <Box pb={"22px"}>
        <AtsTitleText text={"Pipeline"} fs={18} fw={600} />
      </Box>
      <Box pb={"13px"}>
        <AtsTitleText
          text={"Hiring pipeline stages"}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Box>
      <Stack direction={"row"} flexWrap={"wrap"} gap={"15px"}>
        {initialCountDetails.map((stage, index) => (
          <Stack
            key={index}
            sx={{
              px: "16px",
              py: "14px",
              borderRadius: "6px",
              boxShadow: BOX_SHADOW.GRAY,
              gap: "10px",
              width: 97,
              cursor: "pointer",
              border: BORDERS.GRAY,
            }}
          >
            <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  bgcolor: stage.color,
                  borderRadius: "30px",
                }}
              ></Box>
              <AtsTitleText
                fs={18}
                fw={400}
                textColor={COLORS.BLACK}
                text={stage.value}
              />
            </Stack>
            <AtsTitleText
              fs={12}
              fw={600}
              textColor={COLORS.BLACK}
              text={stage.title}
            />
          </Stack>
        ))}
      </Stack>
      <Box mb={"37px"} mt={"50px"}>
        <AtsTitleText
          text={"Saved candidates demographics"}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Box>
      <Stack direction={"row"} flexWrap={"wrap"}>
        {graphData.map((data, index) => (
          <Stack
            key={index}
            sx={{
              flex: {
                xs: "1 1 100%",
                sm: "1 1 calc(50% - 1px)",
                md: "1 1 calc(33.33% - 1px)",
                // lg: "1 1 calc(25% - 1px)",
                // xl: "1 1 calc(20% - 1px)",
              },
              minWidth: {
                xs: "100%",
                sm: "calc(50% - 1px)",
                md: "calc(33.33% - 1px)",
                // lg: "calc(25% - 1px)",
                // xl: "calc(20% - 1px)",
              },
              px: {
                xs: "20px",
                sm: "30px",
                md: "40px",
                lg: "50px",
                xl: "60px",
              },
              py: {
                xs: "10px",
                sm: "15px",
                md: "20px",
                lg: "25px",
                xl: "30px",
              },
              ...((index === 0 || index === 1) && {
                borderBottom: BORDERS.GRAY,
              }),
              ...((index === 0 || index === 2) && {
                borderRight: { sm: BORDERS.GRAY, xs: "none" },
              }),
            }}
          >
            <Box textAlign={"center"} my={"30px"}>
              <AtsTitleText
                text={data.title}
                fs={14}
                fw={600}
                textColor={"#000"}
              />
            </Box>
            <DonutChart
              key={index}
              title={data.title || ""}
              series={data?.series || []}
              labels={data?.labels || []}
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default PipelineProject;
