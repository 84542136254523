import { Search } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  InputAdornment,
  Popover,
  Stack,
  TextField,
} from "@mui/material";
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../theme";
import { FilterIcon } from "../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../components/AtsCustomButton";
import { initialCountDetails } from "../CountCard";
import AtsDateRangePicker from "../../../../../components/AtsDateRangePicker";
import { formatDateRange } from "../../../../../utils/dateUtils";
import AtsRoundedButton from "../../../../../components/AtsRoundedButton";

interface CustomHeaderProps {
  headerText: string;
}

export const CandidateListHeader = ({ headerText }: CustomHeaderProps) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (headerText === "Applied") {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleEvent = (event, picker) => {
    const datePicker = formatDateRange(picker.startDate, picker.endDate);
    // setSelectedDate(datePicker);
  };

  return (
    <Stack
      direction={"row"}
      gap={1}
      sx={{
        alignItems: "center",
      }}
      className="custom-header"
    >
      <Stack direction={"row"} gap={1}>
        <div
          style={{
            position: "relative",
          }}
        >
          {headerText === "Applied" ? (
            <AtsDateRangePicker handleEvent={handleEvent}>
              <Button
                sx={{
                  height: 26,
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: 14,
                  color: "#4f4f4f",
                  ":hover": {
                    color: "#4f4f4f",
                    bgcolor: BG_COLORS.GRAY,
                  },
                }}
                variant="text"
                endIcon={<FilterIcon />}
              >
                Applied
              </Button>
            </AtsDateRangePicker>
          ) : (
            <AtsCustomButton px={0.5} onClick={(e) => handleClick(e)}>
              <Stack direction={"row"} gap={1}>
                <AtsTitleText
                  text={headerText}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />

                {headerText.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              ".MuiPopover-paper": {
                borderRadius: "10px !important",
              },
            }}
          >
            <Stack
              sx={{
                p: 1,
                border: BORDERS.GRAY,
                borderRadius: "10px",
              }}
            >
              {headerText === "Candidate" ? (
                <TextField
                  placeholder="Search...."
                  fullWidth
                  size={"small"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : headerText === "Rate" ? (
                <Stack sx={{ px: 2, py: 1.5, minWidth: 100 }} gap={2}>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Over Budget" fs={12} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Under Budget" fs={12} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Matches Budget" fs={12} fw={600} />
                  </Stack>
                </Stack>
              ) : headerText === "Rate As" ? (
                <Stack sx={{ px: 2, py: 1.5, minWidth: 100 }} gap={2}>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Good Fit" fs={12} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <AtsTitleText text="Not a Fit" fs={12} fw={600} />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <AtsTitleText text="Maybe" fs={12} fw={600} />
                  </Stack>
                </Stack>
              ) : (
                headerText === "Stage" && (
                  <Box sx={{ width: 155 }}>
                    <Stack sx={{ px: 1, pt: 1.5, pb: 2 }} gap={2}>
                      {initialCountDetails.map((item) => (
                        <Stack
                          key={item.title}
                          direction={"row"}
                          alignItems={"center"}
                          gap={1.5}
                        >
                          <Box
                            sx={{
                              width: "15px",
                              height: "15px",
                              bgcolor: item.color,
                              borderRadius: "30px",
                            }}
                          ></Box>
                          <AtsTitleText text={item.title} fs={12} fw={600} />
                        </Stack>
                      ))}
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1.5}
                      sx={{
                        px: 1,
                        pt: 2,
                        pb: 1.5,
                        minWidth: 100,
                        borderTop: BORDERS.GRAY,
                      }}
                    >
                      <Box
                        sx={{
                          width: "15px",
                          height: "15px",
                          bgcolor: "#ee6262",
                          borderRadius: "30px",
                        }}
                      ></Box>
                      <AtsTitleText text={"Rejected"} fs={12} fw={600} />
                    </Stack>
                  </Box>
                )
              )}
            </Stack>
          </Popover>
        </div>
      </Stack>
    </Stack>
  );
};
