import { Add } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectService } from "../../../Api/projectServices";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsSelect from "../../../components/AtsSelect";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import JobCardWrapper from "../components/JobCardWrapper";
import { OptionProps } from "../../../types/commonType";
import { ProjectListStatusEnum } from "../../../enums/projectEnums";
import { ProjectListProps } from "../../../types/projectTypes";

const projects = [
  "One",
  "Two",
  "Three",
  "Sixty two and One",
  "Hundred and Five",
];

const Projects = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [reletedProject, setReletedProject] = useState<OptionProps[]>([]);
  const [recentProject, setRecentProject] = useState<ProjectListProps[]>([]);

  const handleChange = (value: string) => {
    if (postOpportunity.job_project_id === value) {
      const opportunityObj = {
        ...postOpportunity,
        job_project_id: "",
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const opportunityObj = {
        ...postOpportunity,
        job_project_id: value,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };

  const handleSelect = (value: string) => {
    setSelectedProject(value);
  };

  const recentCreateProject = async () => {
    try {
      const response = await ProjectService.getProjectList({
        status: ProjectListStatusEnum.IN_PROGRESS,
      });
      if (response.results.length) {
        const firstFiveData = response.results.slice(0, 5);
        setRecentProject(firstFiveData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyProject = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getMyProject();
      if (response.results.length) {
        const options = response.results.map(
          (option) =>
            ({ label: option.title, value: option.nid } as OptionProps)
        );
        setReletedProject(options);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    recentCreateProject();
    fetchMyProject();
  }, []);
  return (
    <>
      <JobCardWrapper header="Add this job to a Project">
        <Stack pr={1}>
          <Stack gap={1} pt={1}>
            <AtsTitleText
              text={"Related Projects"}
              fs={"0.875rem"}
              fw={600}
              textColor={COLORS.LIGHT_GRAY}
            />
            <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
              {recentProject.map((project, index) => (
                <AtsRoundedButton
                  onClick={() => handleChange(project.nid)}
                  key={index}
                  text={`Project ${project.title}`}
                  buttonVariant={
                    postOpportunity.job_project_id === project.nid
                      ? "contained"
                      : "whiteContained"
                  }
                  // endIcon={
                  //   postOpportunity.job_projects.includes(project) && <Close />
                  // }
                />
              ))}
            </Stack>
          </Stack>
          <Stack gap={1} py={5} sx={{ borderBottom: BORDERS.GRAY }}>
            <AtsTitleText
              text={"All Project"}
              textColor={COLORS.LIGHT_GRAY}
              fs={"0.875rem"}
              fw={600}
            />
            <AtsSelect
              placeholder="Select Project"
              selected={selectedProject}
              onSelect={(select: string) => handleSelect(select)}
              options={reletedProject}
            />
          </Stack>
          <Stack gap={1} py={2}>
            <AtsTitleText
              text={"What to create a new project?"}
              textColor={COLORS.BLACK}
              fs={"0.75rem"}
              fw={600}
            />
            <AtsTitleText
              text={
                "Don't worry create a new project to complete your job posting!"
              }
              textColor={COLORS.BLACK}
              fs={"0.625rem"}
              fw={400}
            />
            <Box>
              <AtsRoundedButton
                text={`Create Project`}
                buttonVariant={"outline"}
                startIcon={<Add />}
              />
            </Box>
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default Projects;
