import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import ContractorComponent from "./ContractorComponent";
import { useEffect, useState } from "react";
import { AppointmentStatusCode } from "../../../types/calendarTypes";

const contractStartData = [
  {
    contractorName: "Trenor Cunningham",
    role: "Sr. Java developer",
    projectName: "InsightTree Digital",
  },
  {
    contractorName: "Jasmine Mitgnuther",
    role: "Sr. Java developer",
    projectName: "InsightTree Digital",
  },
];
const contractEndData = [
  {
    contractorName: "Trenor Cunningham",
    role: "Sr. Java developer",
    projectName: "InsightTree Digital",
  },
];

interface IContractProps {
  contractStatus: string;
}

const Contract = ({ contractStatus }: IContractProps) => {
  const [contractData, setContractData] = useState([]);

  useEffect(() => {
    if (contractStatus === AppointmentStatusCode.contractStart) {
      setContractData(contractStartData);
    } else {
      setContractData(contractEndData);
    }
  }, [contractStatus]);

  return (
    <Stack
      gap={"20px"}
      sx={{
        height:
          contractStatus === AppointmentStatusCode.contractStart ? 335 : "auto",
        overflow: "auto",
      }}
    >
      {contractData.map((item, index) => {
        return (
          <ContractorComponent
            contractorName={item.contractorName}
            projectName={item.projectName}
            role={item.role}
            key={index}
            renderBottomView={
              <Stack
                pt={"15px"}
                pb={"5px"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={
                  contractStatus === AppointmentStatusCode.contractStart
                    ? "flex-end"
                    : "space-between"
                }
              >
                {contractStatus !== AppointmentStatusCode.contractStart && (
                  <AtsTitleText
                    text={"Renew"}
                    isLink
                    isUnderline
                    fs={12}
                    fw={500}
                    textColor={COLORS.PRIMARY}
                  />
                )}
                <AtsTitleText
                  text={"View"}
                  fs={12}
                  fw={500}
                  textColor={COLORS.PRIMARY}
                />
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default Contract;
