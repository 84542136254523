import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import { Star } from "@mui/icons-material";
import { getInitials } from "../../../../../../../../../utils/common";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { SubmissionService } from "../../../../../../../../../Api/submissionServices";
import { CandidateRattingReviewListResponse } from "../../../../../../../../../types/submissionTypes";
import {
  formateOneDigitNum,
  isNotNullOrUndefined,
} from "../../../../../../../../../utils/stringUtils";
import { getDateFormatMMMMDYYYYY } from "../../../../../../../../../utils/dateUtils";

const reviewsList = [
  {
    name: "Jonathan Nolan",
    project: "Project Name Nine",
    rate: "4.8",
    date: "June 02,2024",
    description:
      "Trevor has been an exceptional asset to the [Project Name] project. Their dedication, creativity, and problem-solving skills have been invaluable. They consistently delivered high-quality work, collaborated seamlessly with the team, and took initiative to overcome challenges",
  },
  {
    name: "Hayden Smith",
    project: "Project Name Nine",
    rate: "4.9",
    date: "June 02,2024",
    description:
      "Trevor has been an exceptional asset to the [Project Name] project. Their dedication, creativity, and problem-solving skills have been invaluable. They consistently delivered high-quality work, collaborated seamlessly with the team, and took initiative to overcome challenges",
  },
  {
    name: "Keegan Allen",
    project: "Project Name Nine",
    rate: "5.0",
    date: "June 02,2024",
    description:
      "Trevor has been an exceptional asset to the [Project Name] project. Their dedication, creativity, and problem-solving skills have been invaluable. They consistently delivered high-quality work, collaborated seamlessly with the team, and took initiative to overcome challenges",
  },
  {
    name: "Marcella Smith",
    project: "Project Name Nine",
    rate: "4.5",
    date: "June 02,2024",
    description:
      "Trevor has been an exceptional asset to the [Project Name] project. Their dedication, creativity, and problem-solving skills have been invaluable. They consistently delivered high-quality work, collaborated seamlessly with the team, and took initiative to overcome challenges",
  },
  {
    name: "Jyotsna Chauhan",
    project: "Project Name Nine",
    rate: "4.4",
    date: "June 02,2024",
    description:
      "Trevor has been an exceptional asset to the [Project Name] project. Their dedication, creativity, and problem-solving skills have been invaluable. They consistently delivered high-quality work, collaborated seamlessly with the team, and took initiative to overcome challenges",
  },
];

const TotalReviews = () => {
  const { submissionDetails } = useAppSelector(getSubmissionState);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [expandedItems, setExpandedItems] = useState<Set<number>>(new Set());
  const [rattingReview, setRattingReview] = useState<
    CandidateRattingReviewListResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== rattingReview.length) {
        setPage(page + 1);
      }
    }
  };

  const getRattingReviewList = async (candidate_id: string) => {
    try {
      setLoading(true);
      const requestObj = {
        page,
        candidate_id,
      };
      const response = await SubmissionService.getCandidateRattingList(
        requestObj
      );

      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setRattingReview(response.results);
        } else {
          setRattingReview((prevList) => [...prevList, ...response.results]);
        }
      } else {
        setRattingReview([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    submissionDetails?.candidate_id &&
      getRattingReviewList(submissionDetails?.candidate_id);
  }, [page]);

  const handleToggle = (index: number) => {
    setExpandedItems((prev) => {
      const newExpandedItems = new Set(prev);
      if (newExpandedItems.has(index)) {
        newExpandedItems.delete(index);
      } else {
        newExpandedItems.add(index);
      }
      return newExpandedItems;
    });
  };

  const getSubDesc = useCallback(
    (description: string, index: number) => {
      if (expandedItems.has(index)) {
        return description;
      } else if (description.length > 350) {
        const subDes = description.substring(0, 350);
        return `${subDes}...`;
      } else {
        return description;
      }
    },
    [expandedItems]
  );

  return (
    <>
      <Box
        sx={{
          height: "100%",
        }}
        onScroll={loading ? () => {} : loadMoreItems}
      >
        <Stack pt={4} pb={2}>
          <AtsTitleText
            text={`Total Reviews (${totalItem ?? 0})`}
            fs={16}
            fw={500}
            textColor="#000"
          />
        </Stack>
        <Box>
          <Grid container rowSpacing={"35px"} columnSpacing={"33px"}>
            {loading
              ? Array.from(new Array(2)).map((_, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <Stack gap={0.5}>
                      <Stack direction={"row"} gap={1}>
                        <Skeleton variant="circular" width={40} height={40} />

                        <Stack gap={1}>
                          <Skeleton variant="text" width={140} height={18} />

                          <Skeleton variant="text" width={140} height={18} />
                        </Stack>
                      </Stack>
                      <Stack direction={"row"} gap={0.3} py={1}>
                        <Skeleton variant="text" width={18} height={18} />

                        <Star sx={{ fontSize: 15, color: "#ffcd34" }} />
                        <Stack px={1}>
                          <AtsTitleText
                            textColor={COLORS.LIGHT_GRAY}
                            text="|"
                          />
                        </Stack>
                        <Skeleton variant="text" width={140} height={18} />
                      </Stack>
                      <Stack direction={"row"}>
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={108}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                ))
              : rattingReview.map((review, index) => {
                  const first_name = isNotNullOrUndefined(review.first_name)
                    ? review.first_name
                    : "";
                  const last_name = isNotNullOrUndefined(review.last_name)
                    ? review.last_name
                    : "";
                  const description = isNotNullOrUndefined(review.description)
                    ? review.description
                    : "";
                  const projectName = "Project Name Nine";
                  const rating = isNotNullOrUndefined(review.rating)
                    ? formateOneDigitNum(review.rating)
                    : "0";
                  const date = isNotNullOrUndefined(review.created)
                    ? getDateFormatMMMMDYYYYY(review.created)
                    : "";
                  const name = `${first_name} ${last_name}`;
                  const shortName = getInitials(name);
                  return (
                    <Grid key={index} item xs={12} sm={6}>
                      <Stack gap={0.5}>
                        <Stack direction={"row"} gap={1}>
                          {loading ? (
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                bgcolor: BG_COLORS.PRIMARY,
                              }}
                            >
                              <AtsTitleText
                                text={shortName}
                                fs={14}
                                fw={600}
                                textColor={COLORS.PRIMARY}
                              />
                            </Avatar>
                          )}
                          {/* <Box
                        sx={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                          bgcolor: BG_COLORS.PRIMARY,
                          color: COLORS.PRIMARY,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                      
                      </Box> */}
                          <Stack gap={1}>
                            {loading ? (
                              <Skeleton
                                variant="text"
                                width={140}
                                height={18}
                              />
                            ) : (
                              <AtsTitleText
                                text={name}
                                fs={14}
                                fw={600}
                                textColor="#000"
                              />
                            )}
                            {loading ? (
                              <Skeleton
                                variant="text"
                                width={140}
                                height={18}
                              />
                            ) : (
                              <AtsTitleText
                                text={projectName}
                                fs={12}
                                fw={400}
                                textColor={COLORS.LIGHT_GRAY}
                              />
                            )}
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} gap={0.3} py={1}>
                          {loading ? (
                            <Skeleton variant="text" width={18} height={18} />
                          ) : (
                            <AtsTitleText
                              fs={12}
                              fw={400}
                              textColor="#000"
                              text={rating}
                            />
                          )}
                          <Star sx={{ fontSize: 15, color: "#ffcd34" }} />
                          <Stack px={1}>
                            <AtsTitleText
                              textColor={COLORS.LIGHT_GRAY}
                              text="|"
                            />
                          </Stack>
                          {loading ? (
                            <Skeleton variant="text" width={140} height={18} />
                          ) : (
                            <AtsTitleText
                              fs={12}
                              fw={400}
                              textColor={COLORS.LIGHT_GRAY}
                              text={getDateFormatMMMMDYYYYY(date)}
                            />
                          )}
                        </Stack>
                        <Stack direction={"row"}>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={108}
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: COLORS.LIGHT_GRAY,
                              }}
                            >
                              {getSubDesc(description, index)}{" "}
                              {expandedItems.has(index) ? (
                                <span
                                  style={{
                                    color: COLORS.PRIMARY,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleToggle(index)}
                                >
                                  Read Less
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: COLORS.PRIMARY,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleToggle(index)}
                                >
                                  Read More
                                </span>
                              )}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
            {}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TotalReviews;
