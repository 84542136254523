export enum JobTypeEnum {
  CONTRACT = "Contract",
  INTERSHIP = "Internship",
  DIRECT_HIRE = "Direct hire",
  CONTRACT_TO_HIRE = "Contract to hire",
}
export enum RateAsEnum {
  GOOD_FIT = "Good fit",
  NOT_FIT = "Not a fit",
  MAYBE = "Maybe",
}
export enum StatisticsCountEnum {
  NewSubmission = "New Submission",
  Interviewing = "Interviewing",
  Screened = "Screened",
  Shortlisted = "Shortlisted",
  OffersExtended = "Offers Extended",
  OfferAccepted = "Offer Accepted",
  Hired = "Hired",
  Rejected = "Rejected",
}
