import axios from "axios";
import { PaginatedResponse } from "../types/commonType";
import { JobListingResponse } from "../types/jobsTypes";
import {
  ActiveJobsForProjectParams,
  CandidateListResponse,
  ContractorRoleResponse,
  CotractorsForProjectParams,
  createProjectParams,
  ProjectCreationResponse,
  ProjectDetailsParams,
  ProjectListParams,
  ProjectListProps,
  projectResponse,
  ProjectStats,
  ProjectStatsParams,
  RolesForProjectParams,
  TeamMemberProps,
} from "../types/projectTypes";

const PROJECT_BASE_URL = "https://dev-project.esteemed.io";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  UPLOAD_BASE: "/file/upload/node/",
  MY_PROJECT: "my-projects/get?_format=json",
  TEAM_MEMBER: "team-members",
  UPLOAD_FILE: "project_documents/field_ats_attchments?_format=json",
  UPLOAD_LOGO: "project/field_ats_project_image?_format=json",
  CREATE_PROJECT: "project/create?_format=json",
  PROJECT_LIST: "projects",
  PROJECT_STATS: "project-stats?_format=json",
  PROJECT_DETAILS: "project/get?_format=json",
  PROJECT_ACTIVE_JOBS: "project-active-jobs",
  PROJECT_ROLES: "project-placement-jobs",
  PROJECT_CONTRACTORS: "project-contractors",
};

const MY_PROJECT_URL = PROJECT_BASE_URL + Endpoint.BASE + Endpoint.MY_PROJECT;
const TEAM_MEMBER_URL = Endpoint.BASE + Endpoint.TEAM_MEMBER;
const UPLOAD_FILE_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_BASE + Endpoint.UPLOAD_FILE;
const UPLOAD_LOGO_URL =
  PROJECT_BASE_URL + Endpoint.UPLOAD_BASE + Endpoint.UPLOAD_LOGO;
const CREATE_PROJECT_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.CREATE_PROJECT;
const PROJECT_LIST_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.PROJECT_LIST;
const PROJECT_DETAILS_URL =
  PROJECT_BASE_URL + Endpoint.BASE + Endpoint.PROJECT_DETAILS;
const PROJECT_STATS_URL = Endpoint.BASE + Endpoint.PROJECT_STATS;
const PROJECT_ACTIVE_JOBS_URL = Endpoint.BASE + Endpoint.PROJECT_ACTIVE_JOBS;
const PROJECT_ROLES_URL = Endpoint.BASE + Endpoint.PROJECT_ROLES;
const PROJECT_CONTRACTORS_URL = Endpoint.BASE + Endpoint.PROJECT_CONTRACTORS;

export class ProjectService {
  static async getMyProject(): Promise<PaginatedResponse<projectResponse>> {
    const endpoint = MY_PROJECT_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getProjectMember(): Promise<PaginatedResponse<TeamMemberProps>> {
    const response = await axios.get(TEAM_MEMBER_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async uploadDocumentForProject(binary: ArrayBuffer): Promise<any> {
    const response = await axios.post(UPLOAD_FILE_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file;filename="test.pdf"`,
      },
    });
    return response.data;
  }
  static async uploadLogoForProject(
    binary: ArrayBuffer,
    fileName: string
  ): Promise<any> {
    const response = await axios.post(UPLOAD_LOGO_URL, binary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `file; filename="${fileName}"`,
      },
    });
    return response.data;
  }
  static async createProject(
    request: createProjectParams
  ): Promise<ProjectCreationResponse> {
    const response = await axios.post(CREATE_PROJECT_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getProjectList(
    params: Partial<ProjectListParams>
  ): Promise<PaginatedResponse<ProjectListProps>> {
    const response = await axios.get(PROJECT_LIST_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getProjectState(
    params: Partial<ProjectStatsParams>
  ): Promise<ProjectStats> {
    const response = await axios.get(PROJECT_STATS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  // PROJECT DETAILS

  static async getProjectDetails(
    params: Partial<ProjectDetailsParams>
  ): Promise<ProjectListProps> {
    const response = await axios.get(PROJECT_DETAILS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getActiveJobsForProject(
    params: Partial<ActiveJobsForProjectParams>
  ): Promise<PaginatedResponse<JobListingResponse>> {
    const response = await axios.get(PROJECT_ACTIVE_JOBS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  // PROJECT CONTRACTORS

  static async getRolesForProject(
    params: Partial<RolesForProjectParams>
  ): Promise<PaginatedResponse<ContractorRoleResponse>> {
    const response = await axios.get(PROJECT_ROLES_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getContractorsForProject(
    params: Partial<CotractorsForProjectParams>
  ): Promise<PaginatedResponse<CandidateListResponse>> {
    const response = await axios.get(PROJECT_CONTRACTORS_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
