import { Search } from "@mui/icons-material";
import { Collapse, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { BG_COLORS, COLORS } from "../theme";
import { TitleIdOptionProps } from "../types/commonType";
import { useClickOutSide } from "../utils/common";
import AtsTextInput from "./AtsTextInput";
import AtsTitleText from "./AtsTitleText";

interface Props {
  searchValue: string;
  selected: string;
  placeHolder?: string;
  options: TitleIdOptionProps[];
  onSelect: (selected: string) => void;
  handleChange?: (value: string) => void;
  onScroll?: (e: any) => void;
}

const AtsAutoComplete = ({
  onSelect,
  searchValue,
  onScroll,
  options,
  selected,
  handleChange,
  placeHolder,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const onChange = (selectValue: string | TitleIdOptionProps) => {
    if (typeof selectValue === "string") {
      onSelect(selectValue);
    } else {
      onSelect(selectValue.id);
    }
  };
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <div ref={domNode} style={{ position: "relative" }}>
      <AtsTextInput
        height="50px"
        placeholder={placeHolder}
        startAdornment={<Search />}
        value={searchValue}
        onChange={(e) => {
          // onChange(e.target.value);
          handleChange(e.target.value);
        }}
        onClick={() => setShow(!show)}
      />
      <Collapse
        sx={{
          boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
          borderRadius: "6px",
        }}
        in={show}
      >
        <div
          onScroll={onScroll}
          style={{
            maxHeight: 200,
            padding: "10px",
            overflow: "auto",
          }}
          className=""
        >
          {options.length ? (
            options.map((option: TitleIdOptionProps, index) => (
              <MenuItem
                key={index}
                value={option.id}
                onClick={() => {
                  onChange(option.id);
                  setShow(false);
                }}
                sx={{
                  bgcolor: selected === option.id ? BG_COLORS.PRIMARY : "white",
                }}
              >
                <Stack>
                  <AtsTitleText
                    text={option.title}
                    fs={14}
                    fw={500}
                    textColor={
                      selected === option.id ? COLORS.PRIMARY : COLORS.BLACK
                    }
                  />
                </Stack>
              </MenuItem>
            ))
          ) : (
            <AtsTitleText text={"No Result"} />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default AtsAutoComplete;
