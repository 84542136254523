import React from "react";
import AtsTextArea from "../../../../components/AtsTextArea";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { Stack } from "@mui/material";
import { createProjectParams } from "../../../../types/projectTypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import AtsTextInput from "../../../../components/AtsTextInput";

const AddProjectDetails = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);

  const handleChange = (key: keyof createProjectParams, value: string) => {
    const updateProjectData = {
      ...createProjectState,
      [key]: value,
    };
    dispatch(setCreateProjectState(updateProjectData));
  };

  return (
    <JobCardWrapper
      header="Add project details"
      subText="Start your new project and manage your jobs and tasks"
    >
      <Stack pr={1} mb={1}>
        <Stack gap={"25px"}>
          <Stack gap={1}>
            <AtsTitleText
              text={"Project Name*"}
              fs={"0.875rem"}
              fw={500}
              textColor={COLORS.LIGHT_GRAY}
            />
            <AtsTextInput
              placeholder="Name (required)"
              value={createProjectState.title}
              onChange={(event) => handleChange("title", event.target.value)}
            />
          </Stack>
          <AtsTextArea
            header="Project description*"
            loading={false}
            cols={12}
            rows={7}
            className="textArea"
            value={createProjectState.description}
            onChange={(event) =>
              handleChange("description", event.target.value)
            }
          />
        </Stack>
      </Stack>
    </JobCardWrapper>
  );
};

export default AddProjectDetails;
