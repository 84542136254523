import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsCheckbox from "../../../components/AtsCheckbox";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsDataTable from "../../../components/AtsDataTable";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { FilterIcon, TickCircleIcon } from "../../../assets/icons/Icons";
import { IUserManagemnetProps } from "../UserManagement";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}
interface UserProps {
  id: string;
  name: string;
  job_title: string;
  job_type: string;
  email: string;
  phone: string;
  role: string;
}
const activeUser: UserProps[] = [
  {
    id: "1",
    name: "Daniel Anderson",
    job_title: "Chief Hiring Manager",
    job_type: "Human Resources",
    email: "daniel.anderson@gmail.com",
    phone: "+ 1 (856) 458-6010",
    role: "Admin",
  },
  {
    id: "2",
    name: "Daniel Anderson",
    job_title: "Chief Hiring Manager",
    job_type: "Human Resources",
    email: "daniel.anderson@gmail.com",
    phone: "+ 1 (856) 458-6010",
    role: "Admin",
  },
  {
    id: "3",
    name: "Daniel Anderson",
    job_title: "Chief Hiring Manager",
    job_type: "Human Resources",
    email: "daniel.anderson@gmail.com",
    phone: "+ 1 (856) 458-6010",
    role: "Admin",
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "id",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "name",
    header: "User",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "phone",
    header: "Phone no.",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "role",
    header: "Role",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const firstCellStyles = {
  padding: "5px 0px",
};

const InactiveUsers = ({ invitedUsers }: IUserManagemnetProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(selectedUsers.length === activeUser.length);
  }, [selectedUsers]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllUser = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedUsers(newSelectAll ? activeUser.map((user) => user.id) : []);
  };
  const customizeRow = (column: IColumnProps, user: UserProps) => {
    switch (column.key) {
      case "id":
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <AtsCheckbox
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleUserSelect(user.id)}
                p={0}
              />
            </Stack>
          </td>
        );
      case "name":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={0.3}>
              <Stack>
                <AccountCircle sx={{ fontSize: 70, color: "#6d6d6d" }} />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor="#000"
                  text={user.name}
                />

                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                  text={user.job_title}
                />
                <AtsTitleText
                  fs={12}
                  fw={500}
                  textColor={COLORS.BLACK}
                  text={user.job_type}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "email":
      case "phone":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={500}
                textColor={COLORS.BLACK}
                text={user[column.key]}
              />
            </Stack>
          </td>
        );
      case "role":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={"12"}
                fw={600}
                text="Team Member"
                buttonVariant="blueContained"
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={"12"}
                fw={600}
                text="Reactivate"
                buttonVariant="greenOutline"
                startIcon={<TickCircleIcon strokeColor={COLORS.GREEN} />}
              />
              <AtsCustomButton px={0.6}>
                <MoreVert sx={{ fontSize: "16px", color: COLORS.LIGHT_GRAY }} />
              </AtsCustomButton>
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = activeUser.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const isIndeterminate =
      selectedUsers.length > 0 && selectedUsers.length < activeUser.length;
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: index === 0 ? "50px" : column.width,
            minWidth: index === 0 ? "50px" : column.minWidth,
            padding: index === 0 ? "15px 0px" : "15px 10px",
            textAlign: "start",
          }}
        >
          {index === 0 ? (
            <AtsCheckbox
              checked={selectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllUser}
              p={0}
            />
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <AtsCustomButton px={0.5}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsTitleText
                    text={column.header}
                    fs={12}
                    fw={600}
                    textColor="#4f4f4f"
                    isCursor
                  />
                  {column.header.length > 0 && <FilterIcon />}
                </Stack>
              </AtsCustomButton>
              {column.header.length > 0 && (
                <Stack>
                  <KeyboardArrowUp
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mb: -0.4,
                      cursor: "pointer",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mt: -0.5,
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <AtsDataTable
        data={activeUser}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
    </>
  );
};

export default InactiveUsers;
