import { Box, Container, Dialog, Grid, Stack } from "@mui/material";
import React from "react";
import AtsBackLink from "../../../../../../../../../components/AtsBackLink";
import AtsText from "../../../../../../../../../components/AtsText";
import UserInfo from "../../../../../../../../../layout/Header/UserInfo";
import {
  getMakeAnOfferState,
  setIsOpenMakeAnOfferDialog,
} from "../../../../../../../../../redux/slices/makeAnOfferSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { WarningIcon } from "../../../../../../../assets/Icons";
import JobDetailsAndTerms from "../components/JobDetailsAndTerms";
import OfferSent from "../components/OfferSent";
import PriceSummary from "../components/PriceSummary";

const MakeAnOfferDialog = () => {
  const { font14, font24 } = FontUtil();
  const dispatch = useAppDispatch();
  const { isCompleteRequest, isOpenMakeAnOfferDialog } =
    useAppSelector(getMakeAnOfferState);

  return (
    <>
      <Dialog open={isOpenMakeAnOfferDialog} fullScreen>
        <Stack
          direction={"row"}
          className="header"
          sx={{ justifyContent: "space-between" }}
        >
          <Box
            sx={{
              height: "50px",
            }}
          >
            <img
              height={"100%"}
              src={require("../../../../../../../../../assets/images/logo.png")}
              alt="esteemed"
            />
          </Box>
          <Stack direction={"row"} alignItems={"center"} gap={2} mx={3}>
            <UserInfo />
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 83px)",
            overflow: "scroll",
          }}
        >
          <Container maxWidth="lg" sx={{ height: "95%" }}>
            <Stack
              my={2}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                border: BORDERS.GRAY,
                borderRadius: 1,
                height: "100%",
              }}
            >
              {isCompleteRequest ? (
                <OfferSent />
              ) : (
                <>
                  <Stack
                    direction={"row"}
                    px={4}
                    py={2}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <AtsText
                      text={"Make an offer"}
                      sx={{
                        ...font24,
                        color: COLORS.BLACK,
                        fontWeight: 600,
                      }}
                    />
                    <AtsBackLink
                      text="Go Back"
                      onClick={() =>
                        dispatch(setIsOpenMakeAnOfferDialog(false))
                      }
                    />
                  </Stack>
                  <Stack
                    sx={{
                      height: "calc(100% - 63px)",
                    }}
                    px={4}
                    py={2}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
                        <Stack gap={2}>
                          <JobDetailsAndTerms />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <WarningIcon />
                            <AtsText
                              text={
                                "Your project won’t be confirmed until your service provider accepts your request (within 24 hours)."
                              }
                              sx={{
                                ...font14,
                                color: COLORS.BLACK,
                                fontWeight: 400,
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <PriceSummary />
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
            </Stack>
          </Container>
        </Box>
      </Dialog>
    </>
  );
};

export default MakeAnOfferDialog;
