import { Stack } from "@mui/material";
import React from "react";
import { BORDERS, BORDERS_2X, COLORS } from "../../theme";
import AtsTitleText from "../AtsTitleText";
import FontUtil from "../../utils/FontUtil";
import { HomeIcon, StarIcon } from "../../pages/Jobs/assets/Icons";

interface UserProfileProps {
  name: string;
  designation: string;
  rate?: string | number;
  fs?: number;
  address: string;
  isHideBorder?: boolean;
}

const UserProfileInfo = ({
  name,
  designation,
  rate,
  address,
  fs = 14,
  isHideBorder,
}: UserProfileProps) => {
  return (
    <>
      <Stack
        direction={"row"}
        gap={2}
        sx={{ py: 2, ...(!isHideBorder && { borderBottom: BORDERS_2X.GRAY }) }}
      >
        <Stack>
          <img
            src={require("../../assets/images/candidate.png")}
            alt="Nvidia Logo"
            style={{
              height: "auto",
              marginRight: "10px",
              width: "66px",
              borderRadius: "100%",
            }}
          />
        </Stack>
        <Stack gap={0.5}>
          <AtsTitleText fs={fs} fw={600} textColor={COLORS.BLACK} text={name} />
          <AtsTitleText
            text={designation}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
          <Stack direction={"row"} gap={1}>
            {rate && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                pr={1}
                sx={{ borderRight: BORDERS.GRAY }}
              >
                <AtsTitleText
                  text={rate}
                  fs={12}
                  fw={400}
                  textColor={COLORS.BLACK}
                />
                <StarIcon />
              </Stack>
            )}

            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              pl={rate ? 1 : 0}
            >
              <HomeIcon />
              <AtsTitleText
                text={address}
                fs={12}
                fw={600}
                textColor={COLORS.LIGHT_GRAY}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default UserProfileInfo;
