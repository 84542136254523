import { CheckCircle, RadioButtonChecked } from "@mui/icons-material";
import {
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import { COLORS } from "../theme";
import AtsTitleText from "./AtsTitleText";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.GRAY,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.GREEN,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLORS.GRAY,
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => {
  console.log("ownerState", ownerState);
  return {
    color: theme.palette.text.disabled,
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: COLORS.GREEN,
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      zIndex: 1,
      color: COLORS.GRAY,
      fontSize: 18,
    },
  };
});

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <RadioButtonChecked className="QontoStepIcon-circle" />
      )}
    </ColorlibStepIconRoot>
  );
}

interface IProps {
  activeStep: number;
  stepData: any[];
}

const AtsStepper = ({ activeStep, stepData }: IProps) => {
  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={
          <ColorlibConnector
            sx={{
              marginLeft: "8px",
            }}
          />
        }
      >
        {stepData.map((step, index) => (
          <Step key={step.status}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Stack
                gap={1}
                sx={{
                  position: "relative",
                }}
              >
                <AtsTitleText
                  text={step.status}
                  fs={14}
                  fw={600}
                  textColor={
                    index < activeStep ? COLORS.GREEN : COLORS.LIGHT_GRAY
                  }
                />
                {index < activeStep && (
                  <Stack
                    sx={{
                      position: "absolute",
                      top: 28,
                    }}
                  >
                    <AtsTitleText
                      text={step.date}
                      fs={11}
                      fw={400}
                      textColor={COLORS.BLACK}
                    />
                  </Stack>
                )}
              </Stack>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default AtsStepper;
