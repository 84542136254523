import { Close } from "@mui/icons-material";
import { MenuItem, Select, Stack } from "@mui/material";
import React, { useState } from "react";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import AtsIcon from "../../../../components/AtsIcon";
import AtsText from "../../../../components/AtsText";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS } from "../../../../theme";
import JobsSwitch from "../../../Dashboard/PostedJobs/JobsSwitch";

const JOBS = [
  {
    title: "Good Fit",
    type: "Good Fit",
  },
  {
    title: "Not a Fit",
    type: "Not a Fit",
  },
  {
    title: "Maybe",
    type: "Maybe",
  },
  {
    title: "Unrated",
    type: "Unrated",
  },
  {
    title: "All",
    type: "All",
  },
];

const JobFilterSection = () => {
  const [active, setActive] = useState<string>("Good Fit");
  const [show, setShow] = useState<boolean>(false);
  const [keySkills, setSkills] = React.useState("");
  const handleKeySkillsChange = (event) => {
    setSkills(event.target.value);
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={3}
        // pt={1}
        pb={3}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <Stack
          direction={"row"}
          sx={{ minHeight: 35 }}
          alignItems={"center"}
          gap={0.3}
        >
          <AtsCustomButton px={0.5} onClick={(w) => setShow(!show)}>
            <Stack direction={"row"} gap={0.5}>
              {show ? (
                <Close sx={{ fontSize: "16px !important" }} />
              ) : (
                <AtsIcon icon="icon-setting-4" height="16px" fw={600} />
              )}
              <AtsTitleText text={"Filter"} />
            </Stack>
          </AtsCustomButton>

          {show && (
            <>
              <AtsText
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#6d6d6d",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
                text="|"
              />
              <AtsText
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#000",
                }}
                text="Ratings:"
              />
              <Stack
                sx={{
                  border: "2px solid #dcdfe7",
                  borderRadius: "20px",
                  marginLeft: "8px",
                  marginRight: "13px",
                }}
              >
                <JobsSwitch
                  jobs={JOBS}
                  active={active}
                  onChange={(value) => setActive(value)}
                />
              </Stack>
              {/* <FormControl fullWidth size="small"> */}
              <Select
                labelId="skills-label"
                value={keySkills}
                onChange={handleKeySkillsChange}
                displayEmpty
                sx={{
                  fontSize: "12px", // Font size for the select input
                  fontWeight: "500",
                  padding: "0px 0px 0px 5px", // Padding for the select input
                  borderRadius: "20px", // Rounded corners
                  backgroundColor: "inherit",
                  color: "#6d6d6d",
                  width: "130px",
                  height: "30px",
                  border: "1px solid #6d6d6d",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSelect-select": {
                    borderRadius: "20px", // Ensure dropdown options have rounded corners
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: "20px", // Rounded corners for the dropdown menu
                      "& .MuiMenuItem-root": {
                        fontSize: "12px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled style={{ fontSize: "12px" }}>
                  <Stack direction="row" alignItems="center">
                    Location <AtsText text="10" className="countCircle" />
                  </Stack>
                </MenuItem>
                <MenuItem value={1}>06/10</MenuItem>
                <MenuItem value={2}>02/10</MenuItem>
                <MenuItem value={3}>04/10</MenuItem>
                <MenuItem value={4}>08/10</MenuItem>
                <MenuItem value={5}>05/10</MenuItem>
              </Select>
              {/* </FormControl> */}
            </>
          )}
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <AtsIcon icon="icon-export-3" height="16px" fw={600} />
          <AtsTitleText text={"Export"} />
        </Stack>
      </Stack>
    </>
  );
};

export default JobFilterSection;
