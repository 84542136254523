import {
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getPostOpportunityState,
  setActiveStep,
  setActiveStep2,
  setActiveStep3,
  setIsEdit,
  setSelectedConfirm,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, BORDERS_2X, COLORS } from "../../../theme";
import { formattedAmount } from "../../../utils/common";
import JobCardWrapper from "../components/JobCardWrapper";
import { useEffect, useState } from "react";
import { getStep1 } from "../PostOpportunity";
import { getStepCondition } from "./Stepper";

const VicePresidentBusinessDevlopment = () => {
  const dispatch = useAppDispatch();
  const { jobDetailsById, postOpportunity, selectedConfirm } = useAppSelector(
    getPostOpportunityState
  );
  const [checkData, setCheckData] = useState([]);

  const checkDataHour = [
    {
      title: jobDetailsById.title,
      subTitle: "Confirm your job Title.",
      route: () => {
        dispatch(setActiveStep(getStep1(jobDetailsById.job_type)));
        dispatch(setActiveStep2(2));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "title",
    },
    {
      title: jobDetailsById.job_type,
      subTitle: `Confirm this is a ${jobDetailsById.job_type} position with a desined start and end date.`,
      route: () => {
        dispatch(setActiveStep(3));
        dispatch(setActiveStep2(0));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "position",
    },
    {
      title: jobDetailsById.work_mode,
      subTitle: `Confirm this is a ${jobDetailsById.work_mode}, with time on location required.`,
      route: () => {
        dispatch(setActiveStep(getStepCondition(jobDetailsById.job_type)));
        dispatch(setActiveStep2(0));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "location",
    },
    {
      title: `$${formattedAmount(
        postOpportunity.job_salary_low
      )}-$${formattedAmount(postOpportunity.job_salary_high)} / per hour`,
      subTitle: `Confirm the salary you're offering.`,
      route: () => {
        dispatch(setActiveStep(getStep1(jobDetailsById.job_type)));
        dispatch(setActiveStep2(5));
        dispatch(setActiveStep3(4));
        dispatch(setIsEdit(true));
      },
      key: "salary",
    },
  ];
  const checkDataYear = [
    {
      title: jobDetailsById.title,
      subTitle: "Confirm your job Title.",
      route: () => {
        dispatch(setActiveStep(getStep1(jobDetailsById.job_type)));
        dispatch(setActiveStep2(2));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "title",
    },
    {
      title: jobDetailsById.job_type,
      subTitle: `Confirm this is a ${jobDetailsById.job_type} position with a desined start and end date.`,
      route: () => {
        dispatch(setActiveStep(3));
        dispatch(setActiveStep2(0));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "position",
    },
    {
      title: jobDetailsById.work_mode,
      subTitle: `Confirm this is a ${jobDetailsById.work_mode}, with time on location required.`,
      route: () => {
        dispatch(setActiveStep(getStepCondition(jobDetailsById.job_type)));
        dispatch(setActiveStep2(0));
        dispatch(setActiveStep3(0));
        dispatch(setIsEdit(true));
      },
      key: "location",
    },
    {
      title: `$${formattedAmount(
        postOpportunity.job_pay_rate_low
      )}-$${formattedAmount(postOpportunity.job_pay_rate_high)} / per year`,
      subTitle: `Confirm the salary you're offering.`,
      route: () => {
        dispatch(setActiveStep(getStep1(jobDetailsById.job_type)));
        dispatch(setActiveStep2(5));
        dispatch(setActiveStep3(4));
        dispatch(setIsEdit(true));
      },
      key: "salary",
    },
  ];
  const handleChange = (value: string) => {
    if (selectedConfirm?.includes(value)) {
      const updatedCheck = selectedConfirm.filter((day) => day !== value);
      dispatch(setSelectedConfirm(updatedCheck));
    } else {
      const updatedCheck = [...selectedConfirm, value];
      dispatch(setSelectedConfirm(updatedCheck));
    }
  };

  useEffect(() => {
    dispatch(setIsEdit(false));
    if (jobDetailsById.job_type === "Direct hire") {
      setCheckData(checkDataYear);
    } else {
      setCheckData(checkDataHour);
    }
  }, []);

  return (
    <>
      <JobCardWrapper
        header="Vice President Of Business Development"
        subText={`Confirm the important details of your listing. Then publish and prepare for responses.`}
      >
        <Stack mb={1} pr={1} gap={2}>
          {checkData.map((item, index) => (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              sx={{
                border:
                  selectedConfirm.length && selectedConfirm?.includes(item.key)
                    ? BORDERS_2X.GREEN
                    : BORDERS.GRAY,
                borderRadius: 2,
                pr: 1,
                pl: 2,
                py: 3,
                cursor: "pointer",
              }}
              onClick={() => handleChange(item.key)}
            >
              <Stack gap={0.5}>
                <AtsTitleText
                  text={item.title}
                  fs={16}
                  fw={600}
                  textColor={
                    selectedConfirm.length &&
                    selectedConfirm?.includes(item.key)
                      ? COLORS.GREEN
                      : COLORS.BLACK
                  }
                />
                <AtsTitleText
                  text={item.subTitle}
                  fs={14}
                  fw={400}
                  textColor={COLORS.BLACK}
                />
                <Stack width={"fit-content"}>
                  <AtsTitleText
                    text={`Edit`}
                    isCursor
                    isUnderline
                    fs={12}
                    fw={600}
                    textColor={COLORS.BLACK}
                    onClick={item.route}
                  />
                </Stack>
              </Stack>
              <Box>
                {selectedConfirm.length &&
                selectedConfirm?.includes(item.key) ? (
                  <CheckCircleRounded
                    sx={{ color: COLORS.GREEN, fontSize: 30 }}
                  />
                ) : (
                  <CheckCircleOutlineRounded
                    sx={{ color: COLORS.LIGHT_GRAY, fontSize: 30 }}
                  />
                )}
              </Box>
            </Stack>
          ))}
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default VicePresidentBusinessDevlopment;
