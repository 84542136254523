import React, { useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../assets/styles/dateRangePicker.css";
import moment from "moment";

interface IProps {
  children: JSX.Element;
  handleEvent: (event: any, picker: any) => void;
  handleClear?: () => void;
}

const AtsDateRangePicker = ({ children, handleClear, handleEvent }: IProps) => {
  const pickerRef = useRef(null);
  const onClear = (evnet, picker) => {
    handleClear && handleClear();
    if (pickerRef.current) {
      pickerRef.current.setStartDate(moment().startOf("day"));
      pickerRef.current.setEndDate(moment().startOf("day"));
    }
  };
  return (
    <>
      <DateRangePicker
        initialSettings={{
          locale: {
            daysOfWeek: moment.weekdaysShort(),
          },
          startDate: moment().startOf("day"),
          endDate: moment().startOf("day"),
        }}
        onApply={handleEvent}
        onCancel={onClear}
      >
        {children}
      </DateRangePicker>
    </>
  );
};

export default AtsDateRangePicker;
