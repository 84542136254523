import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../../Api/jobsListingServices";
import AtsText from "../../../../components/AtsText";
import { setStatisticsCount } from "../../../../redux/slices/jobSlice";
import { useAppDispatch } from "../../../../redux/store";
import { getNumberValue } from "../../../../utils/common";
import { BORDERS } from "../../../../theme";

interface JobCountProps {
  loading?: boolean;
  nid: string;
}

export interface CountProps {
  title: string;
  value: number;
  color?: string;
}

const JobCounts = ({ nid }: JobCountProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<CountProps[]>([]);
  const countTitles = [
    "Submission",
    "Interviewing",
    "Screened",
    "Shortlisted",
    "Offers Extended",
    "Offers Accepted",
  ];

  const initialCountDetails = countTitles.map((title) => ({
    title,
    value: 0,
  }));

  const fetchStatisticsDetails = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobStatisticsById({ nid });
      if (response.results.length) {
        const count = response.results[0];
        dispatch(setStatisticsCount(count));
        setCount([
          { title: "Submission", value: count.submissions_count },
          { title: "Interviewing", value: count.interview_count },
          { title: "Screened", value: count.screened_count },
          { title: "Shortlisted", value: count.shortlist_count },
          { title: "Offers Extended", value: count.offer_extented_count },
          { title: "Offer Accepted", value: count.offer_accepted_count },
        ]);
      } else {
        setCount(initialCountDetails);
      }
    } catch (error) {
      console.error(error);
      setCount(initialCountDetails);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nid) {
      fetchStatisticsDetails();
    }
  }, [nid]);

  return (
    <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
      {loading
        ? initialCountDetails.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: BORDERS.GRAY,
                borderRadius: 2,
                py: 1,
                px: 1.5,
                flex: 1,
                minWidth: 95,
                maxWidth: 95,
                boxShadow: `rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;`,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Skeleton
                  variant="text"
                  width={"100%"}
                  sx={{ fontSize: "1rem" }}
                />
              </Stack>
              <AtsText
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#4f4f4f",
                  mt: 1,
                }}
                text={item.title}
              />
            </Box>
          ))
        : count.map((count, index) => (
            <Box
              key={index}
              sx={{
                border: BORDERS.GRAY,
                borderRadius: 2,
                py: 1,
                px: 1.5,
                flex: 1,
                minWidth: 95,
                maxWidth: 95,
                boxShadow: `rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;`,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsText
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#231f20",
                  }}
                  text={getNumberValue(count.value, 0)}
                />
              </Stack>
              <AtsText
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#4f4f4f",
                  mt: 1,
                }}
                text={count.title}
              />
            </Box>
          ))}
    </Stack>
  );
};

export default JobCounts;
