import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  // Add other user info fields as needed
}

interface CallInfo {
  id: string;
  callBy: UserInfo;
  mode: "INCOMING" | "OUTGOING";
  // Add other call-related fields as needed
}

interface CallingState {
  call: CallInfo | null;
  isCallDisconnected: boolean;
}

const initialState: CallingState = {
  call: null,
  isCallDisconnected: false,
};

const callingSlice = createSlice({
  name: "calling",
  initialState,
  reducers: {
    setIncomingOrOutgoingCall(state, action: PayloadAction<CallInfo>) {
      state.call = action.payload;
    },
    setAcceptCall(state, action: PayloadAction<Partial<CallInfo>>) {
      if (state.call) {
        state.call = { ...state.call, ...action.payload };
      }
    },
    setCallIsDisconnected(state) {
      state.isCallDisconnected = true;
      state.call = null;
    },
    // Additional calling-related actions can go here
  },
});

export const {
  setIncomingOrOutgoingCall,
  setAcceptCall,
  setCallIsDisconnected,
} = callingSlice.actions;
export const getCandidateState = (state: RootState) => state.calling;
export default callingSlice.reducer;
