import { Box } from "@mui/material";
import React from "react";
import { BG_COLORS } from "../theme";

interface CustomButtonProps {
  children: JSX.Element;
  onClick?: (e: any) => void;
  px?: number | string;
  py?: number | string;
  hoverColor?: string;
}

const AtsCustomButton = ({
  children,
  onClick,
  px = 1.5,
  py = 0.6,
  hoverColor = BG_COLORS.GRAY,
}: CustomButtonProps) => {
  return (
    <Box
      component={"div"}
      onClick={onClick ? onClick : () => {}}
      sx={{
        px,
        py,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "5px",
        width: "fit-content",
        ":hover": { bgcolor: hoverColor },
      }}
    >
      {children}
    </Box>
  );
};

export default AtsCustomButton;
