import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef } from "react";

export const useClickOutSide = (handler: any) => {
  const domNode = useRef<any>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return domNode;
};

export const getStringValue = (value: string, preValue: string | number) => {
  if (value) {
    if (value.length) return value;
  } else {
    return preValue;
  }
};
export const getNumberValue = (value: number, preValue: number) => {
  if (value) {
    return value;
  } else {
    return preValue;
  }
};
export const formattedAmount = (amount: number | string) => {
  const parseAmount = typeof amount === "string" ? parseFloat(amount) : amount;
  const usFormat = parseAmount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return usFormat;
};

export const getInitials = (name: string) => {
  const words = name.split(" ");
  const initials = words.map((word) => word.charAt(0)).join("");

  return initials.toUpperCase();
};

export const makeid = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const emailValidation = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
