import { ArrowForwardIos } from "@mui/icons-material";
import { Stack } from "@mui/material";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../../../../../components/AtsText";
import UserProfileInfo from "../../../../../../../../../components/common/UserProfileInfo";
import {
  BG_COLORS,
  BORDERS_2X,
  COLORS,
} from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { setIsCompleteRequest } from "../../../../../../../../../redux/slices/makeAnOfferSlice";

const PriceSummary = () => {
  const { font10, font14, font18 } = FontUtil();
  const dispatch = useAppDispatch();
  return (
    <>
      <Stack
        sx={{
          px: 3,
          py: 2,
          bgcolor: BG_COLORS.GRAY,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsText
            sx={{
              ...font18,
              fontWeight: 600,
              color: COLORS.BLACK,
            }}
            text="Price Summary"
          />
        </Stack>
        <UserProfileInfo
          name="Trevor Cunningham"
          designation="VP Administration and Finance"
          rate={"4.8"}
          address="Lives in Lisbon, Portugal"
        />
        <Stack gap={3} sx={{ pt: 2, pb: 2 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 600,
                color: COLORS.LIGHT_GRAY,
              }}
              text="Duration"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 600,
              }}
              text="40 Hrs/wk for 2 weeks"
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 600,
                color: COLORS.LIGHT_GRAY,
              }}
              text="Dates"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 600,
              }}
              text="June 24, 2024 -  July 07, 2024"
            />
          </Stack>

          <Stack sx={{ pt: 1.3 }}>
            <div className="deviderBorder"></div>
          </Stack>
        </Stack>
        <Stack gap={3} sx={{ pt: 2, pb: 2 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
                borderBottom: "1px solid",
              }}
              text="Rate per hour"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 500,
              }}
              text="$65.00"
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
                borderBottom: "1px solid",
              }}
              text="40 Hrs x 2 Wk"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 500,
              }}
              text="$5,200.00"
            />
          </Stack>
          <Stack sx={{ pt: 1.3 }}>
            <div className="deviderBorder"></div>
          </Stack>
        </Stack>
        <Stack gap={3.5} pb={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={2}
            sx={{ borderBottom: BORDERS_2X.GRAY }}
          >
            <AtsText
              sx={{
                ...font14,
                fontWeight: 400,
                color: COLORS.BLACK,
              }}
              text="Total"
            />
            <AtsText
              sx={{
                ...font14,
                color: COLORS.BLACK,
                fontWeight: 500,
              }}
              text="$5,200.00"
            />
          </Stack>

          <AtsRoundedButton
            text="Complete your Request"
            endIcon={<ArrowForwardIos />}
            onClick={() => {
              dispatch(setIsCompleteRequest(true));
            }}
          />
          <Stack>
            <AtsText
              sx={{
                ...font10,
                fontWeight: 400,
                color: COLORS.LIGHT_GRAY,
              }}
              text="By selecting the button below, I agree to the Colleagues Seller Terms, Colleagues Employer Agreement, and Refund Policy, and that Esteemed can charge my payment method. I agree to pay the total amount shown if my hiring request is accepted."
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PriceSummary;
