import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserInformation } from "../../types/userManagementTypes";

interface CurrentUser {
  uid: string;
  roles: string[];
  name: string;
}

interface UserInfo {
  current_user: CurrentUser;
  csrf_token: string;
  logout_token: string;
  access_token: string;
}
interface AuthState {
  userInfo: Partial<UserInformation>;
  isAuth: boolean;
  isOpen: boolean;
}

const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
const isAuth = localStorage.getItem("userInfo") ? true : false;

const initialState: AuthState = {
  userInfo: userInfo,
  isAuth,
  isOpen: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state: AuthState, action) => {
      const { payload } = action;
      state.userInfo = payload;
    },
    setIsAuth: (state: AuthState, action) => {
      const { payload } = action;
      state.isAuth = payload;
    },
    setIsOpen: (state: AuthState, action) => {
      const { payload } = action;
      state.isOpen = payload;
    },
  },
});

export const { setUserInfo, setIsAuth, setIsOpen } = authSlice.actions;
export const getAuthState = (state: RootState) => state.auth;
export default authSlice.reducer;
