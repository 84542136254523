import { Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS, COLORS } from "../../../../../../theme";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { ProjectDetailsTabsEnum } from "../../../../../../enums/projectEnums";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import {
  getProjectDetailsState,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import AtsIcon from "../../../../../../components/AtsIcon";

const ProjectDetailsTabs = () => {
  const dispatch = useAppDispatch();
  const { selectedProjectDetailsTab } = useAppSelector(getProjectDetailsState);
  return (
    <>
      <Box
        sx={{
          bgcolor: BG_COLORS.PRIMARY,
          borderRadius: "10px",
          padding: "40px 24px 40px 30px",
          minWidth: 236,
          maxHeight: 350,
        }}
      >
        <AtsTitleText text={"Project Details"} fs={30} fw={300} />
        <Stack
          className="scrollHide"
          gap={"40px"}
          mt={"30px"}
          mb={"20px"}
          sx={{
            height: "calc(100% - 35px)",
            overflow: "auto",
          }}
        >
          {Object.values(ProjectDetailsTabsEnum).map((tab) => (
            <Stack
              key={tab}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => dispatch(setSelectedProjectDetailsTab(tab))}
            >
              <AtsTitleText
                text={tab}
                fs={18}
                fw={500}
                textColor={
                  selectedProjectDetailsTab === tab ? COLORS.PRIMARY : "#93b1e6"
                }
              />
              {selectedProjectDetailsTab === tab && (
                <AtsIcon
                  icon="icon-arrow-right-3"
                  height="16px"
                  fw={600}
                  color={COLORS.PRIMARY}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default ProjectDetailsTabs;
