import { Box, Skeleton } from "@mui/material";
import { BG_COLORS, COLORS } from "../theme";

interface ILabelProps {
  text: string | number;
  variant: "primary" | "warninig" | "success" | "danger" | "purple" | "blue";
  loading?: boolean;
  isCapital?: boolean;
}

const renderColor = (variant: string) => {
  switch (variant) {
    case "warninig":
      return {
        bgcolor: BG_COLORS.ORANGE,
        color: COLORS.ORANGE,
      };
    case "success":
      return {
        bgcolor: BG_COLORS.GREEN,
        color: COLORS.GREEN,
      };
    case "danger":
      return {
        bgcolor: BG_COLORS.RED,
        color: COLORS.RED,
      };
    case "purple":
      return {
        bgcolor: BG_COLORS.PURPLE,
        color: COLORS.PURPLE,
      };
    case "blue":
      return {
        bgcolor: BG_COLORS.BLUE,
        color: COLORS.BLUE,
      };
    default:
      return {
        bgcolor: BG_COLORS.PRIMARY,
        color: COLORS.PRIMARY,
      };
  }
};
const renderVariant = (variant: string) => {
  return {
    bgcolor: renderColor(variant).bgcolor,
    color: renderColor(variant).color,
  };
};

const AtsLabel = ({ text, variant, loading, isCapital }: ILabelProps) => {
  return (
    <Box
      sx={{
        ...renderVariant(variant),
        height: "20px",
        fontSize: 10,
        fontWeight: 700,
        borderRadius: "20px",
        px: 1,
        display: "flex",
        alignItems: "center",
        textTransform: isCapital ? "capitalize" : "uppercase",
      }}
    >
      {loading ? <Skeleton sx={renderVariant(variant)} /> : text}
    </Box>
  );
};

export default AtsLabel;
