import React from "react";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { Box, Stack } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import { Check } from "@mui/icons-material";

const texts = [
  "Provide onboarding time",
  "Integrate them with your team",
  "Communicate often",
];

const ThingOfRemember = () => {
  const { font14, font16 } = FontUtil();
  return (
    <>
      <Stack gap={1.5}>
        <AtsText
          text={"Things to remember"}
          sx={{
            ...font16,
            color: COLORS.BLACK,
            fontWeight: 600,
          }}
        />
        <AtsText
          text={
            "We ask every employer to treat their Colleague service providers as part of their team."
          }
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
        <Stack gap={1}>
          {texts.map((text) => (
            <Stack direction={"row"} gap={1} key={text}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "20px",
                  bgcolor: BG_COLORS.PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Check sx={{ color: COLORS.PRIMARY, fontSize: 13 }} />
              </Box>
              <AtsText
                text={text}
                sx={{
                  ...font14,
                  color: COLORS.BLACK,
                  fontWeight: 400,
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default ThingOfRemember;
