import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ListItemIcon, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmissionService } from "../../../../../Api/submissionServices";
import GreenThumb from "../../../../../assets/images/greenThumb.png";
import Heart from "../../../../../assets/images/heart.png";
import SpeedMetor from "../../../../../assets/images/speedMetor.png";
import AtsRoundedButton from "../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../components/AtsText";
import {
  getSubmissionState,
  setCandidateDetails,
  setIsCandidateDetailsDrawerOpen,
  setIsCandidateDetailsLoading,
} from "../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { BORDERS } from "../../../../../theme";
import { GetSubmissionResponse } from "../../../../../types/submissionTypes";
import CandidateColumn from "./CandidateColumn";
import InterviewDataTable from "./CandidateDetails/CandidateDetailsTabs/InterViewTab/modules/InterviewDataTable";
import DetailsDrawer from "./CandidateDetails/DetailsDrawer";
import { CandidateListHeader } from "./CandidateListHeader";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { getDateFormatMMMMDYYYYY } from "../../../../../utils/dateUtils";
import AtsIcon from "../../../../../components/AtsIcon";
import {
  JobTypeEnum,
  RateAsEnum,
  StatisticsCountEnum,
} from "../../../../../enums/jobsEnums";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Candidate",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "applied_on",
    header: "Applied",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "bill_rate",
    header: "Rate",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "duration",
    header: "Rate As",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Stage",
    width: "auto",
    minWidth: "100px",
  },
];

const CandidateList = () => {
  const { nid } = useParams();
  const dispatch = useAppDispatch();
  const { jobDetailsForSubmission } = useAppSelector(getSubmissionState);
  const [loading, setLoading] = useState<boolean>(false);
  const [submissions, setSubmissions] = useState<GetSubmissionResponse[]>([]);

  const onRowClick = (id: string) => {
    // dispatch(setIsCandidateDetailsDrawerOpen(true));
    // getCandidateDetials(id);
  };

  const cellStyles = {
    padding: "10px 10px",
  };

  const getCompareSalaryFromCandidateRate = (bill_rate: string) => {
    if (!bill_rate) {
      return (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, color: "#6d6d6d" }}
        ></Typography>
      );
    }
    const priceWithoutDollarSign = bill_rate.replace("$", "");
    const payRateHigh = parseFloat(jobDetailsForSubmission?.pay_rate_high);
    const salaryHigh = parseFloat(jobDetailsForSubmission?.salary_high);
    const billRate = parseFloat(priceWithoutDollarSign);

    if (jobDetailsForSubmission?.job_type === JobTypeEnum.DIRECT_HIRE) {
      const calculateSalary = salaryHigh / 192 / 12;
      if (calculateSalary > billRate) {
        return (
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#6d6d6d" }}>
            <span className="boldText">Over Budget</span> By ${billRate}
          </Typography>
        );
      } else {
        return (
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#6d6d6d" }}>
            <span className="boldText">Under Budget</span> By ${billRate}
          </Typography>
        );
      }
    } else {
      if (payRateHigh > billRate) {
        return (
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#6d6d6d" }}>
            <span className="boldText">Over Budget</span> By ${billRate}
          </Typography>
        );
      } else {
        return (
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#6d6d6d" }}>
            <span className="boldText">Under Budget</span> By ${billRate}
          </Typography>
        );
      }
    }
  };

  const customizeRow = (
    column: IColumnProps,
    submission: GetSubmissionResponse
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={cellStyles} key={column.key}>
            <CandidateColumn submission={submission} />
          </td>
        );
      case "applied_on":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor="#000"
                text={getDateFormatMMMMDYYYYY(submission.applied_on)}
              />
            </Stack>
          </td>
        );
      case "bill_rate":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack gap={0.3} mb={"15px"}>
              <>
                <ListItemIcon
                  className="dollerIcon"
                  sx={{ minWidth: "20px", mt: 0 }}
                >
                  <img
                    src={SpeedMetor}
                    alt="Nvidia Logo"
                    style={{ width: 30, height: 17 }}
                  />
                </ListItemIcon>
                {getCompareSalaryFromCandidateRate(submission?.bill_rate)}
              </>
            </Stack>
          </td>
        );
      case "duration":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <AtsIcon
                height="20px"
                icon={renderRateAsComponent(submission?.rate_as).icon}
                color={renderRateAsComponent(submission?.rate_as).color}
              />
              <AtsTitleText
                lineHeight={"0px"}
                text={submission?.rate_as}
                fs={12}
                fw={400}
                textColor="#000"
              />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={"12"}
                fw={600}
                text={submission?.stage}
                buttonVariant={renderStatisticsBgcolor(submission?.stage)}
              />
            </Stack>
          </td>
        );
    }
  };

  const renderRowComponent = () => {
    const leadsData = submissions.map(
      (value: GetSubmissionResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() => onRowClick(value.candidate_id)}
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            </tr>
          </React.Fragment>
        );
      }
    );
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <CandidateListHeader headerText={column.header} />
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  const getSubmissionsList = async () => {
    setLoading(true);
    try {
      const response = await SubmissionService.getSubmissionByJobId({
        job_id: nid,
      });
      const submissionData = response?.results;
      if (submissionData?.length) {
        setSubmissions(submissionData);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (nid) {
      getSubmissionsList();
    }
  }, [nid]);

  const candidatDrawerMemo = useMemo(() => <DetailsDrawer />, []);

  return (
    <>
      <InterviewDataTable
        data={submissions}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={loading}
      />
      {candidatDrawerMemo}
    </>
  );
};

export default CandidateList;

const renderRateAsComponent = (rateAs: string) => {
  if (rateAs === RateAsEnum.GOOD_FIT) {
    return {
      icon: `icon-like-1`,
      color: "#11B146",
    };
  } else if (rateAs === RateAsEnum.NOT_FIT) {
    return {
      icon: `icon-dislike`,
      color: "#DF4F4F",
    };
  } else {
    return {
      icon: `icon-message-question`,
      color: "#DF4F4F",
    };
  }
};

export const renderStatisticsBgcolor = (stage: string) => {
  if (stage === StatisticsCountEnum.Shortlisted) {
    return "greenContained";
  } else if (stage === StatisticsCountEnum.Rejected) {
    return "dangerContained";
  } else if (stage === StatisticsCountEnum.NewSubmission) {
    return "blueContained";
  } else if (stage === StatisticsCountEnum.Interviewing) {
    return "purpleContained";
  } else {
    return "primaryContained";
  }
};
