import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../theme";
import { AnimatePresence } from "framer-motion";
import AtsIcon from "../../../../../components/AtsIcon";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { ProjectService } from "../../../../../Api/projectServices";
import { ActiveJobsForProjectParams } from "../../../../../types/projectTypes";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Job from "../../../../Jobs/Job";
import ActiveJobsDetails from "./modules/ActiveJobsDetails";
import { JobListingResponse } from "../../../../../types/jobsTypes";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import {
  getJobState,
  setSelectedJob,
} from "../../../../../redux/slices/jobSlice";
import JobDetailsLoadingPage from "../../../../Jobs/JobDetailsLoadingPage";
import {
  getProjectDetailsState,
  setSelectedActiveJob,
} from "../../../../../redux/slices/projectDetailsSlice";
import JobDetails from "../../../../Jobs/JobDetails";

const dummyData = {
  nid: "100115580",
  title: "Encargado de ventas",
  job_status: "Open",
  job_priority: "High",
  work_mode: "In-person",
  job_type: "Direct hire",
  created: "2024-08-23T16:17:22+0300",
  job_address_address_line1: "Testing",
  job_address_administrative_area: "Alabama",
  job_address_country: "United States",
  job_address_locality: "Test",
  job_address_postal_code: "",
  job_start_date: "2024-08-31",
  job_end_date: null,
  job_open_closed: "1",
  submissions_count: 0,
};

const ActiveJobs = () => {
  const dispatch = useAppDispatch();
  const { selectedActiveJob } = useAppSelector(getProjectDetailsState);
  const { id } = useParams();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("xxl"));
  const jobListRef = useRef(null);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [jobList, setJobList] = useState<JobListingResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== jobList.length) {
        setPage(page + 1);
      }
    }
  };

  const fetchJobList = useMemo(() => {
    return (
      <div
        ref={jobListRef}
        onScroll={loading ? () => {} : loadMoreItems}
        className="scrollHide"
        style={{
          overflowX: "scroll",
          height: "100%",
          width: "100%",
        }}
      >
        <Stack
          gap={1}
          sx={{
            // height: "100%",
            pr: 2,
            pb: "10px",
          }}
        >
          {jobList.map((job, index) => (
            <Job
              loading={loading}
              key={job.nid}
              jobData={job}
              onSelect={() => dispatch(setSelectedJob(job.nid))}
              selectedJob={selectedActiveJob}
            />
          ))}
        </Stack>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, loading, jobList]);

  const jobDetailsComponent = useMemo(() => {
    return (
      <Box
        className="scrollHide"
        sx={{
          overflowX: "scroll",
          height: "100%",
          flex: 1,
        }}
      >
        <JobDetails selectedJob={selectedActiveJob} />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActiveJob]);

  const getJobs = async () => {
    try {
      setLoading(true);
      const requestObj = {
        project_id: id,
        ...(search.length && { title: search }),
      } as ActiveJobsForProjectParams;
      const response = await ProjectService.getActiveJobsForProject(requestObj);
      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setJobList(response.results);
          selectedActiveJob === "" &&
            dispatch(setSelectedActiveJob(response.results[0].nid));
        } else {
          setJobList((prevList) => [...prevList, ...response.results]);
        }
      } else {
        setTotalItem(0);
        dispatch(setSelectedActiveJob(""));
        setJobList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getJobs();
  }, [page, debouncedSearch]);
  return (
    <>
      <Stack sx={{ mb: 1.5 }}>
        <Stack
          direction={"row"}
          gap={2}
          alignItems={"center"}
          sx={{
            maxWidth: 305,
            minHeight: 28,
          }}
        >
          <Stack sx={{ width: "40%" }}>
            <AtsTitleText
              text={`${totalItem ?? 0} Jobs`}
              fs={14}
              fw={600}
              textColor={COLORS.BLACK}
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            width={"60%"}
          >
            <AnimatePresence>
              {showInput ? (
                <Stack
                  direction={"row"}
                  gap={1}
                  sx={{
                    width: "100%",
                    borderRadius: "20px",
                    border: BORDERS.GRAY,
                    bgcolor: "white",
                    pl: 1,
                    height: 25,
                  }}
                >
                  <input
                    type="text"
                    className="customInput"
                    style={{
                      width: "80%",
                    }}
                    value={search}
                    onChange={(e) => {
                      setPage(0);
                      setSearch(e.target.value);
                    }}
                  />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    mt={0.5}
                    pl={2}
                    sx={{ cursor: "pointer", width: "20%" }}
                    onClick={() => setShowInput(false)}
                  >
                    <AtsIcon icon="icon-search-normal-1" />
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  mt={0.5}
                  pl={2}
                  sx={{ cursor: "pointer", width: "15%" }}
                  onClick={() => setShowInput(true)}
                >
                  <AtsIcon icon="icon-search-normal-1" />
                </Stack>
              )}
            </AnimatePresence>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: "calc(100% - 40px)",
          // mb: 2,
          overflow: "auto",
        }}
      >
        <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
          <Box
            sx={{
              width: isLarge ? 325 : 320,
              height: "100%",
            }}
          >
            {fetchJobList}
          </Box>
          {/* {jobList?.length > 0 && jobDetailsComponent} */}
          {jobDetailsComponent}
        </Stack>
      </Stack>
    </>
  );
};

export default ActiveJobs;
