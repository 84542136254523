import { Circle } from "@mui/icons-material";
import { Box, Skeleton, Stack } from "@mui/material";
import ClockIcon from "../../../../assets/icons/clock.svg";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import { JobDetailsResponse } from "../../../../types/jobsTypes";
import { getStringValue } from "../../../../utils/common";

interface JobScheduleProps {
  jobDetails: JobDetailsResponse;
  loading?: boolean;
}

const JobSchedule = ({ jobDetails, loading }: JobScheduleProps) => {
  return (
    <Stack gap={2}>
      <AtsTitleText text="Schedule" fs={16} fw={600} textColor={COLORS.BLACK} />
      <Stack direction={"row"} gap={3} alignItems={"flex-start"}>
        <Box>
          <img src={ClockIcon} alt="Nvidia Logo" />
        </Box>
        <Stack gap={1}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Circle sx={{ fontSize: 6, color: "#000" }} />
            {loading ? (
              <Skeleton variant="rectangular" width={150} height={20} />
            ) : (
              <AtsTitleText
                text={`${getStringValue(
                  jobDetails?.hours_per_day,
                  ""
                )} Day shift`}
                fs={12}
                fw={400}
              />
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Circle sx={{ fontSize: 6, color: "#000" }} />
            {loading ? (
              <Skeleton variant="rectangular" width={150} height={20} />
            ) : (
              <AtsTitleText
                text={getStringValue(jobDetails?.hours_per_week, "")}
                fs={12}
                fw={400}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default JobSchedule;
