import { Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { BG_COLORS, COLORS } from "../../../../../../../../theme";
import AtsText from "../../../../../../../../components/AtsText";
import { getSubmissionState } from "../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { getAvailability } from "../../../CandidateColumn";

const DetailTopSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { candidateDetails, isCandidateDetailsLoading, submissionDetails } =
    useAppSelector(getSubmissionState);

  const details = [
    {
      label: "Joining Info",
      value:
        candidateDetails?.availability &&
        candidateDetails?.availability[0]?.available
          ? candidateDetails?.availability[0]?.available
          : "",
    },
    {
      label: "Availability",
      value: getAvailability(
        candidateDetails?.availability &&
          candidateDetails?.availability[0]?.availability
      ),
    },
    { label: "Rate", value: `${submissionDetails?.bill_rate}/hr` },
    {
      label: "No. of Key Skills",
      value: `${submissionDetails?.matched}/${submissionDetails?.total}`,
    },
  ];
  return (
    <Stack
      px={4}
      py={1.5}
      gap={2}
      direction={isMobile ? "column" : "row"}
      sx={{
        bgcolor: BG_COLORS.PRIMARY,
        borderRadius: 2,
      }}
    >
      {details.map((detail, index) => (
        <Stack key={index} gap={1} sx={{ flex: 1 }}>
          <AtsTitleText
            text={detail.label}
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
          {isCandidateDetailsLoading ? (
            <Skeleton height={20} />
          ) : (
            <AtsTitleText text={detail.value} fs={14} fw={500} />
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default DetailTopSection;
