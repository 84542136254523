import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface CandidateState {
  condidateTabValue: number;
}

const initialState: CandidateState = {
  condidateTabValue: 0,
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setCondidateTabValue: (state: CandidateState, action) => {
      const { payload } = action;
      state.condidateTabValue = payload;
    },
  },
});

export const { setCondidateTabValue } = candidateSlice.actions;
export const getCandidateState = (state: RootState) => state.candidate;
export default candidateSlice.reducer;
