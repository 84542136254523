import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Views } from "react-big-calendar";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getCalendarState,
  setCalendarView,
  setDate,
} from "../../../redux/slices/calendarSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BORDERS, COLORS } from "../../../theme";
import JobsSwitch from "../../Dashboard/PostedJobs/JobsSwitch";

const PROJECTS = [
  {
    title: "Weekly",
    type: Views.WEEK,
  },
  {
    title: "Monthly",
    type: Views.MONTH,
  },
];

const CalenderDateFilterSection = () => {
  const dispatch = useAppDispatch();
  const { date, calendarView } = useAppSelector(getCalendarState);

  const onPrevClick = () => {
    if (calendarView === Views.WEEK) {
      dispatch(setDate(moment(date).subtract(1, "w").toDate()));
    } else {
      dispatch(setDate(moment(date).subtract(1, "M").toDate()));
    }
  };

  const onNextClick = () => {
    if (calendarView === Views.WEEK) {
      dispatch(setDate(moment(date).add(1, "w").toDate()));
    } else {
      dispatch(setDate(moment(date).add(1, "M").toDate()));
    }
  };
  const onTodayClick = useCallback(() => {
    dispatch(setDate(moment().toDate()));
  }, []);
  const dateText = useMemo(() => {
    if (calendarView === Views.WEEK) {
      const from = moment(date)?.startOf("week");
      const to = moment(date)?.endOf("week");
      return `${from.format("MMMM DD")} to ${to.format("MMMM DD")}`;
    }
    if (calendarView === Views.MONTH) {
      return moment(date).format("MMMM YYYY");
    }
  }, [calendarView, date]);
  return (
    <>
      <AtsTitleText text="Calendar" fs={24} fw={600} textColor={COLORS.BLACK} />
      <Stack
        sx={{ pt: "22px", pb: "19px" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
          <AtsTitleText
            text={dateText}
            fs={20}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Stack direction={"row"} gap={"10px"}>
            <IconButton
              sx={{
                border: BORDERS.GRAY,
                p: 1,
              }}
              onClick={onPrevClick}
            >
              <ArrowBackIosNew sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
            </IconButton>
            <IconButton
              sx={{
                border: BORDERS.GRAY,
                p: 1,
              }}
              onClick={onNextClick}
            >
              <ArrowForwardIos sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
            </IconButton>
          </Stack>
          <AtsRoundedButton
            text="Today"
            buttonVariant="whiteContained"
            buttonHeight={32}
            onClick={onTodayClick}
          />
        </Stack>
        <JobsSwitch
          width="101px"
          jobs={PROJECTS}
          active={calendarView}
          onChange={(value) => {
            dispatch(setCalendarView(value));
          }}
        />
      </Stack>
    </>
  );
};

export default CalenderDateFilterSection;
