import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface MakeAnOferState {
  selectedOfferTab: number;
  isCompleteRequest: boolean;
  isOpenMakeAnOfferDialog: boolean;
  isOfferDetails: boolean;
}

const initialState: MakeAnOferState = {
  selectedOfferTab: 0,
  isCompleteRequest: false,
  isOpenMakeAnOfferDialog: false,
  isOfferDetails: false,
};

const makeAnOfferSlice = createSlice({
  name: "makeAnOffer",
  initialState,
  reducers: {
    setSelectedOfferTab: (state: MakeAnOferState, action) => {
      const { payload } = action;
      state.selectedOfferTab = payload;
    },
    setIsCompleteRequest: (state: MakeAnOferState, action) => {
      const { payload } = action;
      state.isCompleteRequest = payload;
    },
    setIsOpenMakeAnOfferDialog: (state: MakeAnOferState, action) => {
      const { payload } = action;
      state.isOpenMakeAnOfferDialog = payload;
    },
    setIsOfferDetails: (state: MakeAnOferState, action) => {
      const { payload } = action;
      state.isOfferDetails = payload;
    },
  },
});

export const {
  setSelectedOfferTab,
  setIsCompleteRequest,
  setIsOpenMakeAnOfferDialog,
  setIsOfferDetails,
} = makeAnOfferSlice.actions;
export const getMakeAnOfferState = (state: RootState) => state.makeAnOffer;
export default makeAnOfferSlice.reducer;
