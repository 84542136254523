import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { BG_COLORS, BORDERS, COLORS } from "../../theme";
import FontUtil from "../../utils/FontUtil";
import AtsCard from "../AtsCard";
import AtsText from "../AtsText";

interface Priority {
  icon: any;
  label: string;
  text: string;
}

interface IPrioritiesProps {
  prioritiesData: Priority[];
  headerText: string;
}

const TopPrioritiesComponnet = ({
  prioritiesData,
  headerText,
}: IPrioritiesProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerSlide, setItemsPerSlide] = useState(3);
  const [isHovered, setIsHovered] = useState<number | null>(null);
  const { font11, font14, font16, font17 } = FontUtil();

  useEffect(() => {
    const updateItemsPerSlide = () => {
      if (window.innerWidth < 600) {
        setItemsPerSlide(1);
      } else {
        setItemsPerSlide(3);
      }
    };

    window.addEventListener("resize", updateItemsPerSlide);
    updateItemsPerSlide();

    return () => window.removeEventListener("resize", updateItemsPerSlide);
  }, []);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? Math.ceil(prioritiesData.length / itemsPerSlide) - 1
        : prevIndex - 1
    );
  };

  const nextSlide = () => {
    if (isMobile) {
      setCurrentIndex((prevIndex) =>
        prevIndex === Math.ceil(prioritiesData.length / itemsPerSlide) - 1
          ? 0
          : prevIndex + 1
      );
    } else {
      setCurrentIndex((prevIndex) =>
        prevIndex === Math.ceil(prioritiesData.length / itemsPerSlide) + 1
          ? 0
          : prevIndex + 1
      );
    }
  };
  return (
    <>
      <Stack px={1} direction={"row"} justifyContent={"space-between"}>
        <AtsText
          text={headerText}
          sx={{
            ...font16,
            fontWeight: 600,
            color: "#231f20",
          }}
        />
        <motion.div
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{
            position: "relative",
            overflow: "hidden",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Stack direction={"row"} gap={1}>
            <IconButton
              sx={{
                cursor: "pointer",
                border: BORDERS.GRAY,
                bgcolor: "white",
                p: "3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                ":hover": {
                  bgcolor: "white",
                },
                ":disabled": {
                  bgcolor: "white",
                },
              }}
              onClick={prevSlide}
              disabled={currentIndex === 0}
            >
              <ChevronLeft
                sx={{
                  ...font17,
                  color: currentIndex !== 0 ? COLORS.PRIMARY : COLORS.TEXT_GRAY,
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                cursor: "pointer",
                border: BORDERS.GRAY,
                bgcolor: "white",
                p: "3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                ":hover": {
                  bgcolor: "white",
                },
                ":disabled": {
                  bgcolor: "white",
                },
              }}
              disabled={currentIndex === prioritiesData.length - 1}
              onClick={nextSlide}
            >
              <ChevronRight
                sx={{
                  ...font17,
                  color:
                    currentIndex === prioritiesData.length - 1
                      ? COLORS.TEXT_GRAY
                      : COLORS.PRIMARY,
                }}
              />
            </IconButton>
          </Stack>
        </motion.div>
      </Stack>
      <div className="carousel">
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerSlide)}%)`,
            minHeight: "130px",
            paddingTop: "10px",
          }}
        >
          {prioritiesData.map((priority, index) => (
            <div
              className="carousel-item"
              key={index}
              style={{ flex: `0 0 ${100 / itemsPerSlide}%` }}
            >
              <motion.div
                whileHover={{ scale: 1.02, translateY: -5 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "10px",
                  cursor: "pointer",
                  height: "100%",
                }}
                onHoverStart={() => setIsHovered(index)}
                onHoverEnd={() => setIsHovered(null)}
              >
                <Box
                  px={1}
                  sx={{
                    // maxWidth: isLargeXl ? "500px" : "300px",
                    height: "100%",
                  }}
                >
                  <AtsCard
                    sx={{
                      height: "86px",
                    }}
                  >
                    <Stack direction={"row"} gap={1}>
                      <Box
                        sx={{
                          mt: 0.3,
                        }}
                      >
                        <priority.icon />
                      </Box>
                      <Stack gap={1}>
                        <Stack>
                          <AtsText
                            className="truncate_two_line"
                            text={priority.label}
                            sx={{
                              ...font14,
                              fontWeight: 600,
                              color: "#4f4f4f",
                            }}
                          />
                        </Stack>
                        <AtsText
                          className="truncate_two_line"
                          text={priority.text}
                          sx={{
                            ...font11,
                            fontWeight: 400,
                            color: "#4f4f4f",
                          }}
                        />
                      </Stack>
                    </Stack>
                  </AtsCard>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 17,
                      right: 9,
                      width: "24px",
                      height: "20px",
                      textAlign: "center",
                      borderBottomRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      bgcolor:
                        isHovered === index
                          ? COLORS.PRIMARY
                          : BG_COLORS.PRIMARY,
                    }}
                  >
                    <IoIosArrowForward
                      style={{
                        fontSize: 12,
                        color:
                          isHovered !== index
                            ? COLORS.PRIMARY
                            : BG_COLORS.PRIMARY,
                      }}
                    />
                  </Box>
                </Box>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TopPrioritiesComponnet;
