import { Box } from "@mui/material";

import { WarningIcon } from "../../../assets/icons/Icons";
import TopPrioritiesComponnet from "../../../components/common/TopPrioritiesComponnet";
import "./CSS/Carousel.css";

export const PRIORITIES = [
  {
    icon: WarningIcon,
    label: "Add Onboarding Instructions",
    text: "Tell new hires how to hit the ground running.",
  },
  {
    icon: WarningIcon,
    label: "Evaluate the Offer Draft for CTO",
    text: "Tell new hires how to hit the ground running.",
  },
  {
    icon: WarningIcon,
    label: "Edit Onboarding Instructions",
    text: "Tell new hires how to hit the ground running.",
  },
  {
    icon: WarningIcon,
    label: "Update Onboarding Instructions",
    text: "Tell new hires how to hit the ground running.",
  },
  {
    icon: WarningIcon,
    label: "Hello Onboarding Instructions",
    text: "Tell new hires how to hit the ground running.",
  },
  {
    icon: WarningIcon,
    label: "New Onboarding Instructions",
    text: "Tell new hires how to hit the ground running.",
  },
];

const TopPriorities = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, #fff, #e8ebf1 32%, #dfe3eb)",
          borderRadius: "10px",
          height: "220px",
          mt: 2.5,
        }}
      >
        <Box className="bg_image">
          <Box
            sx={{
              p: 4,
              maxWidth: "100%",
            }}
          >
            <TopPrioritiesComponnet
              headerText="Your top priorities are..."
              prioritiesData={PRIORITIES}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TopPriorities;
