import { Box, Grid, Skeleton, Stack } from "@mui/material";
import FlagredIcon from "../../../src/assets/icons/flagRed.svg";
import AtsCard from "../../components/AtsCard";
import AtsIcon from "../../components/AtsIcon";
import AtsText from "../../components/AtsText";
import AtsTitleText from "../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../theme";
import { JobListingResponse } from "../../types/jobsTypes";
import { dateFormater, getDateFormatMMMDYYYYY } from "../../utils/dateUtils";
import {
  commaSeparatedAddress,
  isNotNullOrUndefined,
} from "../../utils/stringUtils";
import { getJobStatus } from "../Dashboard/PostedJobs/PostedCard";
import { countryAbbreviations } from "../../utils/countryAbbreviations";
import { JobTypeEnum } from "../../enums/jobsEnums";
interface IJobProps {
  jobData: Partial<JobListingResponse>;
  loading: boolean;
  isBottomHide?: boolean;
  onSelect: () => void;
  selectedJob: string;
}
const Job = ({
  jobData,
  onSelect,
  loading,
  isBottomHide,
  selectedJob,
}: IJobProps) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={onSelect}>
      <AtsCard isActive={jobData.nid === selectedJob}>
        <>
          {jobData.nid === selectedJob && <div className="talkbubble1"></div>}
          <Stack gap={1} className="" py={1} px={"5px"}>
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              {renderStatusComponent(loading, jobData?.job_status)}
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton width={100} />
                </Box>
              ) : (
                jobData?.job_priority === "High" && (
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <img src={FlagredIcon} alt="Nvidia Logo" />
                    <AtsText
                      sx={{
                        fontSize: 11,
                        fontWeight: 700,
                        color: "#da4545",
                      }}
                      text={`HIGH PRIORITY`}
                    />
                  </Stack>
                )
              )}
            </Stack>
            <Stack>
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  <Skeleton />
                </Box>
              ) : (
                <AtsText
                  text={jobData?.title}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#231f20",
                  }}
                />
              )}
            </Stack>
            <Stack
              direction={"row"}
              gap={2}
              pt={1}
              pb={"15px"}
              sx={{ borderBottom: BORDERS.GRAY }}
            >
              {renderDateComponent(
                loading,
                "Starts",
                jobData?.job_start_date,
                11
              )}
              {jobData?.job_type !== JobTypeEnum.DIRECT_HIRE &&
                renderDateComponent(loading, "Ends", jobData?.job_end_date, 11)}
            </Stack>
            <Stack
              pt={1}
              pb={2}
              sx={{
                ...(!isBottomHide && { borderBottom: BORDERS.GRAY }),
              }}
              gap={1}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      {renderJobDataComponent(
                        loading,
                        "location",
                        commaSeparatedAddress({
                          location: jobData.job_address_locality,
                          country: jobData?.job_address_country
                            ? countryAbbreviations[jobData?.job_address_country]
                            : "",
                        })
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      {renderJobDataComponent(
                        loading,
                        "clock",
                        isNotNullOrUndefined(
                          jobData?.field_ats_job_hours_per_week
                        ) && jobData?.field_ats_job_hours_per_week?.length > 0
                          ? parseFloat(
                              jobData?.field_ats_job_hours_per_week.split(
                                " "
                              )[0]
                            ) >= 30
                            ? "Full-time"
                            : "Part-time"
                          : ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      {renderJobDataComponent(
                        loading,
                        "document-text",
                        jobData?.job_type
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* First Grid Item */}
                  <Grid container>
                    <Grid item xs={12}>
                      {renderJobDataComponent(
                        loading,
                        "monitor",
                        jobData?.work_mode
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
            {!isBottomHide && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                pt={1}
              >
                {loading ? (
                  <Box sx={{ width: "50%" }}>
                    <Skeleton />
                  </Box>
                ) : (
                  <AtsTitleText
                    fs={12}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                    text={`${jobData?.submissions_count ?? 0} Submissions`}
                  />
                )}
                {loading ? (
                  <Box sx={{ width: "50%" }}>
                    <Skeleton />
                  </Box>
                ) : (
                  <AtsTitleText
                    fs={12}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                    text={dateFormater(jobData?.created)}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </>
      </AtsCard>
    </div>
  );
};

export default Job;

export const renderDateComponent = (
  loading: boolean,
  dateType: string,
  date: string | null,
  fs: number,
  fullWidth?: boolean
) => {
  return (
    <Stack
      width={fullWidth ? "100%" : "50%"}
      direction={"row"}
      alignItems={"center"}
      gap={0.5}
    >
      <AtsTitleText
        text={`${dateType}:`}
        fs={fs}
        fw={500}
        textColor={COLORS.LIGHT_GRAY}
      />
      <Box>
        {loading ? (
          <Box sx={{ width: "100%" }}>
            <Skeleton width={80} />
          </Box>
        ) : (
          <AtsTitleText
            text={!date ? "" : getDateFormatMMMDYYYYY(date)}
            fs={fs}
            fw={600}
            textColor={COLORS.BLACK}
          />
        )}
      </Box>
    </Stack>
  );
};

export const renderJobDataComponent = (
  loading: boolean,
  icon: string,
  text: string
) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      <Box>
        <AtsIcon icon={`icon-${icon}`} fs={14} />
      </Box>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton width={80} />
        </Box>
      ) : (
        <AtsTitleText
          fs={12}
          fw={500}
          textColor={COLORS.LIGHT_GRAY}
          noTextWrap
          text={text}
        />
      )}
    </Stack>
  );
};

export const renderStatusComponent = (loading: boolean, status: string) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton width={100} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              width: "6px",
              height: "6px",
              bgcolor: getJobStatus(status).color,
              borderRadius: "20px",
            }}
          ></Box>
          <AtsTitleText
            fs={11}
            fw={700}
            textColor={getJobStatus(status).color}
            text={`${getJobStatus(status).text}`}
            isUpperCase
          />
        </>
      )}
    </Stack>
  );
};
