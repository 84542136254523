import { Box, Collapse, Stack } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getCalendarState,
  setEventType,
} from "../../../redux/slices/calendarSlice";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import AtsIcon from "../../../components/AtsIcon";
import { CalendarEventTypes } from "../../../types/calendarTypes";
import AtsCustomButton from "../../../components/AtsCustomButton";

const CalendarFilterSection = () => {
  const dispatch = useAppDispatch();
  const { eventType } = useAppSelector(getCalendarState);
  const [show, setShow] = useState<boolean>(false);
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        pt: "18px",
        pb: "16px",
        px: "19px",
        borderBottom: BORDERS.GRAY,
      }}
    >
      <Stack sx={{ position: "relative" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          sx={{
            cursor: "pointer",
            px: "14px",
            py: "6px",
            border: BORDERS.GRAY,
            borderRadius: "48px",
          }}
          onClick={() => setShow(!show)}
        >
          <Stack direction={"row"} gap={0.5} alignItems={"center"}>
            <AtsTitleText
              text={"Event type:"}
              textColor={COLORS.DARK_GRAY}
              fs={14}
              fw={400}
            />
            <AtsTitleText
              text={eventType}
              textColor={COLORS.DARK_GRAY}
              fs={14}
              fw={600}
            />
          </Stack>
          <Stack>
            <AtsIcon icon="icon-arrow-down-1" height="16px" />
          </Stack>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            left: "0px",
            top: "30px",
            zIndex: 9999,
            width: "150px",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
              border: BORDERS.GRAY,
            }}
            in={show}
          >
            <Box
              sx={{
                p: "14px",
              }}
            >
              <Stack gap={"10px"}>
                {Object.keys(CalendarEventTypes).map((event) => (
                  <AtsCustomButton
                    key={event}
                    onClick={() => {
                      dispatch(setEventType(event));
                      setShow(false);
                    }}
                  >
                    <AtsTitleText text={event} fs={14} />
                  </AtsCustomButton>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Stack>
      <Stack>
        <AtsIcon icon="icon-search-normal-1" />
      </Stack>
    </Stack>
  );
};

export default CalendarFilterSection;
