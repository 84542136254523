import React, { useEffect } from "react";
import { useAppSelector } from "../redux/store";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import { useLocation } from "react-router-dom";

const Chatbot = () => {
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const { pathname } = useLocation();

  useEffect(() => {
    const tidioScript = document.querySelector(
      'script[src="//code.tidio.co/2bq09dnimdjwstxgmfyoxnqfxoz8rolb.js"]'
    );

    if (!tidioScript) {
      const script = document.createElement("script");
      script.src = "//code.tidio.co/2bq09dnimdjwstxgmfyoxnqfxoz8rolb.js";
      script.async = true;
      script.onload = () => {
        window.tidioChatApi.on("ready", () => {
          if (isCandidateDetailsDrawerOpen || pathname === "/login") {
            window.tidioChatApi.hide();
          } else {
            window.tidioChatApi.show();
          }
        });
      };
      document.body.appendChild(script);
    } else {
      if (isCandidateDetailsDrawerOpen || pathname === "/login") {
        window.tidioChatApi.hide();
      } else {
        window.tidioChatApi.show();
      }
    }
  }, [isCandidateDetailsDrawerOpen, pathname]);

  return null;
};

export default Chatbot;
