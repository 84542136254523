import styled from "@emotion/styled";
import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

interface IButtonProps extends ButtonProps {
  text: string;
  fs?: string | number;
  fw?: string | number;
  ml?: string | number;
  loading?: boolean;
  isUppercase?: boolean;
  isInitial?: boolean;
  isOutlineTransparent?: boolean;
  buttonVariant?:
    | "outline"
    | "contained"
    | "whiteContained"
    | "greenOutline"
    | "blackOutline"
    | "greenContained"
    | "primaryContained"
    | "dangerContained"
    | "purpleContained"
    | "blueContained";
  buttonHeight?: string | number;
  buttonWidth?: string | number;
  lineHeight?: string;
}

interface StyledLoaderProps extends CircularProgressProps {
  buttonVariant?: string;
}

const StyledLoader = styled(CircularProgress)<StyledLoaderProps>(
  ({ buttonVariant = "contained", size }) => {
    if (buttonVariant === "contained") {
      return {
        size: size,
        color: "white",
      };
    } else if (buttonVariant === "whiteContained") {
      return {
        size: size,
        color: "var(--back)",
      };
    }
    return {
      size: size,
      color: "#001aa1",
    };
  }
);

const renderCss = (variant: string, isOutlineTransparent?: boolean) => {
  if (variant === "outline") {
    return {
      border: "1px solid var(--primary) !important",
      color: "var(--primary) !important",
      bgcolor: isOutlineTransparent
        ? "transparent !important"
        : "white !important",
    };
  } else if (variant === "whiteContained") {
    return {
      border: "1px solid var(--border-light-gray) !important",
      color: "var(--back) !important",
      bgcolor: "transparent !important",
    };
  } else if (variant === "greenOutline") {
    return {
      border: "1px solid var(--green) !important",
      color: "var(--green) !important",
      bgcolor: "transparent !important",
    };
  } else if (variant === "greenContained") {
    return {
      border: "1px solid var(--bg-light-green) !important",
      color: "var(--dark-green) !important",
      bgcolor: "var(--bg-light-green) !important",
    };
  } else if (variant === "blueContained") {
    return {
      border: "1px solid var(--bg-blue) !important",
      color: "var(--blue) !important",
      bgcolor: "var(--bg-blue) !important",
    };
  } else if (variant === "primaryContained") {
    return {
      border: "1px solid var(--bg-primary) !important",
      color: "var(--primary) !important",
      bgcolor: "var(--bg-primary) !important",
    };
  } else if (variant === "dangerContained") {
    return {
      border: "1px solid var(--bg-red) !important",
      color: "var(--red) !important",
      bgcolor: "var(--bg-red) !important",
    };
  } else if (variant === "purpleContained") {
    return {
      border: "1px solid var(--bg-purple) !important",
      color: "var(--purple) !important",
      bgcolor: "var(--bg-purple) !important",
    };
  } else if (variant === "blackOutline") {
    return {
      border: "1px solid black !important",
      color: "black !important",
      bgcolor: "transparent !important",
    };
  } else {
    return {
      border: "1px solid var(--primary) !important",
      color: "white !important",
      bgcolor: "var(--primary) !important",
    };
  }
};

const AtsRoundedButton = ({
  text,
  loading = false,
  isUppercase = false,
  isInitial,
  isOutlineTransparent,
  buttonVariant = "contained",
  buttonHeight,
  buttonWidth,
  lineHeight,
  fs,
  fw,
  ml,
  ...rest
}: IButtonProps) => {
  return (
    <>
      <Button
        variant="outlined"
        disabled={rest.disabled || loading}
        startIcon={
          loading ? (
            <StyledLoader size={24} buttonVariant={rest.variant} />
          ) : (
            rest.startIcon
          )
        }
        sx={{
          ...renderCss(buttonVariant, isOutlineTransparent),
          px: "13px",
          py: "5px",
          borderRadius: "20px",
          textTransform: isUppercase
            ? "uppercase"
            : isInitial
            ? "initial"
            : "capitalize",
          fontFamily: "Inter",
          height: buttonHeight,
          ...(buttonWidth && { width: buttonWidth }),
          ...(buttonHeight && { height: buttonHeight }),
          ...(lineHeight && { lineHeight: `${lineHeight} !important` }),
          ...(fs && { fontSize: `${fs}px !important` }),
          ...(fw && { fontWeight: `${fw} !important` }),
          ":hover": {
            ...renderCss(buttonVariant, isOutlineTransparent),
          },
          ":disabled": {
            ...renderCss(buttonVariant, isOutlineTransparent),
            opacity: 0.5,
          },
          ".MuiButton-startIcon": {
            ...(!text.length && { mr: 0 }),
          },
          ".MuiButton-endIcon": {
            ml,
          },
        }}
        {...rest}
      >
        {text}
      </Button>
    </>
  );
};

export default AtsRoundedButton;
