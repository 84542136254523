import { Box, Skeleton, Stack } from "@mui/material";
import AtsCard from "../../../../../../../components/AtsCard";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../theme";
import {
  CandidateListResponse,
  ContractorRoleResponse,
} from "../../../../../../../types/projectTypes";

interface IProps {
  roleData: Partial<ContractorRoleResponse>;
  loading: boolean;
  onSelect: () => void;
  selectedRole: Partial<ContractorRoleResponse>;
}

const RolesForProject = ({
  onSelect,
  roleData,
  selectedRole,
  loading,
}: IProps) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={onSelect}>
      <AtsCard
        isActive={roleData.nid === selectedRole?.nid}
        sx={{
          mb: "15px",
        }}
      >
        <Box
          sx={{
            pt: "3px",
            pb: "3px",
          }}
        >
          {roleData.nid === selectedRole?.nid && (
            <div className="talkbubble2"></div>
          )}
          <Stack
            gap={"10px"}
            className=""
            pb={"16px"}
            sx={{
              borderBottom: BORDERS.GRAY,
            }}
            px={"5px"}
          >
            <Stack>
              {loading ? (
                <Skeleton />
              ) : (
                <AtsTitleText fs={14} fw={600} text={roleData?.title ?? ""} />
              )}
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
              <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                <AtsIcon icon="icon-clock" height="16px" />
                {loading ? (
                  <Skeleton width={80} />
                ) : (
                  <AtsTitleText
                    fs={12}
                    fw={400}
                    text={"Full-time"}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                )}
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                <AtsIcon icon="icon-document-text" height="16px" />
                {loading ? (
                  <Skeleton width={80} />
                ) : (
                  <AtsTitleText
                    fs={12}
                    fw={400}
                    text={`R-${roleData?.nid}`}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pt={"12px"}
          >
            {loading ? (
              <Skeleton width={80} />
            ) : (
              <AtsTitleText
                fs={11}
                fw={400}
                text={"Under budget"}
                textColor="#000"
              />
            )}
            {loading ? (
              <Skeleton width={80} />
            ) : (
              <AtsTitleText
                fs={11}
                fw={600}
                text={`${roleData?.contractors_count ?? 0} Contractors`}
                textColor={COLORS.PRIMARY}
              />
            )}
          </Stack>
        </Box>
      </AtsCard>
    </div>
  );
};

export default RolesForProject;
