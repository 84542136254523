export const ATS_ROUTES = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  JOBS: "/jobs",
  SUBMISSIONS: "/submissions/:nid",
  PROJECTS: "/projects",
  PROJECT_DETAILS: "/project-details/:id",
  CALENDAR: "/calendar",
  MESSAGING: "/messaging",
  INSIGHTS: "/insights",
  AVAILABILITY: "/availablility",
  BILLING: "/billing",
  ADMIN_CENTER: "/admin-center",
  HELP: "/help",
  MY_PROFILE: "/my-profile",
  MY_TEAM: "/my-team",
  GETTING_STARTED: "/gettting-started",
  COMMUNITY: "/community",
  POST_OPPORTUNITY: "/post-opportunity",
  USER_MANAGEMENT: "/user-management",
  USER_ROLES: "/user-roles",
  NO_ACCESS: "/no-access",
  CREATE_PROJECT: "/create-project",
};
