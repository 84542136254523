import { Avatar, Box, Stack } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsIcon from "../../../components/AtsIcon";

const CtoInterview = () => {
  return (
    <Box>
      <Stack
        gap={"15px"}
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"10px"}>
          <AtsTitleText text={"Guests"} fs={16} fw={600} textColor="#000" />
          <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
            />
            <AtsTitleText
              text={"daniel@gmail.com"}
              fs={14}
              fw={400}
              textColor={COLORS.BLACK}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
            <Avatar
              sx={{ width: 30, height: 30 }}
              src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
            />
            <AtsTitleText
              text={"trevor.c@gmail.com"}
              fs={14}
              fw={400}
              textColor={COLORS.BLACK}
            />
          </Stack>
        </Stack>
        <Stack gap={"10px"}>
          <AtsTitleText
            text={"Description"}
            fs={16}
            fw={600}
            textColor="#000"
          />
          <AtsTitleText
            text={
              "This round focuses on assessing the ability to set a technology direction that aligns with company's goals. Discuss how candidate has identified emerging technologies and integrated them into previous roles."
            }
            fs={14}
            fw={400}
            textColor={COLORS.BLACK}
          />
        </Stack>
      </Stack>
      <Stack
        pt={"15px"}
        pb={"5px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack gap={"4px"} direction={"row"} alignItems={"center"}>
          <AtsIcon icon="icon-edit-2" color={COLORS.PRIMARY} height="16px" />
          <AtsTitleText
            text={"Edit"}
            fs={12}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
        </Stack>
        <AtsTitleText
          text={"View interview details"}
          fs={12}
          fw={500}
          textColor={COLORS.PRIMARY}
        />
      </Stack>
    </Box>
  );
};

export default CtoInterview;
