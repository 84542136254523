import React, { useEffect, useState } from "react";
import JobCardWrapper from "../components/JobCardWrapper";
import { Stack } from "@mui/material";
import AtsSelect from "../../../components/AtsSelect";
import { JobsService } from "../../../Api/jobsListingServices";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { OptionProps } from "../../../types/commonType";

const ChooseIndustry = () => {
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [options, setOptions] = useState<OptionProps[]>([]);
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const handleSelect = (value: string) => {
    setSelectedSize(value);
    const opportunityObj = {
      ...postOpportunity,
      job_industry: value,
    } as PostOpportunityProps;
    dispatch(setPostOpportunity(opportunityObj));
  };

  const fetchJobIndutries = async () => {
    try {
      const response = await JobsService.getJobIndustries();
      const options = response.map(
        (option) => ({ label: option.name, value: option.name } as OptionProps)
      );
      setOptions(options);
    } catch (error) {}
  };

  useEffect(() => {
    fetchJobIndutries();
  }, []);

  return (
    <>
      <JobCardWrapper header="Choose an industry for your job">
        <Stack px={1}>
          <AtsSelect
            placeholder="Select industry"
            selected={selectedSize}
            onSelect={(select: string) => handleSelect(select)}
            options={options}
          />
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default ChooseIndustry;
