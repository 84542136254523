import { Box, Stack } from "@mui/material";
import moment from "moment";
import {
  Calendar,
  CalendarProps,
  Components,
  EventProps,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  getCalendarState,
  setCalendarView,
  setDate,
} from "../../../redux/slices/calendarSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { COLORS } from "../../../theme";
import { AppointmentStatusCode, EventItem } from "../../../types/calendarTypes";
import AppointmentEvent from "../components/AppointmentEvent";
import "../css/calendar.css";
import { ComponentType } from "react";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);

export const EVENTS: EventItem[] = [
  {
    start: moment("2024-08-25T10:00:00").toDate(),
    end: moment("2024-08-25T10:30:00").toDate(),
    data: {
      appointment: {
        id: 1,
        status: AppointmentStatusCode.Overdue,
        text: "Invoice Overdue",
        length: "(2)",
      },
    },
    resourceId: 1,
    allDay: true,
  },
  {
    start: moment("2024-08-26T10:30:00").toDate(),
    end: moment("2024-08-26T11:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.due,
        text: "Invoice Due",
        length: "",
      },
    },
    resourceId: 2,
    allDay: true,
  },
  {
    start: moment("2024-08-26T10:30:00").toDate(),
    end: moment("2024-08-26T11:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.due,
        text: "Invoice Due",
        length: "",
      },
    },
    resourceId: 3,
    allDay: true,
  },

  {
    start: moment("2024-08-27T10:30:00").toDate(),
    end: moment("2024-08-27T11:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.contractStart,
        text: "Contract Start",
        length: "(2)",
      },
    },
    resourceId: 5,
    allDay: true,
  },
  {
    start: moment("2024-08-30T10:30:00").toDate(),
    end: moment("2024-08-30T11:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.contractEnd,
        text: "Contract End",
        length: "",
      },
    },
    resourceId: 6,
    allDay: true,
  },
  {
    start: moment("2024-08-28T10:30:00").toDate(),
    end: moment("2024-08-28T11:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.interview,
        text: "CTO - Interview",
        length: "",
      },
    },
    resourceId: 7,
    allDay: false,
  },
  {
    start: moment("2024-08-29T09:30:00").toDate(),
    end: moment("2024-08-29T10:15:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: AppointmentStatusCode.interview,
        text: "CTO - Interview",
        length: "",
      },
    },
    resourceId: 8,
    allDay: false,
  },
];
interface CustomComponents extends Components<any, object> {
  event?: ComponentType<EventProps<EventItem>>;
  week?: {
    header?: ComponentType<any>;
  };
}
const CalendarView = (props: Omit<CalendarProps, "localizer">) => {
  const dispatch = useAppDispatch();
  const { date, calendarView } = useAppSelector(getCalendarState);
  const selectedEvent = (e) => {
    console.log("e", e);
  };

  const CustomWeekHeader = ({ label }) => {
    const [date, day] = label.split(" ");
    return (
      <Stack gap={"4px"}>
        <Box
          className="dateButton"
          sx={{
            width: 44,
            height: 44,
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AtsTitleText className="colorText" text={date} fw={600} fs={16} />
        </Box>
        <AtsTitleText
          className="dayText"
          text={day}
          fw={400}
          fs={14}
          textColor={COLORS.LIGHT_GRAY}
        />
      </Stack>
    );
  };

  const components: CustomComponents = {
    event: ({ event }: EventProps<EventItem>) => {
      const data = event?.data;
      if (data?.appointment)
        return (
          <AppointmentEvent
            appointment={event}
            isMonthView={calendarView === Views.MONTH}
            isAllday={event?.allDay}
          />
        );

      return null;
    },
    week: {
      header: CustomWeekHeader, // Use the custom week header component
    },
  };

  return (
    <>
      <Calendar
        {...props}
        localizer={localizer}
        views={[Views.WEEK, Views.MONTH]}
        events={EVENTS}
        defaultDate={"2022-10-10"}
        defaultView={Views.WEEK}
        min={moment("2022-10-10T09:00:00").toDate()}
        max={moment("2022-10-10T18:00:00").toDate()}
        onSelectEvent={selectedEvent}
        // resources={view === Views.DAY ? RESOURCES : undefined}
        // Custom Props

        // Components
        components={components}
        // Toolbar
        toolbar={false}
        date={date}
        view={calendarView}
        onView={() => {
          dispatch(setCalendarView);
        }}
        onNavigate={() => {
          dispatch(setDate);
        }}
        // step={STEP}
        // timeslots={TIME_SLOTS}
      />
    </>
  );
};

export default CalendarView;
