import { Box, Container, Stack } from "@mui/material";
import CalenderDateFilterSection from "./modules/CalenderDateFilterSection";
import { BORDERS } from "../../theme";
import CalendarView from "./modules/CalendarView";
import CalendarFilterSection from "./modules/CalendarFilterSection";

const Calendar = () => {
  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
          pr: "14px !important",
        }}
      >
        <Stack sx={{ pr: "10px" }}>
          <CalenderDateFilterSection />
        </Stack>

        <Box
          sx={{
            height: "calc(100% - 95px)",
            bgcolor: "white",
            border: BORDERS.GRAY,
            borderRadius: "10px",
          }}
        >
          <CalendarFilterSection />
          <CalendarView />
        </Box>
      </Container>
    </div>
  );
};

export default Calendar;
