export enum StatusEnum {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
}
export enum TaskStatusEnum {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}
