import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import AtsInternationalInput from "../../../../../../../../../components/AtsInternationalInput";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import ColleaguesPavilionDetails from "./ColleaguesPavilionDetails";
import LongTermDetails from "./LongTermDetails";
import ShortTermDetails from "./ShortTermDetails";
import PaymentTermsDetails from "./PaymentTermsDetails";
import ThingOfRemember from "./ThingOfRemember";

const JobDetailsAndTerms = () => {
  const { font14, font16, font18 } = FontUtil();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [phone, setPhone] = useState("");

  return (
    <>
      <Stack
        sx={{
          maxWidth: isLargeScreen ? "80%" : isDesktopScreen ? "85%" : "100%",
          height: "100%",
          pr: 2,
        }}
      >
        <AtsInternationalInput
          title="Your phone number"
          subText="Confirm your mobile number to receive updates."
          value={phone}
          onChange={(phone) => setPhone(phone)}
        />
        <Stack
          mt={4}
          gap={1}
          sx={{
            height: "calc(100% - 94px)",
            overflow: "scroll",
            pr: 2,
          }}
        >
          <Accordion
            sx={{
              boxShadow: "none",
              border: BORDERS.GRAY,
              borderRadius: "12px !important",
              "&.Mui-expanded": {
                margin: "0px !important",
              },
              "::before": {
                height: "0px !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                ...font18,
                color: COLORS.BLACK,
                fontWeight: 600,
                px: 4,
              }}
            >
              Job details
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: 4,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              boxShadow: "none",
              border: BORDERS.GRAY,
              borderRadius: "12px !important",
              "&.Mui-expanded": {
                margin: "0px !important",
              },
              "::before": {
                height: "0px !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                ...font18,
                color: COLORS.BLACK,
                fontWeight: 600,
                px: 4,
              }}
            >
              Terms
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: 4,
              }}
            >
              <Stack
                sx={{
                  borderBottom: BORDERS.GRAY,
                  pb: 4,
                }}
              >
                <LongTermDetails />
              </Stack>
              <Stack
                sx={{
                  borderBottom: BORDERS.GRAY,
                  py: 4,
                }}
              >
                <ShortTermDetails />
              </Stack>
              <Stack
                sx={{
                  borderBottom: BORDERS.GRAY,
                  py: 4,
                }}
              >
                <ColleaguesPavilionDetails />
              </Stack>
              <Stack
                sx={{
                  borderBottom: BORDERS.GRAY,
                  py: 4,
                }}
              >
                <PaymentTermsDetails />
              </Stack>
              <Stack
                sx={{
                  py: 4,
                }}
              >
                <ThingOfRemember />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </>
  );
};

export default JobDetailsAndTerms;
