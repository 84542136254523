import { Box, Skeleton } from "@mui/material";
import React from "react";
import { BORDERS } from "../../../../../../../../../theme";

interface IProps {
  theadComponent: () => void;
  rowComponent?: () => React.ReactNode;
  loading: boolean;
  data: any[];
}

const InterviewDataTable = ({
  theadComponent,
  rowComponent,
  loading,
  data,
}: IProps) => {
  return (
    <>
      <Box component={"div"} sx={{ height: "100%" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            //   borderSpacing: '0 0px',
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
              }}
            >
              <>{theadComponent()}</>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12} style={{ background: "white" }}>
                  <Skeleton variant="text" height={100} />
                </td>
              </tr>
            ) : data.length ? (
              <>{rowComponent()}</>
            ) : (
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "white",
                      borderRadius: "12px",
                    }}
                  ></Box>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default InterviewDataTable;
