import { createSlice } from "@reduxjs/toolkit";
import { ProjectDetailsTabsEnum } from "../../enums/projectEnums";
import { RootState } from "../store";
import { CompanyInfoResponse } from "../../types/companyTypes";

interface ProjectDetailsState {
  selectedProjectDetailsTab: ProjectDetailsTabsEnum;
  companyInfoState: Partial<CompanyInfoResponse>;
  selectedActiveJob: string;
  companyStateError: any;
}

const initialState: ProjectDetailsState = {
  selectedProjectDetailsTab: ProjectDetailsTabsEnum.COMPANY,
  companyInfoState: {},
  companyStateError: {},
  selectedActiveJob: "",
};

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    setSelectedProjectDetailsTab: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.selectedProjectDetailsTab = payload;
    },
    setCompanyInfoState: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.companyInfoState = payload;
    },
    setSelectedActiveJob: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.selectedActiveJob = payload;
    },
    setCompanyStateError: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.companyStateError = payload;
    },
  },
});

export const {
  setSelectedProjectDetailsTab,
  setCompanyInfoState,
  setSelectedActiveJob,
  setCompanyStateError,
} = projectDetailsSlice.actions;
export const getProjectDetailsState = (state: RootState) =>
  state.projectDetails;
export default projectDetailsSlice.reducer;
