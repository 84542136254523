import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import StepComponent from "../../../../components/common/StepComponent";
import {
  getProjectsState,
  initailCreateProjectState,
  setActiveProjectStep,
  setActiveProjectStep2,
  setActiveProjectStep3,
  setCreatedProject,
  setCreateProjectState,
  setIsCreatedProject,
} from "../../../../redux/slices/projectsSlice";
import { ProjectService } from "../../../../Api/projectServices";
import axios from "axios";
import { createProjectParams } from "../../../../types/projectTypes";
import { getAuthState } from "../../../../redux/slices/authSlice";
import { toast } from "react-toastify";

const CreateProjectStepper = () => {
  const dispatch = useAppDispatch();
  const {
    activeProjectStep,
    activeProjectStep2,
    activeProjectStep3,
    isEditProjectStep,
    createProjectState,
    requiredDocument,
    chooseProfileImg,
    selectedProjectDetailsConfirm,
  } = useAppSelector(getProjectsState);
  const { userInfo } = useAppSelector(getAuthState);

  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (isEditProjectStep) {
      dispatch(setActiveProjectStep(3));
      dispatch(setActiveProjectStep2(3));
      dispatch(setActiveProjectStep3(3));
    } else {
      if (activeProjectStep < 3) {
        dispatch(setActiveProjectStep(activeProjectStep + 1));
      } else if (activeProjectStep === 3 && activeProjectStep2 < 3) {
        dispatch(setActiveProjectStep2(activeProjectStep2 + 1));
      } else if (activeProjectStep2 === 3 && activeProjectStep3 < 3) {
        dispatch(setActiveProjectStep3(activeProjectStep3 + 1));
      }
    }
  };

  const handleBack = () => {
    if (activeProjectStep3 > 0) {
      dispatch(setActiveProjectStep3(activeProjectStep3 - 1));
    } else if (activeProjectStep2 > 0) {
      dispatch(setActiveProjectStep2(activeProjectStep2 - 1));
    } else if (activeProjectStep > 0) {
      dispatch(setActiveProjectStep(activeProjectStep - 1));
    }
  };

  const handleBinaryUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await ProjectService.uploadDocumentForProject(
            arrayBuffer
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };
  const handleBinaryUploadForLogo = async (file) => {
    if (!file) return;
    const allowedTypes = [
      "image/png",
      "image/gif",
      "image/jpg",
      "image/jpeg",
      "image/webp",
    ];
    if (!allowedTypes.includes(file.type)) {
      console.error(
        "Invalid file type. Only png, gif, jpg, jpeg, and webp are allowed."
      );
      return;
    }
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await ProjectService.uploadLogoForProject(
            arrayBuffer,
            file.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let fidValues = [];
    let fidValuesForProjecImg = [];

    try {
      if (requiredDocument.length) {
        const uploadRequirmentPromises =
          requiredDocument.map(handleBinaryUpload);
        const resultsForDoc: any = await Promise.all(uploadRequirmentPromises);
        fidValues = resultsForDoc.map((item) => item?.fid[0]?.value || null);
      }

      if (chooseProfileImg.length) {
        const uploadChooseProjectImgPromises = chooseProfileImg.map(
          handleBinaryUploadForLogo
        );
        const resultsForProjectImg: any = await Promise.all(
          uploadChooseProjectImgPromises
        );
        fidValuesForProjecImg = resultsForProjectImg.map(
          (item) => item?.fid[0]?.value || null
        );
      }

      const updateProjectState = {
        ...createProjectState,
        company_id: userInfo?.company?.id,
        client_email: userInfo?.email,
        job_id: [10],
        document: {
          ...createProjectState.document,
          attachments_fid: fidValues.filter(Boolean),
        },
        project_image_fid: fidValuesForProjecImg[0] || null,
      } as createProjectParams;

      const response = await ProjectService.createProject(updateProjectState);
      if (response?.data) {
        dispatch(setIsCreatedProject(true));
        dispatch(setCreatedProject(response.data));
        dispatch(setCreateProjectState(initailCreateProjectState));
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
    } finally {
      setLoading(false);
    }
  };

  const progress = (activeProjectStep / 3) * 100;
  const progress2 = (activeProjectStep2 / 3) * 100;
  const progress3 = (activeProjectStep3 / 3) * 100;

  const disabled = () => {
    const conditions = [
      !createProjectState.title.length && activeProjectStep === 1,
      !createProjectState.description.length && activeProjectStep === 1,
      !createProjectState.visibility.length && activeProjectStep === 2,
      !createProjectState.start_date.length && activeProjectStep === 3,
      !createProjectState.end_date.length && activeProjectStep === 3,
      !createProjectState.document.title.length && activeProjectStep2 === 3,
      !createProjectState.document.description.length &&
        activeProjectStep2 === 3,
      !requiredDocument.length && activeProjectStep2 === 3,
      !createProjectState.team_members.length && activeProjectStep3 === 1,
      selectedProjectDetailsConfirm.length !== 3 && activeProjectStep3 === 3,
    ];
    return conditions.some(Boolean);
  };

  return (
    <>
      <StepComponent
        steps={[progress, progress2, progress3]}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        isActiveStep={activeProjectStep === 1}
        isShowSubmitBtn={
          activeProjectStep === 3 &&
          activeProjectStep2 === 3 &&
          activeProjectStep3 === 3
        }
        isDisabled={disabled() || loading}
        isLoading={loading}
      />
    </>
  );
};

export default CreateProjectStepper;

export const getStepCondition = (type) => {
  return type === "Direct hire" ? 6 : 10;
};
