import { Stack } from "@mui/material";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import RadioInput from "../../../PostOpportunity/components/RadioInput";

const visibility = [
  {
    title: "Private",
    value: "private",
    sideTitle: "(Only members addes to Esteemed Talent)",
  },
  { title: "Public", value: "public", sideTitle: "(All members)" },
];

const SetProjectVisibility = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);

  const handleChange = (value: string) => {
    const lowerCaseString = value;
    console.log("🚀 ~ handleChange ~ lowerCaseString:", lowerCaseString);
    const updateProjectData = {
      ...createProjectState,
      visibility:
        createProjectState.visibility === lowerCaseString
          ? ""
          : lowerCaseString,
    };
    dispatch(setCreateProjectState(updateProjectData));
  };

  return (
    <JobCardWrapper header="Set project visibility">
      <Stack pr={1} gap={2}>
        {visibility.map((item, index) => (
          <RadioInput
            key={index}
            checked={createProjectState.visibility === item.value}
            onSelect={() => handleChange(item.value)}
            title={`${item.title}`}
            sideTitle={item.sideTitle}
          />
        ))}
      </Stack>
    </JobCardWrapper>
  );
};

export default SetProjectVisibility;
