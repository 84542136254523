import React, { useEffect, useState } from "react";
import AtsCard from "../../../../../../../components/AtsCard";
import { Box } from "@mui/material";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import { Stack } from "@mui/material";
import { IColumnProps } from "../../../../../../../types/commonType";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { ProjectContractorsStatusEnum } from "../../../../../../../enums/projectEnums";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../../redux/slices/contractorSlice";
import { ProjectService } from "../../../../../../../Api/projectServices";
import { useParams } from "react-router-dom";
import { CandidateListResponse } from "../../../../../../../types/projectTypes";

interface contractorProps {
  uid: string;
  first_name: string;
  last_name: string;
  role: string;
  teamName: string;
  email: string;
}

const invoicesData: contractorProps[] = [
  {
    uid: "1",
    first_name: "Alex",
    last_name: "Novak",
    role: "Sr. Java",
    teamName: "Java Team",
    email: "daniel.anderson@nvidia.com",
  },
  {
    uid: "2",
    first_name: "Aayush",
    last_name: "Mittal",
    role: "Sr. Java",
    teamName: "Java Team",
    email: "daniel.anderson@nvidia.com",
  },
];
const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Team Member",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

interface IProps {
  status: ProjectContractorsStatusEnum;
}

const ContractorsActiveList = ({ status }: IProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { selectedContractor } = useAppSelector(getContractorDetailsState);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [contractors, setContractors] = useState<CandidateListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getContractorDetails = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getContractorsForProject({
        project_id: id,
        job_id: selectedContractor?.nid,
        status,
      });
      setContractors(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Object.keys(selectedContractor).length && getContractorDetails();
  }, [selectedContractor]);

  const customizeRow = (
    column: IColumnProps,
    contractor: CandidateListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    <AccountCircle sx={{ fontSize: 70, color: "#6d6d6d" }} />
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.first_name} ${contractor.last_name}`}
                    />

                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={selectedContractor?.title ?? ""}
                    />
                    <AtsTitleText
                      fs={12}
                      fw={500}
                      text={contractor?.team ?? "Java Team"}
                    />
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton px={0.6}>
              <AtsIcon icon="icon-eye" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = contractors.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
              backgroundColor:
                value.candidate_id === hoveredRow ? "#f2f6ff" : "white",
            }}
            onMouseEnter={() => setHoveredRow(value.candidate_id)}
            onMouseLeave={() => {
              setHoveredRow(null);
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 5px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  return (
    <Stack gap={"13px"}>
      <AtsTitleText
        fs={14}
        fw={600}
        text={`${contractors.length} ${
          status === ProjectContractorsStatusEnum.ACTIVE ? "Active" : "Inactive"
        } contractors`}
      />
      <AtsCard sx={{ mb: "10px" }} isOnlyBorder>
        <Box
          sx={{
            px: "19px",
            pb: "27px",

            // py: 2,
          }}
        >
          <AtsDataTable
            // isTopBorder
            data={contractors}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        </Box>
      </AtsCard>
    </Stack>
  );
};

export default ContractorsActiveList;
