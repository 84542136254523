import React, { useRef, useState } from "react";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { Box, Grid, Stack } from "@mui/material";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../theme";
import AtsTextInput from "../../../../components/AtsTextInput";
import AtsTextArea from "../../../../components/AtsTextArea";
import { UploadIcon } from "../../../PostOpportunity/assets/Icons";
import AtsIcon from "../../../../components/AtsIcon";
import AtsCustomButton from "../../../../components/AtsCustomButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
  setRequiredDocument,
} from "../../../../redux/slices/projectsSlice";
import {
  createProjectParams,
  ProjectDocumentProps,
} from "../../../../types/projectTypes";

const ProjectDocument = () => {
  const dispatch = useAppDispatch();
  const { createProjectState, requiredDocument } =
    useAppSelector(getProjectsState);
  const fileInputRef = useRef(null);

  const handleFiles = (selectedFiles) => {
    const fileArray = Array.from(selectedFiles);
    const updateDocument = [...requiredDocument, ...fileArray];
    dispatch(setRequiredDocument(updateDocument));
  };

  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleChange = (key: keyof ProjectDocumentProps, value: string) => {
    const preDoc = { ...createProjectState.document };
    const updateDoc = {
      ...preDoc,
      [key]: value,
    };
    const updateProjectState = {
      ...createProjectState,
      document: updateDoc,
    } as createProjectParams;
    dispatch(setCreateProjectState(updateProjectState));
  };

  const handleDelete = (index: number) => {
    const preFiles = [...requiredDocument];
    preFiles.splice(index, 1);
    dispatch(setRequiredDocument(preFiles));
  };

  const renderFIleComponent = (file, index) => {
    let fileName = file.name.split(".")[0];
    if (fileName.length > 20) {
      fileName = fileName.substring(0, 20) + "...";
    }

    return (
      <Stack
        direction={"row"}
        sx={{
          px: "20px",
          py: "10px",
          bgcolor: "white",
          borderRadius: "8px",
        }}
      >
        <Stack direction={"row"} alignItems={"start"} gap={"15px"}>
          <img
            src={require("../../../../assets/images/pdfIcon.png")}
            alt="Nvidia Logo"
            style={{ width: 21 }}
          />
          <Stack gap={"10px"}>
            <AtsTitleText
              text={fileName}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
            <Box className="truncate_two_line">
              <AtsTitleText
                text={"Create a user-friendly dashboard with real-time an..."}
                fs={12}
                fw={500}
                textColor={COLORS.LIGHT_GRAY}
              />
            </Box>
          </Stack>
          <Box
            sx={{
              borderLeft: BORDERS.GRAY,
            }}
          >
            <Box
              sx={{
                px: "10px",

                height: "30px",
                display: "flex",
                alignItems: "center",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <AtsCustomButton px={0.5} py={0}>
                <AtsIcon icon="icon-refresh" height="16px" />
              </AtsCustomButton>
            </Box>
            <Box
              sx={{
                px: "10px",

                height: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AtsCustomButton
                px={0.5}
                py={0.5}
                onClick={() => handleDelete(index)}
              >
                <AtsIcon icon="icon-trash" height="16px" />
              </AtsCustomButton>
            </Box>
          </Box>
        </Stack>
      </Stack>
    );
  };
  return (
    <JobCardWrapper
      header="Add requirements or any other documents"
      subText="Upload any documents here"
    >
      <Stack pr={1} gap={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Stack gap={"25px"} pr={"20px"}>
              <Stack gap={1}>
                <AtsTitleText
                  text={"File Name*"}
                  fs={"0.875rem"}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                />
                <AtsTextInput
                  placeholder="Name (required)"
                  value={createProjectState?.document?.title}
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                />
              </Stack>
              <AtsTextArea
                header="File description*"
                loading={false}
                cols={12}
                rows={3}
                className="textArea"
                value={createProjectState?.document?.description}
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
              />
              <Stack gap={1}>
                <AtsTitleText
                  text={"Attachments*"}
                  fs={"0.875rem"}
                  fw={500}
                  textColor={COLORS.LIGHT_GRAY}
                />
                <label
                  htmlFor="chooseVideo"
                  style={{
                    // width: "105px",
                    padding: "20px 0px",
                    borderRadius: "10px",
                    border: "2px dashed lightgray",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginBottom: 0,
                  }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <AtsIcon
                    icon="icon-gallery"
                    height="35px"
                    fs={35}
                    color={COLORS.LIGHT_GRAY}
                    fw={400}
                  />
                  <AtsTitleText
                    text={"Drag & Drop or"}
                    fs={12}
                    fw={500}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                  <AtsTitleText
                    text={"Upload from your device"}
                    fs={12}
                    fw={500}
                    textColor={COLORS.PRIMARY}
                    isLink
                    isUnderline
                  />
                  <input
                    type="file"
                    name=""
                    accept=".pdf, .csv"
                    id="chooseVideo"
                    hidden
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </label>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                bgcolor: BG_COLORS.GRAY,
                borderRadius: "8px",
              }}
            >
              <Stack
                direction={requiredDocument.length ? "column" : "row"}
                gap={"10px"}
                sx={{
                  px: "15px",
                  py: "20px",
                  height: 312,
                  overflow: "auto",
                  ...(!requiredDocument.length && {
                    alignItems: "center",
                    justifyContent: "center",
                  }),
                }}
              >
                {!requiredDocument.length ? (
                  <AtsTitleText text={"No files chosen"} fs={12} />
                ) : (
                  requiredDocument.map((file, index) =>
                    renderFIleComponent(file, index)
                  )
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </JobCardWrapper>
  );
};

export default ProjectDocument;
