import { Stack } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";

const PaymentTermsDetails = () => {
  const { font14, font16 } = FontUtil();
  const paymentTermContent1 = `Please note that our payment terms are subject to change without notice. This means that the conditions under which payments are made and received, including but not limited to billing processes, payment schedules, and fees, may be modified at our discretion.`;
  const paymentTermContent2 = `Changes to the payment terms will be updated on our platform, and it is the responsibility of users to regularly review these terms to stay informed of any modifications. Continued use of our services after any changes have been made constitutes acceptance of the new payment terms.`;
  const paymentTermContent3 = `For any questions or concerns regarding changes to payment terms, please contact your Account Manager.`;
  return (
    <>
      <Stack gap={1.5}>
        <AtsText
          text={"Payment terms"}
          sx={{
            ...font16,
            color: COLORS.BLACK,
            fontWeight: 600,
          }}
        />
        <Stack>
          <AtsText
            text={paymentTermContent1}
            sx={{
              ...font14,
              color: COLORS.BLACK,
              fontWeight: 400,
            }}
          />
          <AtsText
            text={paymentTermContent2}
            sx={{
              ...font14,
              color: COLORS.BLACK,
              fontWeight: 400,
            }}
          />
          <AtsText
            text={paymentTermContent3}
            sx={{
              ...font14,
              color: COLORS.BLACK,
              fontWeight: 400,
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PaymentTermsDetails;
