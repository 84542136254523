import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Skeleton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { JobsService } from "../../../../Api/jobsListingServices";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../theme";
import {
  CompanyDetailsResponse,
  JobDetailsResponse,
} from "../../../../types/jobsTypes";
import { useAppSelector } from "../../../../redux/store";
import { getAuthState } from "../../../../redux/slices/authSlice";

const initialCompanyDetails = {
  nid: "-",
  title: "-",
  description: "-",
  founded: "-",
  website: "",
  size: "-",
  logo: require("../../../../assets/images/nvidiaLogo.png"),
};

const AboutCompany = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { userInfo } = useAppSelector(getAuthState);
  const [companyDetails, setCompanyDetails] = useState<
    Partial<CompanyDetailsResponse>
  >({});

  const getComplanyDetailsApi = async (nid: string) => {
    setLoading(true);
    try {
      const response = await JobsService.getCompanyDetails({ nid });
      const companyResponse = response.results;
      if (companyResponse.length) {
        setCompanyDetails(companyResponse[0]);
      } else {
        setCompanyDetails({});
      }
    } catch (error) {
      setCompanyDetails({});
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userInfo) {
      getComplanyDetailsApi(userInfo.company.id);
    }
  }, [userInfo]);
  console.log("companyDetails", companyDetails);
  return (
    <>
      {Object.keys(companyDetails).length > 0 && (
        <Box component={"div"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pt={4}
            sx={{ borderTop: BORDERS.GRAY }}
          >
            <AtsTitleText fs={18} fw={600} text="About Company" />
          </Stack>
          <List>
            <ListItem alignItems="center" sx={{ pb: 0, pt: 0, pl: 0, pr: 0 }}>
              {companyDetails?.logo && (
                <ListItemIcon sx={{ minWidth: "20px", mt: 0 }}>
                  {loading ? (
                    <Skeleton width={72} height={50} />
                  ) : (
                    <img
                      src={companyDetails.logo}
                      alt="Nvidia Logo"
                      style={{ width: 72, height: 50, marginRight: 10 }}
                    />
                  )}
                </ListItemIcon>
              )}
              <Stack className="innerHTML">
                {renderLoadingComponent(
                  loading,
                  <AtsTitleText
                    text={companyDetails.title}
                    fs={12}
                    fw={400}
                    textColor={COLORS.BLACK}
                  />
                )}
              </Stack>
            </ListItem>
          </List>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Company Overview"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              loading,
              <AtsTitleText
                isCapitalize
                text={companyDetails?.description}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Website"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              loading,
              <AtsTitleText
                text={companyDetails.website}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 0, pt: 2 }}>
            <AtsTitleText
              text="Company Size"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              loading,
              <AtsTitleText
                text={companyDetails.size}
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#231f20",
                }}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
          <Stack gap={1} sx={{ pb: 4, pt: 2 }}>
            <AtsTitleText
              text="Founded In"
              fs={16}
              fw={600}
              textColor={COLORS.BLACK}
            />
            {renderLoadingComponent(
              loading,
              <AtsTitleText
                text={companyDetails.founded}
                fs={12}
                fw={400}
                textColor={COLORS.BLACK}
              />
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default AboutCompany;

const renderLoadingComponent = (loading: boolean, component: JSX.Element) => {
  if (loading) {
    return <Skeleton height={80} sx={{ fontSize: "1rem" }} />;
  } else {
    return component;
  }
};
