import { Stack } from "@mui/material";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CheckboxInput from "../components/CheckboxInput";
import ImportantThings from "../components/ImportantThings";
import JobCardWrapper from "../components/JobCardWrapper";

const officeTime = [
  {
    key: "1-2 days per week",
    value: "1-2",
  },
  {
    key: "2-4 days per week",
    value: "2-4",
  },
  {
    key: "A couple of times a month",
    value: "month",
  },
  {
    key: "A couple of times a year",
    value: "year",
  },
];

const OfficeTime = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);

  const handleChange = (value: string) => {
    const preDays = [...postOpportunity.job_often];
    if (preDays.includes(value)) {
      const updatedDays = preDays.filter((day) => day !== value);
      const opportunityObj = {
        ...postOpportunity,
        job_often: updatedDays,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    } else {
      const updatedDays = [...preDays, value];
      const opportunityObj = {
        ...postOpportunity,
        job_often: updatedDays,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(opportunityObj));
    }
  };
  return (
    <>
      <JobCardWrapper header="How often do you need someone in the office?">
        <Stack pr={1} gap={2}>
          {officeTime.map((item, index) => (
            <CheckboxInput
              key={index}
              title={item.key}
              checked={postOpportunity.job_often.includes(item.key)}
              onSelect={() => handleChange(item.key)}
            />
          ))}
          <Stack mt={3}>
            <ImportantThings
              header="Important things to remember"
              subText="Be sure to comply with all local workplace regulations"
            />
          </Stack>
        </Stack>
      </JobCardWrapper>
    </>
  );
};

export default OfficeTime;
