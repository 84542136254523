import { ChevronRight } from "@mui/icons-material";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { BellIcon } from "../../../assets/icons/Icons";
import AtsTitleText from "../../../components/AtsTitleText";
import { useClickOutSide } from "../../../utils/common";
import "../../css/header.css";
import NotificationCard from "./components/NotificationCard";
import { COLORS } from "../../../theme";

const Notifications = () => {
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <>
      <Box ref={domNode} sx={{ position: "relative" }}>
        <Stack
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setShow(!show)}
        >
          <BellIcon />
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            right: "0px",
            top: "50px",
            zIndex: 9999,
            width: "330px",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "6px",
            }}
            in={show}
          >
            <Stack pb={1}>
              <Stack
                sx={{ p: 2 }}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <AtsTitleText
                  text="Notifications"
                  fs={16}
                  fw={600}
                  textColor={COLORS.BLACK}
                />
                <Stack
                  gap={0.2}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  <AtsTitleText
                    text="Show all"
                    fs={12}
                    fw={400}
                    textColor={COLORS.PRIMARY}
                  />
                  <ChevronRight
                    sx={{ fontSize: 14, color: "var(--primary)" }}
                  />
                </Stack>
              </Stack>
              <NotificationCard
                isUnread
                headerText="Unread"
                buttonText="Mark All as read"
                timeText="3 month ago"
                messageRender={
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: COLORS.BLACK,
                    }}
                  >
                    Connection request from{" "}
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Christopher McGrath
                    </span>
                  </Typography>
                }
              />
              <NotificationCard
                headerText="Recent"
                timeText="Jun 1, 2024"
                messageRender={
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: COLORS.BLACK,
                    }}
                  >
                    Your Job for{" "}
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      Chief Technology Officer
                    </span>{" "}
                    is successfully created
                  </Typography>
                }
              />
            </Stack>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default Notifications;
