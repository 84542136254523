import { Box, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import { IColumnProps } from "../../../../../../../types/commonType";
import { BORDERS } from "../../../../../../../theme";

interface InvoiceProps {
  uid: string;
  date: string;
  description: string;
  quantity: string;
  rate: string;
  amount: string;
}

const invoicesData: InvoiceProps[] = [
  {
    uid: "1",
    date: "07/01 - 07/15",
    description: "Alex Novak",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
  {
    uid: "2",
    date: "07/01 - 07/15",
    description: "Alex Novak",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
  {
    uid: "3",
    date: "07/01 - 07/15",
    description: "Jamie Valentin",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
  {
    uid: "4",
    date: "07/01 - 07/15",
    description: "Matt Obert",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
  {
    uid: "5",
    date: "07/01 - 07/15",
    description: "Teody Seguin",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
  {
    uid: "6",
    date: "07/01 - 07/15",
    description: "Mike O Hara",
    quantity: "42.50 hrs",
    rate: "$30.00",
    amount: "$3245.00",
  },
];
const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "date",
    header: "Date",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "description",
    header: "Description",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "quantity",
    header: "Quantity",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "rate",
    header: "Rate",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "amount",
    header: "Amount",
    width: "auto",
    minWidth: "100px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const InvoiceItemsList = () => {
  const customizeRow = (column: IColumnProps, invoice: InvoiceProps) => {
    switch (column.key) {
      case "description":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={600}
                  text={invoice[column.key]}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "date":
      case "quantity":
      case "rate":
      case "amount":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText fs={14} fw={400} text={invoice[column.key]} />
            </Stack>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = invoicesData.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "12px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsTitleText
              text={column.header}
              fs={12}
              fw={600}
              textColor="#4f4f4f"
              isCursor
            />
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Box mb={"23px"}>
        <AtsTitleText text={"Invoice items"} fs={16} fw={600} />
      </Box>
      <Box>
        <AtsDataTable
          isTopBorder
          data={invoicesData}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={false}
        />
      </Box>
    </>
  );
};

export default InvoiceItemsList;
