import { Circle, MoreVert } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsIcon from "../../../../components/AtsIcon";
import { COLORS } from "../../../../theme";
import { ProjectListProps } from "../../../../types/projectTypes";

const images = [
  "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
];

interface IProps {
  projectsDetails: Partial<ProjectListProps>;
  loading: boolean;
}

const ProjectDetailsHeaderComponent = ({
  projectsDetails,
  loading,
}: IProps) => {
  return (
    <>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Box
            sx={{
              width: 50,
              height: 50,
            }}
          >
            {loading ? (
              <Skeleton variant="circular" width={50} height={50} />
            ) : projectsDetails?.project_image ? (
              <img
                width={"100%"}
                src={projectsDetails?.project_image}
                alt=""
                style={{
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                width={"100%"}
                src={require("../../../../assets/images/projectImg.png")}
                alt=""
              />
            )}
          </Box>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack gap={"9px"}>
              {loading ? (
                <Skeleton height={20} width={200} />
              ) : (
                <AtsTitleText
                  text={projectsDetails?.title ?? ""}
                  fs={20}
                  fw={600}
                  textColor="#000"
                />
              )}
              <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                  <AtsIcon
                    icon="icon-document-text"
                    fs={16}
                    height="18px"
                    color={COLORS.LIGHT_GRAY}
                  />
                  {loading ? (
                    <Skeleton height={20} width={100} />
                  ) : (
                    <AtsTitleText
                      text={`P-${projectsDetails?.nid ?? ""}`}
                      fs={14}
                      fw={400}
                      textColor={COLORS.LIGHT_GRAY}
                    />
                  )}
                </Stack>
                <Circle sx={{ fontSize: 5, color: "lightgray" }} />
                <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
                  <AtsIcon
                    icon="icon-location"
                    fs={16}
                    height="18px"
                    color={COLORS.LIGHT_GRAY}
                  />
                  {loading ? (
                    <Skeleton height={20} width={100} />
                  ) : (
                    <AtsTitleText
                      text={
                        projectsDetails?.location
                          ? projectsDetails?.location?.country
                          : ""
                      }
                      fs={14}
                      fw={400}
                      textColor={COLORS.LIGHT_GRAY}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
              <Stack>
                <div className="avatar-group">
                  {images.map((item, index) => (
                    <div key={item} className="avatar">
                      <img src={item} alt={`Image1${index}`} />
                    </div>
                  ))}

                  <div className="avatar">
                    <div className="plan">
                      <AtsTitleText
                        text={"+8"}
                        textColor="#000"
                        fs={12}
                        fw={600}
                      />
                    </div>
                  </div>
                </div>
              </Stack>
              <IconButton color="inherit">
                <MoreVert fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectDetailsHeaderComponent;
