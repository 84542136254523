import _ from "lodash";

export const commaSeparatedAddress = (addressObj) => {
  const commaSeparatedValues = Object.values(addressObj)
    .filter((value) => value !== "" && value !== null)
    .join(",");

  return commaSeparatedValues;
};

export const toLowerCase = (value: string) => {
  if (!value) return "";
  return _.startCase(_.toLower(value));
};

export const concatenateStrings = (firstValue: string, lastValue: string) => {
  return `${firstValue ?? ""}  ${lastValue ?? ""}`;
};

export const htmlString = (value: string): string => {
  if (!value) return "";
  const tempElement = document.createElement("div");
  tempElement.innerHTML = value;
  const textOnly = tempElement.textContent || tempElement.innerText;
  return textOnly;
};

export const isNotNullOrUndefined = <T>(
  value: T | null | undefined
): value is T => {
  return value !== null && value !== undefined;
};

export const formateOneDigitNum = (num) => {
  if (!num) return 0;
  return num.toFixed(1);
};
