import { createTheme } from "@mui/material";

export let appTheme = createTheme({
  breakpoints: {
    // values: {
    //   xs: 0,
    //   sm: 600,
    //   md: 1280,
    //   lg: 1920,
    //   xl: 2500,
    // },
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
      xxl: 2500,
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

export const COLORS = {
  PRIMARY: "var(--primary)",
  ORANGE: "var(--orange)",
  GREEN: "var(--green)",
  RED_LIGHT: "var(--red-light)",
  RED: "var(--red)",
  PURPLE: "var(--purple)",
  BLUE: "var(--blue)",
  BLACK: "var(--black)",
  GRAY: "var(--border-gray)",
  TEXT_GRAY: "var(--text-gray)",
  LIGHT_GRAY: "var(--light-gray)",
  DARK_GRAY: "var(--dark-gray)",
};
export const BG_COLORS = {
  PRIMARY: "var(--bg-primary)",
  ORANGE: "var(--bg-orange)",
  GREEN: "var(--bg-green)",
  GRAY: "var(--bg-gray)",
  RED: "var(--bg-red)",
  PURPLE: "var(--bg-purple)",
  BLUE: "var(--bg-blue)",
};
export const BORDERS = {
  PRIMARY: "1px solid var(--primary)",
  GRAY: "1px solid var(--border-gray)",
  LIGHT_GRAY: "1px solid #edeff4",
  DARK: "1px solid var(--border-dark-gray)",
  DARK_GRAY: "1px solid var(--text-gray)",
  ORANGE: "1px solid var(--orange)",
  GREEN: "1px solid var(--green)",
};
export const BORDERS_2X = {
  GRAY: "2px solid var(--border-gray)",
  DARK: "2px solid var(--border-dark-gray)",
  DARK_GRAY: "2px solid var(--text-gray)",
  PRIMARY: "2px solid var(--primary)",
  ORANGE: "2px solid var(--orange)",
  GREEN: "2px solid var(--green)",
};
export const BOX_SHADOW = {
  GRAY: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
};
