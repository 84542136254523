import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Collapse, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { BORDERS, COLORS } from "../theme";
import { OptionProps } from "../types/commonType";
import { useClickOutSide } from "../utils/common";
import AtsTitleText from "./AtsTitleText";

interface ISelectProps {
  placeholder?: string;
  options: OptionProps[];
  selected: string | OptionProps | any;
  onSelect: (selected: string) => void;
}

const AtsSelect = ({
  selected,
  placeholder,
  options,
  onSelect,
}: ISelectProps) => {
  const [show, setShow] = useState<boolean>(false);

  const getLabelForSelectedValue = (selected: string | OptionProps) => {
    const selectedOption =
      options?.filter((item) => item.value === selected) ?? [];
    if (selectedOption.length > 0) {
      return selectedOption[0].label as string;
    }
    return selected as string;
  };

  const onChange = (selectValue: string | OptionProps) => {
    if (typeof selectValue === "string") {
      onSelect(selectValue);
    } else {
      onSelect(selectValue.value);
    }
  };
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <div ref={domNode} style={{ position: "relative" }}>
      <Stack
        direction={"row"}
        sx={{
          height: "44px",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          borderRadius: "8px",
          border: BORDERS.GRAY,
        }}
        onClick={() => setShow(!show)}
      >
        <AtsTitleText
          text={selected ? getLabelForSelectedValue(selected) : placeholder}
          fs={14}
          fw={600}
          color={"#000"}
        />
        <KeyboardArrowDown
          onClick={() => setShow(!show)}
          sx={{
            color: "rgba(0, 0, 0, 0.54)",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
            fontSize: "25px !important",
            ...(show ? { rotate: "180deg" } : ""),
          }}
        />
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bgcolor: "white",
          zIndex: 9999,
          width: "100%",
        }}
      >
        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: "6px",
          }}
          in={show}
        >
          <Box sx={{ py: 1 }}>
            <div
              //   onScroll={onScroll}
              style={{
                maxHeight: 200,
                padding: "10px",
                overflow: "auto",
              }}
              className=""
            >
              {options.length ? (
                options.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={typeof option === "string" ? option : option.value}
                    onClick={() => {
                      onChange(option);
                      setShow(false);
                    }}
                    sx={{
                      ...(typeof option === "string"
                        ? option === selected
                          ? { bgcolor: "#e2ebff" }
                          : { bgcolor: "white" }
                        : option.value === selected
                        ? { bgcolor: "#e2ebff" }
                        : { bgcolor: "white" }),
                    }}
                  >
                    <Stack
                      direction={"row"}
                      gap={2}
                      color={COLORS.BLACK}
                      alignItems="center"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 400,
                      }}
                    >
                      {typeof option === "string" ? option : option.label}
                    </Stack>
                  </MenuItem>
                ))
              ) : (
                <AtsTitleText
                  text={"No Data"}
                  fs={"0.875rem"}
                  fw={400}
                  color={COLORS.BLACK}
                />
              )}
            </div>
          </Box>
        </Collapse>
      </Box>
    </div>
  );
};

export default AtsSelect;
