import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";
import ProjectDetailsTabs from "./modules/ProjectDetailsTabs";
import { useAppSelector } from "../../../../../redux/store";
import { getProjectDetailsState } from "../../../../../redux/slices/projectDetailsSlice";
import CompanyDetails from "./modules/CompanyDetails";
import { ProjectDetailsTabsEnum } from "../../../../../enums/projectEnums";

const DetailsOfProject = () => {
  const { selectedProjectDetailsTab } = useAppSelector(getProjectDetailsState);
  const companyDetailsMemo = useMemo(() => <CompanyDetails />, []);

  const renderDetailsComponents = () => {
    switch (selectedProjectDetailsTab) {
      case ProjectDetailsTabsEnum.COMPANY:
        return companyDetailsMemo;
    }
  };
  return (
    <>
      <Stack direction={"row"} gap={"63px"} height={"98%"}>
        <ProjectDetailsTabs />
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {renderDetailsComponents()}
        </Box>
      </Stack>
    </>
  );
};

export default DetailsOfProject;
