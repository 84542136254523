import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";

const TotalOfInvoice = () => {
  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={"end"}
        flexWrap={"wrap"}
      >
        <Stack gap={"30px"} maxWidth={474}>
          {renderText(
            "Message",
            "Thanks for your business. Please find attached the invoice for our services."
          )}
          {renderText("Notes", "Thank you for your continued partnership.")}
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: COLORS.BLACK,
            }}
          >
            If you have any questions or need further assistance, please do not
            hesitate to contact us at{" "}
            <a
              target="_blank"
              href="https://www.finance@esteemed.io"
              style={{
                color: COLORS.PRIMARY,
                textDecoration: "none",
              }}
              rel="noreferrer"
            >
              finance@esteemed.io.
            </a>
          </Typography>
        </Stack>
        <Box
          sx={{
            // py: { xs: 2, md: "28px" },
            // px: { xs: 2, md: "24px" },
            minWidth: { xs: "100%", md: 335 },
          }}
        >
          <Stack
            gap={"20px"}
            sx={{
              pb: "25px",
              borderBottom: "1px solid",
            }}
          >
            {renderTotalText("Subtotal", "$ 3,735.00", COLORS.BLACK)}
            {renderTotalText("Sales tax", "$ 10.00", COLORS.BLACK)}
          </Stack>
          <Stack
            py={"23px"}
            sx={{
              borderBottom: "1px solid",
            }}
          >
            {renderTotalText("Total", "$ 3,745.00", "#000", true)}
          </Stack>
          <Box mt={"32px"}>
            <AtsRoundedButton text="Proceed to Payment" buttonWidth={"100%"} />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default TotalOfInvoice;

const renderText = (title: string, text: string) => {
  return (
    <Stack gap={"12px"}>
      <AtsTitleText text={title} fs={14} fw={600} />
      <AtsTitleText text={text} fs={14} fw={400} />
    </Stack>
  );
};

const renderTotalText = (
  title: string,
  text: string,
  color: string,
  isBold?: boolean
) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <AtsTitleText
        text={title}
        fs={isBold ? 16 : 14}
        fw={isBold ? 600 : 400}
      />
      <Stack textAlign={"end"}>
        <AtsTitleText
          text={text}
          fs={isBold ? 16 : 14}
          fw={isBold ? 600 : 400}
        />
      </Stack>
    </Stack>
  );
};
