import { Box, Stack } from "@mui/material";
import { useState } from "react";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";
import SkillsComponent from "./SkillsComponent";

interface SkillsCommentsProps {
  education: string;
  priorWorkExp: string;
  technicalExp: string;
  verbalCommunication: string;
  candidateEnthusiasm: string;
  companyKnowledge: string;
}

const Scorecard = () => {
  const [comments, setComments] = useState<SkillsCommentsProps>({
    education: "",
    priorWorkExp: "",
    technicalExp: "",
    verbalCommunication: "",
    candidateEnthusiasm: "",
    companyKnowledge: "",
  });
  return (
    <Box
      sx={{
        py: 3,
        height: 400,
        width: "100%",
        pr: 2,
        overflow: "scroll",
      }}
    >
      <Stack gap={2}>
        <AtsText
          text={"How does the scorecard work ?"}
          sx={{ fontSize: 12, fontWeight: 600, color: COLORS.PRIMARY }}
        />
        <Stack gap={2}>
          <Stack>
            <SkillsComponent
              title="Education Background"
              des="Does the candidate have the appropriate educational qualifications or training for this position?"
              rating={5}
              comments={comments.education}
              onChange={(comment) =>
                setComments({ ...comments, education: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Prior Work Experience"
              des="Has the candidate acquired similar skills or qualifications through past work experiences?"
              rating={4}
              comments={comments.priorWorkExp}
              onChange={(comment) =>
                setComments({ ...comments, priorWorkExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Technical Qualifications/Experience"
              des="Does the candidate have the technical skills necessary for this position?"
              rating={3}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Verbal Communication"
              des="Does the candidate have the appropriate educational qualifications or training for this position?"
              rating={2}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>

          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Candidate Enthusiasm"
              des="Did the candidate show enthusiasm for the position and the company?"
              rating={1}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Knowledge of Company"
              des="Did the candidate show evidence of having researched the company prior to the interview?"
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="​Teambuilding/Interpersonal Skills"
              des="Did the candidate demonstrate, through his or her answers, teambuilding / interpersonal skills?"
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="​Initiative"
              des="Did the candidate demonstrate, through his or her answers, a high degree of initiative?"
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="​Time Management"
              des="Did the candidate demonstrate, through his or her answers, good time management skills?"
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="Customer Service"
              des="Did the candidate demonstrate, through his or her answers, a high level of customer service skills/abilities?"
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <SkillsComponent
              title="​Overall Impression and Recommendation"
              des="Final comments and recommendations for proceeding with the candidate."
              rating={5}
              comments={comments.technicalExp}
              onChange={(comment) =>
                setComments({ ...comments, technicalExp: comment })
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Scorecard;
