import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsSlider from "../../../../components/AtsSlider";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import { createProjectParams } from "../../../../types/projectTypes";
import { formattedAmount } from "../../../../utils/common";
import { BORDERS, COLORS } from "../../../../theme";

const ProjectBudget = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);
  const [price, setPrice] = useState<number[]>([1, 1000000]);

  const handleSetPrice = (newValue: number[]) => {
    const lowValue = newValue[0];
    const highValue = newValue[1];
    dispatch(
      setCreateProjectState({
        ...createProjectState,
        budget_high: highValue,
        budget_low: lowValue,
      } as createProjectParams)
    );
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setPrice(newValue as number[]);
    handleSetPrice(newValue as number[]);
  };

  useEffect(() => {
    setPrice([createProjectState.budget_low, createProjectState.budget_high]);
  }, [createProjectState.budget_low, createProjectState.budget_high]);

  return (
    <JobCardWrapper
      header="Now set your budget"
      subText="Set a rate of budget for your project"
    >
      <>
        <Stack
          gap={1.5}
          sx={{
            px: 5,
            py: 6.2,
            bgcolor: "#f5f5f5",
            borderRadius: "10px",
          }}
        >
          <AtsSlider
            max={5000000}
            min={1}
            onChange={handlePriceChange}
            value={price}
          />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
            sx={{
              ml: "55px",
            }}
          >
            {renderBudgetRateComponent(createProjectState.budget_low, 85)}
            <AtsTitleText text={"to"} fs={"0.625rem"} />
            {renderBudgetRateComponent(
              createProjectState.budget_high,

              140
            )}
          </Stack>
          <Box sx={{ textAlign: "center" }}>
            <AtsTitleText fs={14} fw={400} text={`per year`} />
          </Box>
        </Stack>
      </>
    </JobCardWrapper>
  );
};

export default ProjectBudget;

const renderBudgetRateComponent = (value: number, width: number) => {
  return (
    <Stack gap={0.5}>
      <Stack
        direction={"row"}
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "6px",
          minHeight: 47,
          minWidth: width,
          width: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            width: "27px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          <AtsTitleText
            text={"$"}
            fs={18}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            fontSize: 18,
            color: COLORS.BLACK,
            fontWeight: 600,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <AtsTitleText
            text={formattedAmount(value)}
            fs={18}
            fw={600}
            textColor={COLORS.BLACK}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
