import { Circle } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import AtsCard from "../../../../../../components/AtsCard";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import { getJobStatus } from "../../../../../Dashboard/PostedJobs/PostedCard";

const RecentlyPostedJobs = () => {
  return (
    <>
      <Box pb={"29px"}>
        <AtsTitleText text={"Recently posted jobs"} fs={18} fw={600} />
      </Box>
      <Box pb={"14px"}>
        <AtsTitleText
          text={"4 new jobs"}
          fs={16}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Box>
      <Stack gap={"20px"}>
        {renderCard()}
        {renderCard()}
      </Stack>
    </>
  );
};

export default RecentlyPostedJobs;

const renderText = (text: string) => {
  return (
    <AtsTitleText
      text={text}
      fs={11}
      fw={400}
      textColor={COLORS.LIGHT_GRAY}
      noTextWrap
    />
  );
};

const renderCard = () => {
  return (
    <AtsCard>
      <Stack px={1}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20px"}
          sx={{ minHeight: 17 }}
          pt={1}
          pb={"19px"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Box
              sx={{
                width: "6px",
                height: "6px",
                bgcolor: getJobStatus("Open").color,
                borderRadius: "20px",
              }}
            ></Box>
            <AtsTitleText
              fs={11}
              fw={700}
              textColor={getJobStatus("Open").color}
              text={getJobStatus("Open").text}
            />
          </Stack>
        </Stack>
        <Stack
          sx={
            {
              // height: "44px",
            }
          }
        >
          <AtsTitleText
            className="truncate_two_line"
            fs={16}
            fw={600}
            text={"Staff HR Representative - Remote"}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"8px"}
          rowGap={"3px"}
          flexWrap={"wrap"}
          pt={"9px"}
          pb={"10px"}
          sx={{
            borderBottom: BORDERS.LIGHT_GRAY,
          }}
        >
          {renderText("South Africa")}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText("Full-time")}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText("R-20719")}
          <Circle sx={{ fontSize: 3, color: "lightgray" }} />
          {renderText("Posted on June 24, 2024")}
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pt={"16px"}
          pb={"6px"}
        >
          <AtsTitleText
            fs={12}
            fw={500}
            textColor={COLORS.DARK_GRAY}
            text={`0 Submissions`}
          />
          <AtsTitleText
            fs={12}
            fw={600}
            textColor={COLORS.PRIMARY}
            isCursor
            text={`View Job`}
          />
        </Stack>
      </Stack>
    </AtsCard>
  );
};
