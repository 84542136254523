import axios from "axios";
import { PaginatedResponse } from "../types/commonType";
import { CompanyInfoParams, CompanyInfoResponse } from "../types/companyTypes";
import { RolesForProjectParams } from "../types/projectTypes";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  COMPANY_INFO: "company-info?_format=json",
};

const COMPANY_INFO_URL = Endpoint.BASE + Endpoint.COMPANY_INFO;

export class CompanyServices {
  static async getCompanyInfo(
    params: Partial<CompanyInfoParams>
  ): Promise<CompanyInfoResponse> {
    const response = await axios.get(COMPANY_INFO_URL, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}
