import { createSlice } from "@reduxjs/toolkit";
import {
  CandidateListResponse,
  ContractorRoleResponse,
} from "../../types/projectTypes";
import { RootState } from "../store";

interface ContractorSliceState {
  selectedContractor: Partial<ContractorRoleResponse>;
  contractors: CandidateListResponse[];
}

const initialState: ContractorSliceState = {
  selectedContractor: {},
  contractors: [],
};

const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setSelectedContractor: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.selectedContractor = payload;
    },
    setContractors: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractors = payload;
    },
  },
});

export const { setSelectedContractor, setContractors } =
  contractorSlice.actions;
export const getContractorDetailsState = (state: RootState) => state.contractor;
export default contractorSlice.reducer;
