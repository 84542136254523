import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../../../../theme";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import { EditIcon } from "../../../../../../assets/icons/Icons";
import AtsTextInput from "../../../../../../components/AtsTextInput";
import DirectSupervisor from "../components/DirectSupervisor";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { getProjectsState } from "../../../../../../redux/slices/projectsSlice";
import { CompanyServices } from "../../../../../../Api/companyServices";
import {
  getProjectDetailsState,
  setCompanyInfoState,
  setCompanyStateError,
} from "../../../../../../redux/slices/projectDetailsSlice";
import { commaSeparatedAddress } from "../../../../../../utils/stringUtils";
import DirectBillingContact from "../components/DirectBillingContact";
import {
  CompanyAddressProps,
  CompanyInfoResponse,
} from "../../../../../../types/companyTypes";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { JobAddressProps } from "../../../../../../redux/slices/postOpportunitySlice";
import { companyDetailsValidation } from "../../../../../../utils/validationUtils";

export interface CompanyDetailsProps {
  isEdit: boolean;
  loading: boolean;
}

const CompanyDetails = () => {
  const dispatch = useAppDispatch();
  const { projectsDetails } = useAppSelector(getProjectsState);
  const { companyInfoState, companyStateError } = useAppSelector(
    getProjectDetailsState
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const handleChange = (key: string, value: string) => {
    const preCompanyInfo = {
      ...companyInfoState,
      [key]: value,
    } as CompanyInfoResponse;
    dispatch(setCompanyInfoState(preCompanyInfo));
  };

  const getCompanyDetails = async (company_id: string) => {
    setLoading(true);
    try {
      const response = await CompanyServices.getCompanyInfo({ company_id });
      dispatch(setCompanyInfoState(response));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    projectsDetails?.company_id &&
      getCompanyDetails(projectsDetails?.company_id);
  }, [projectsDetails]);

  useEffect(() => {
    if (companyInfoState?.address) {
      const address = companyInfoState.address as CompanyAddressProps;
      if (
        address?.locality ||
        address?.address_line1 ||
        address?.country ||
        address?.administrative_area ||
        address?.country_code ||
        address?.postal_code
      ) {
        setSearchQuery(
          `${address.address_line1} ${address.locality} ${address.administrative_area} ${address.country} ${address.country_code}`
        );
      }
    }
  }, [companyInfoState.address]);

  const updateCompanyDetails = async () => {
    const res = companyDetailsValidation(companyInfoState, searchQuery);
    dispatch(setCompanyStateError(res.error));
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSearchQuery(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";
      let country_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        } else if (types.includes("country_code")) {
          country_code = component.long_name;
        }
      }
      const address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
        country_code,
      } as CompanyAddressProps;

      const updateCompanyInfo = {
        ...companyInfoState,
        address,
      } as CompanyInfoResponse;
      dispatch(setCompanyInfoState(updateCompanyInfo));
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const renderTextInputComponent = (
    isEdit: boolean,
    title: string,
    value: string,
    key: string,
    handleChange: (key: string, value: string) => void,
    loading?: boolean
  ) => {
    return (
      <Stack gap={"5px"}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: COLORS.BLACK,
          }}
        >
          {title}
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        </Typography>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {!isEdit ? (
            loading ? (
              <Skeleton height={40} />
            ) : (
              <Box
                sx={{
                  bgcolor: BG_COLORS.GRAY,
                  borderRadius: "8px",
                  height: 41,
                  display: "flex",
                  alignItems: "center",
                  pl: "21px",
                }}
              >
                <AtsTitleText
                  text={value}
                  fs={14}
                  fw={400}
                  textColor="#1c1c1c"
                />
              </Box>
            )
          ) : title === "Address" ? (
            <Autocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceSelect}
            >
              <AtsTextInput
                height="41px"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Autocomplete>
          ) : (
            <AtsTextInput
              height="41px"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
            />
          )}
          <Stack
            textAlign={"start"}
            sx={{
              position: "absolute",
            }}
          >
            <AtsTitleText
              text={companyStateError[key]}
              fs={12}
              fw={500}
              textColor={COLORS.RED_LIGHT}
            />
          </Stack>
        </Box>
      </Stack>
    );
  };
  if (!isLoaded) {
    return <Skeleton />;
  }
  return (
    <>
      <Stack gap={"10px"} pb={"40px"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText text={"Company"} fs={20} fw={600} />
          {!isEdit && (
            <AtsRoundedButton
              text="Edit"
              startIcon={<EditIcon />}
              buttonVariant="whiteContained"
              buttonHeight={25}
              buttonWidth={65}
              onClick={() => setIsEdit(!isEdit)}
            />
          )}
        </Stack>
        <AtsTitleText
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          text={
            "Complete all fields related to company details, position specifics, and contact information."
          }
        />
      </Stack>
      <Box
        sx={{
          height: "calc(100% - 130px)",
          overflow: "auto",
        }}
      >
        <Stack gap={"20px"} pb={"50px"}>
          <AtsTitleText
            text={"Company"}
            fs={16}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
          <Grid container columnSpacing={"44px"} rowSpacing={"30px"}>
            <Grid item xs={12} sm={6} xxl={4}>
              {renderTextInputComponent(
                isEdit,
                "Company Name",
                companyInfoState?.title ?? "",
                "title",
                handleChange,
                loading
              )}
            </Grid>
            <Grid item xs={12}>
              {renderTextInputComponent(
                isEdit,
                "Address",
                companyInfoState?.address
                  ? commaSeparatedAddress(companyInfoState?.address)
                  : "",
                "address",
                handleChange,
                loading
              )}
            </Grid>
          </Grid>
          <Stack gap={"30px"}></Stack>
        </Stack>
        <Stack gap={"20px"} pb={"50px"}>
          <AtsTitleText
            text={"Direct Supervisor"}
            fs={16}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
          <DirectSupervisor isEdit={isEdit} loading={loading} />
        </Stack>
        <Stack gap={"20px"} pb={"50px"}>
          <AtsTitleText
            text={"Direct Billing Contact"}
            fs={16}
            fw={500}
            textColor={COLORS.PRIMARY}
          />
          <DirectBillingContact isEdit={isEdit} loading={loading} />
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pt={1}
      >
        <AtsRoundedButton
          text="Cancel"
          buttonVariant="outline"
          buttonHeight={34}
          buttonWidth={79}
          onClick={() => {
            setIsEdit(false);
            dispatch(setCompanyStateError({}));
          }}
        />
        {isEdit && (
          <AtsRoundedButton
            text="Save Details"
            buttonVariant="outline"
            buttonHeight={34}
            buttonWidth={128}
            onClick={updateCompanyDetails}
          />
        )}
        <AtsRoundedButton text="Next" buttonHeight={34} buttonWidth={113} />
      </Stack>
    </>
  );
};

export default CompanyDetails;
