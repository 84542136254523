import React from "react";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import JobsSwitch from "../../../Dashboard/PostedJobs/JobsSwitch";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import { Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setProjectFilter,
} from "../../../../redux/slices/projectsSlice";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../../router/routes";
import { ProjectListStatusEnum } from "../../../../enums/projectEnums";

const PROJECTS = [
  {
    title: "Active",
    type: ProjectListStatusEnum.IN_PROGRESS,
  },
  {
    title: "Inactive",
    type: ProjectListStatusEnum.COMPLETED,
  },
  {
    title: "Archived",
    type: ProjectListStatusEnum.ARCHIVED,
  },
];

const ProjectTopContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectFilter } = useAppSelector(getProjectsState);
  return (
    <>
      <AtsTitleText
        text="All Projects"
        fs={24}
        fw={600}
        textColor={COLORS.BLACK}
      />
      <Stack
        sx={{ pt: "22px", pb: "19px" }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <JobsSwitch
          width="50px"
          jobs={PROJECTS}
          active={projectFilter.status}
          onChange={(value) => {
            const preProjectState = { ...projectFilter, status: value };
            dispatch(setProjectFilter(preProjectState));
          }}
        />
        <AtsRoundedButton
          buttonVariant="outline"
          text="Create new"
          isInitial
          buttonHeight={32}
          startIcon={<Add />}
          onClick={() => navigate(ATS_ROUTES.CREATE_PROJECT)}
        />
      </Stack>
    </>
  );
};

export default ProjectTopContainer;
