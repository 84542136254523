import { Button, Collapse, Stack } from "@mui/material";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { formatDateRange } from "../../../utils/dateUtils";
import AtsDateRangePicker from "../../../components/AtsDateRangePicker";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getProjectsState,
  setProjectFilter,
} from "../../../redux/slices/projectsSlice";
import { ProjectFilterState } from "../../../types/projectTypes";
import moment from "moment";

const dateButtons = [
  {
    title: "Last 7 days",
    dateFrom: 6,
  },
  {
    title: "Last 15 days",
    dateFrom: 14,
  },
];

const FilterDateRange = () => {
  const disaptch = useAppDispatch();
  const { projectFilter } = useAppSelector(getProjectsState);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>(
    formatDateRange(new Date().toISOString(), new Date().toISOString())
  );
  const [selected, setSelected] = useState<string>("");
  const renderButtonComponent = (text: string, dateFrom: number) => {
    return (
      <Button
        variant="outlined"
        size="small"
        sx={{
          textTransform: "initial",
          height: 27,
          borderRadius: "6px",
          bgcolor: selected === text ? COLORS.PRIMARY : "transparent",
          border: selected === text ? BORDERS.PRIMARY : BORDERS.GRAY,
          color: selected === text ? "white" : COLORS.BLACK,
          fontSize: 12,
          fontWeight: 400,
          ":hover": {
            border: selected === text ? BORDERS.PRIMARY : BORDERS.GRAY,
            color: selected === text ? "white" : COLORS.BLACK,
            bgcolor: selected === text ? COLORS.PRIMARY : "transparent",
          },
        }}
        onClick={() => {
          if (selected === text) {
            setSelected("");
            const dateSelection = {
              ...projectFilter,
              start_date: "",
              end_date: "",
            } as ProjectFilterState;
            disaptch(setProjectFilter(dateSelection));
          } else {
            setSelected(text);
            handleLastSevenDay(dateFrom);
          }
        }}
      >
        {text}
      </Button>
    );
  };

  // const handleSelectDateButton =

  const handleLastSevenDay = (dateFrom: number) => {
    const today = new Date();
    const seventhDay = new Date();
    seventhDay.setDate(today.getDate() + dateFrom);
    const formattedTodayDate = moment(today).format("YYYY-MM-DD");
    const formattedSevenDate = moment(seventhDay).format("YYYY-MM-DD");
    const dateSelection = {
      ...projectFilter,
      start_date: formattedTodayDate,
      end_date: formattedSevenDate,
    } as ProjectFilterState;
    disaptch(setProjectFilter(dateSelection));
  };
  const handleEvent = (event, picker) => {
    setSelected("");
    const datePicker = formatDateRange(picker.startDate, picker.endDate);
    setSelectedDate(datePicker);
    const dateSelection = {
      ...projectFilter,
      start_date: moment(picker.startDate).format("YYYY-MM-DD"),
      end_date: moment(picker.endDate).format("YYYY-MM-DD"),
    } as ProjectFilterState;
    disaptch(setProjectFilter(dateSelection));
  };
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0} onClick={() => setIsCollapse(!isCollapse)}>
          <AtsIcon
            icon={isCollapse ? "icon-arrow-up-2" : "icon-arrow-down-1"}
            height="16px"
            fw={600}
          />
        </AtsCustomButton>
        <AtsTitleText
          text={"Date range"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Collapse in={isCollapse}>
        <Stack gap={"10px"}>
          <Stack direction={"row"} gap={"10px"}>
            {dateButtons.map((button) =>
              renderButtonComponent(button.title, button.dateFrom)
            )}
          </Stack>
          <Stack>
            <AtsDateRangePicker
              handleEvent={handleEvent}
              handleClear={() => {
                const dateSelection = {
                  ...projectFilter,
                  start_date: "",
                  end_date: "",
                } as ProjectFilterState;
                disaptch(setProjectFilter(dateSelection));
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "initial",
                  height: 39,
                  borderRadius: "6px",
                  border: BORDERS.GRAY,
                  color: COLORS.BLACK,
                  fontSize: 14,
                  fontWeight: 400,
                  justifyContent: "flex-start",
                  ":hover": {
                    border: BORDERS.GRAY,
                    color: COLORS.BLACK,
                  },
                }}
                startIcon={
                  <AtsIcon
                    icon="icon-calendar-1"
                    color={COLORS.PRIMARY}
                    height="30px"
                  />
                }
              >
                {selectedDate}
              </Button>
            </AtsDateRangePicker>
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default FilterDateRange;
