import { Stack } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";

const LongTermDetails = () => {
  const { font14, font16 } = FontUtil();

  const longTermContent1 = `Your service provider will submit an invoice through the Colleagues platform weekly on Monday for the previous week's time. Your first invoice is due upon receipt. If you have not paid the outstanding invoice by seven days, work will stop on your project and will only resume once you have paid. Subsequent invoices are automatically charged to your payment method on file at the time of invoicing.`;
  const longTermContent2 = `Invoices are based on the time billed, and access to detailed time entries is provided. If you encounter any issues, you have one week from the invoice date to file a dispute.`;
  const longTermContent3 = `Any late payments will incur a fee of 2.5% of the total invoice amount.`;
  return (
    <>
      <Stack gap={1.5}>
        <AtsText
          text={"Long-term engagements"}
          sx={{
            ...font16,
            color: COLORS.BLACK,
            fontWeight: 600,
          }}
        />
        <AtsText
          text={longTermContent1}
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
        <AtsText
          text={longTermContent2}
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
        <AtsText
          text={longTermContent3}
          sx={{
            ...font14,
            color: COLORS.BLACK,
            fontWeight: 400,
          }}
        />
      </Stack>
    </>
  );
};

export default LongTermDetails;
