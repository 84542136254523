/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoginMutation } from "../Api/authService";
import AtsRoundedButton from "../components/AtsRoundedButton";
import AtsTitleText from "../components/AtsTitleText";
import AtsTextInput from "../components/AtsTextInput";
import { setIsAuth, setUserInfo } from "../redux/slices/authSlice";
import { useAppDispatch } from "../redux/store";
import AtsIcon from "../components/AtsIcon";
import { COLORS } from "../theme";
import { UserManagementServices } from "../Api/userManagementServices";

export interface IAuthProps {
  mail: string;
  pass: string;
}

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<IAuthProps>({
    mail: "",
    pass: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const getUserInfoFromToken = async (access_token: string) => {
    try {
      const response = await UserManagementServices.getUserInfo(access_token);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleLogin = async () => {
    try {
      const response = await login(formData).unwrap();
      const userInfo = await getUserInfoFromToken(response.access_token);

      if (userInfo) {
        console.log("userInfo", userInfo);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        dispatch(setUserInfo(userInfo));
      }
      localStorage.setItem("accessToken", response.access_token);
      dispatch(setIsAuth(true));
      toast.success("Login successful!");
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const isDisabled = () => {
    if (isValidEmail) {
      if (!formData.mail.length) {
        return true;
      } else if (!formData.pass.length) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!formData.mail.length) {
      setIsValidEmail(false);
    }
  }, []);
  return (
    <>
      <Container maxWidth="xs">
        <Box sx={{ width: "100%" }}>
          <Stack
            sx={{
              width: "100%",
              textAlign: "center",
              mt: 2,
              pb: 2,
            }}
          >
            <Box
              sx={{
                height: "50px",
                mb: 2,
              }}
            >
              <img
                height={"100%"}
                src={require("../assets/images/logo.png")}
                alt="wsteemed"
              />
            </Box>
            <AtsTitleText text="Hello!" fs={36} fw={300} />
            <AtsTitleText text="Welcome back!" fs={36} fw={300} />
          </Stack>
          <Box
            my={3}
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <Box mb={2}>
              <AtsTextInput
                height="45px"
                header="Email"
                type="email"
                error={formData.mail.length > 0 ? !isValidEmail : undefined}
                value={formData.mail}
                placeholder="Enter Email"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setFormData({ ...formData, mail: inputValue });

                  setIsValidEmail(validateEmail(inputValue));
                }}
              />
            </Box>
            <Box mb={3}>
              <AtsTextInput
                height="45px"
                header="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <AtsIcon
                          icon="icon-eye"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      ) : (
                        <AtsIcon
                          icon="icon-eye-slash"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                value={formData.pass}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setFormData({ ...formData, pass: inputValue });
                }}
              />
            </Box>
            <Stack spacing={2}>
              <AtsRoundedButton
                type="submit"
                text="Login"
                isUppercase={false}
                buttonHeight={40}
                disabled={isLoading || isDisabled()}
                loading={isLoading}
              />
            </Stack>
            <Box sx={{ width: "fit-content", mt: 3 }}>
              <AtsTitleText
                text={"Forgot Password ?"}
                fs={12}
                fw={400}
                isCursor
                isUnderline
              />
            </Box>
          </Box>
          <Stack
            sx={{
              width: "100%",
              textAlign: "center",
              mt: "100px",
              pb: 2,
            }}
          >
            <AtsTitleText text="Powered by Esteemed Inc." fs={10} fw={400} />
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default LoginForm;
