import { Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS } from "../../../../../../../../../../theme";
import OfferDetailsInfo from "./OfferDetailsInfo";
import StatusDetails from "./StatusDetails";

const OfferDetails = () => {
  return (
    <>
      <Stack direction={"row"} sx={{ width: "100%", minHeight: "440px" }}>
        <Box
          sx={{
            width: "67%",
            px: 2,
            py: 3,
          }}
        >
          <OfferDetailsInfo />
        </Box>
        <Box
          sx={{
            width: "33%",
            px: 2,
            py: 3,
            bgcolor: BG_COLORS.GRAY,
          }}
        >
          <StatusDetails />
        </Box>
      </Stack>
    </>
  );
};

export default OfferDetails;
