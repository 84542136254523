import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { InterviewCalenderIcon } from "../../../../../../../../assets/icons/Icons";
import AtsText from "../../../../../../../../components/AtsText";
import { getInterviewState } from "../../../../../../../../redux/slices/interviewSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import InterviewDataTable from "./modules/InterviewDataTable";
import InterviewInfo from "./modules/InterviewInfo";
import { InterviewListHeader } from "./modules/InterviewListHeader";
import NoInterviewDetails from "./modules/NoInterviewDetails";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "interviewId",
    header: "Interview Id",
    width: "200px",
    minWidth: "200px",
  },
  {
    key: "date",
    header: "Scheduled On",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "time",
    header: "Time",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "duration",
    header: "Duration",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "auto",
    minWidth: "60px",
  },
];

const InterviewList = () => {
  const { isInterviewDetails } = useAppSelector(getInterviewState);
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);

  const interviews = [
    {
      id: 1,
      interviewId: "INT-12308910",
      date: "June 19, 2024",
      time: "10:00 AM",
      status: "Scheduled",
      duration: "30 min",
    },
    {
      id: 2,
      interviewId: "INT-12308911",
      date: "June 19, 2024",
      time: "10:00 AM",
      status: "Completed",
      duration: "30 min",
    },
    {
      id: 3,
      interviewId: "INT-12308912",
      date: "June 19, 2024",
      time: "10:00 AM",
      status: "Completed",
      duration: "30 min",
    },
  ];

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const cellStyles = {
    padding: "0px 10px",
  };

  const customizeRow = (column: IColumnProps, list) => {
    switch (column.key) {
      case "interviewId":
        return (
          <td
            className={`${
              !collapsedRows.includes(list.id)
                ? "customTD"
                : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <InterviewCalenderIcon />
              <AtsText
                sx={{ fontSize: 14, fontWeight: 600 }}
                text={list.interviewId}
              />
            </Stack>
          </td>
        );
      case "date":
      case "time":
      case "duration":
        return (
          <td
            className={`${
              !collapsedRows.includes(list.id)
                ? "customTD"
                : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsText
                sx={{ fontSize: "0.75rem", fontWeight: 400 }}
                text={list[column.key]}
              />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td
            className={`${
              !collapsedRows.includes(list.id)
                ? "customTD"
                : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsText
                sx={{
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  color:
                    list.status === "Scheduled" ? COLORS.ORANGE : COLORS.GREEN,
                }}
                text={list.status}
              />
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = interviews.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              backgroundColor: collapsedRows.includes(value.id)
                ? "#e2ebff"
                : "white",
              borderBottom: collapsedRows.includes(value.id)
                ? "none"
                : BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            <td
              className={`${
                !collapsedRows.includes(value.id)
                  ? "customTDLast"
                  : "borderTopRightRadius"
              }`}
              style={cellStyles}
            >
              <IconButton onClick={() => toggleCollapse(value.id)}>
                <KeyboardArrowDown
                  sx={{
                    transition: "all 500ms ease-in-out",
                    ...(collapsedRows.includes(value.id) && {
                      rotate: "180deg",
                    }),
                  }}
                />
              </IconButton>
            </td>
          </tr>
          <tr>
            <td
              colSpan={12}
              style={{
                marginBottom: 0,
                padding: 0,
              }}
            >
              {collapsedRows.includes(value.id) && <InterviewInfo />}
            </td>
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <InterviewListHeader headerText={column.header} />
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box
      sx={{
        my: 3,
        width: "100%",
      }}
    >
      {isInterviewDetails ? (
        <InterviewDataTable
          data={interviews}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={false}
        />
      ) : (
        <NoInterviewDetails />
      )}
    </Box>
  );
};

export default InterviewList;
