import { Button, Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import AtsDateRangePicker from "../../../../components/AtsDateRangePicker";
import AtsIcon from "../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../theme";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import { createProjectParams } from "../../../../types/projectTypes";

const SelectStartEndDate = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);
  const [selectedDate, setSelectedDate] = useState<string>(
    "Start Date - End Date"
  );

  const handleEvent = (event, picker) => {
    const startDate = moment(picker.startDate).format("YYYY-MM-DD");
    const endDate = moment(picker.endDate).format("YYYY-MM-DD");
    setSelectedDate(`${startDate} - ${endDate}`);
    const updateProjectState = {
      ...createProjectState,
      start_date: startDate,
      end_date: endDate,
    } as createProjectParams;
    dispatch(setCreateProjectState(updateProjectState));
  };
  const handleClear = () => {
    setSelectedDate(`Start Date - End Date`);
  };

  useEffect(() => {
    if (
      createProjectState.start_date.length &&
      createProjectState.end_date.length
    ) {
      setSelectedDate(
        `${createProjectState.start_date} - ${createProjectState.end_date}`
      );
    }
  }, [createProjectState.start_date, createProjectState.end_date]);

  return (
    <JobCardWrapper header="Select start date and end date">
      <Stack pr={1} gap={2}>
        <AtsDateRangePicker handleEvent={handleEvent} handleClear={handleClear}>
          <Button
            variant="outlined"
            sx={{
              textTransform: "initial",
              height: 42,
              borderRadius: "6px",
              border: BORDERS.GRAY,
              color: COLORS.BLACK,
              fontSize: 14,
              fontWeight: 400,
              justifyContent: "flex-start",
              ":hover": {
                border: BORDERS.GRAY,
                color: COLORS.BLACK,
              },
            }}
            startIcon={
              <AtsIcon
                icon="icon-calendar-1"
                color={COLORS.BLACK}
                height="30px"
              />
            }
          >
            {selectedDate}
          </Button>
        </AtsDateRangePicker>
      </Stack>
    </JobCardWrapper>
  );
};

export default SelectStartEndDate;
