import axios from "axios";
import { PaginatedResponse } from "../types/commonType";
import {
  CreateUpdateInterviewParams,
  InterviewIdTitleResponse,
  InterviewParams,
  InterviewResult,
} from "../types/interviewTypes";

const Endpoint = {
  BASE: "ats/api/v1/",
  APPOINTMENT: "appointments/get-by-logged-in-user",
  CREATE_INTERVIEW: "interview/create?_format=json",
  UPDATE_INTERVIEW: "interview/update?_format=json",
  INTERVIEW_STATUS: "interview-status?_format=json",
  INTERVIEW_TYPES: "interview-types?_format=json",
};

const APPOINTMENT_URL = Endpoint.BASE + Endpoint.APPOINTMENT;
const CREATE_INTERVIEW_URL = Endpoint.BASE + Endpoint.CREATE_INTERVIEW;
const UPDATE_INTERVIEW_URL = Endpoint.BASE + Endpoint.UPDATE_INTERVIEW;
const INTERVIEW_STATUS_URL = Endpoint.BASE + Endpoint.INTERVIEW_STATUS;
const INTERVIEW_TYPES_URL = Endpoint.BASE + Endpoint.INTERVIEW_TYPES;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

export class InterviewsService {
  static async getCurrentUserInterviews(
    request: Partial<InterviewParams>
  ): Promise<PaginatedResponse<InterviewResult>> {
    const endpoint = APPOINTMENT_URL;
    const response = await axios.get(endpoint, { params: request, headers });
    return response.data;
  }

  static async getInterviewsStatus(): Promise<
    PaginatedResponse<InterviewIdTitleResponse>
  > {
    const endpoint = INTERVIEW_STATUS_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async getInterviewsTypes(): Promise<
    PaginatedResponse<InterviewIdTitleResponse>
  > {
    const endpoint = INTERVIEW_TYPES_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async createInterview(
    request: Partial<CreateUpdateInterviewParams>
  ): Promise<PaginatedResponse<InterviewResult>> {
    const endpoint = CREATE_INTERVIEW_URL;
    const response = await axios.post(endpoint, request, { headers });
    return response.data;
  }

  static async updateInterview(
    request: Partial<CreateUpdateInterviewParams>
  ): Promise<PaginatedResponse<InterviewResult>> {
    const endpoint = UPDATE_INTERVIEW_URL;
    const response = await axios.post(endpoint, request, { headers });
    return response.data;
  }
}
