import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface IProps extends ButtonProps {
  value?: string;
  isSelected?: boolean;
}

const InterviewButton = ({ value, isSelected = false, ...rest }: IProps) => {
  return (
    <>
      <Button
        {...rest}
        className={`${isSelected ? "timeSlotBtnActive" : "timeSlotBtn"}`}
        variant="contained"
        color="primary"
        sx={{
          marginRight: 2,
          color: "white",
        }}
      >
        {value ?? ""}
      </Button>
    </>
  );
};

export default InterviewButton;
