import { ChevronRight } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InterviewsService } from "../../../Api/interviewServices";
import { GoogleMeetIcon } from "../../../assets/icons/Icons";
import AtsCard from "../../../components/AtsCard";
import AtsLabel from "../../../components/AtsLabel";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { InterviewResult } from "../../../types/interviewTypes";
import FontUtil from "../../../utils/FontUtil";
import InterviewDatePicker from "./InterviewDatePicker";

const INTERVIEWS = [
  {
    time: "03:00 pm EST",
    type: "Interview",
    name: "Jenny Smith",
    subject: "Technical Screening",
    platform: <GoogleMeetIcon />,
    interviewer: "Senior Support Technician",
  },
  {
    time: "04:30 pm EST",
    type: "test assessment",
    name: "Jenny Smith",
    subject: "Technical Screening",
    platform: <GoogleMeetIcon />,
    interviewer: "Senior Support Technician",
  },
];

const Interviews = () => {
  const { font14 } = FontUtil();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date()));
  const [interviews, setInterviews] = useState<InterviewResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getInterviewList = async () => {
    setIsLoading(true);
    try {
      const response = await InterviewsService.getCurrentUserInterviews({
        field_ats_interview_date_value: selectedDate.format("YYYY-MM-DD"),
        pages: 1,
        items_per_page: "2",
      });
      setInterviews(response.results);
      setIsLoading(false);
    } catch (error) {
      toast(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInterviewList();
  }, [selectedDate]);

  return (
    <Stack>
      <Stack
        direction={"row"}
        sx={{ flexWrap: "wrap" }}
        pb={2}
        justifyContent={"space-between"}
      >
        <AtsTitleText
          text="Your Interviews"
          fs={24}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <InterviewDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        {interviews?.length
          ? interviews.map((interview, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <motion.div
                  whileHover={{ scale: 1.02, translateY: -5 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "10px",
                    cursor: "pointer",
                    boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <AtsCard>
                    <Stack gap={1}>
                      <AtsTitleText
                        loading={isLoading}
                        text={interview.field_ats_interview_date}
                        fs={15}
                        fw={600}
                        textColor={COLORS.BLACK}
                      />
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <AtsLabel
                          loading={isLoading}
                          text={interview.field_ats_interview_app_type}
                          variant="primary"
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AtsTitleText
                          loading={isLoading}
                          fs={14}
                          fw={400}
                          textColor={COLORS.LIGHT_GRAY}
                          text={`${interview.field_ats_interview_candidate} - ${interview.appointment_title} | `}
                        />
                        <a href={interview.field_appoint_meet_link}>
                          <GoogleMeetIcon />
                        </a>
                      </Stack>
                      <AtsTitleText
                        loading={isLoading}
                        fs={14}
                        fw={600}
                        textColor={COLORS.LIGHT_GRAY}
                        text={`${interview.field_ats_interview_owner}`}
                      />
                    </Stack>
                  </AtsCard>
                </motion.div>
              </Grid>
            ))
          : Array.from(new Array(2)).map((_, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <motion.div
                  whileHover={{ scale: 1.02, translateY: -5 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "10px",
                    cursor: "pointer",
                    boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <AtsCard sx={{ height: 120 }}>
                    <Stack gap={1}>
                      <AtsTitleText
                        loading={isLoading}
                        text={"No Interview Schedule"}
                        fs={16}
                        fw={600}
                        textColor={COLORS.BLACK}
                      />

                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AtsTitleText
                          loading={isLoading}
                          fs={14}
                          fw={400}
                          textColor={COLORS.LIGHT_GRAY}
                          text={``}
                        />
                        {""}
                      </Stack>
                      <AtsTitleText
                        loading={isLoading}
                        fs={14}
                        fw={600}
                        textColor={COLORS.LIGHT_GRAY}
                        text={``}
                      />
                    </Stack>
                  </AtsCard>
                </motion.div>
              </Grid>
            ))}
        {}
      </Grid>
      <Stack
        mt={2}
        pb={"12px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ cursor: "pointer", borderBottom: BORDERS.GRAY }}
      >
        <AtsTitleText fs={12} textColor={COLORS.PRIMARY} text="Show All" />
        <ChevronRight sx={{ ...font14, color: "var(--primary)" }} />
      </Stack>
    </Stack>
  );
};

export default Interviews;
