import { Box, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { BORDERS, COLORS } from "../../../../theme";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsIcon from "../../../../components/AtsIcon";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setChooseProfileImg,
} from "../../../../redux/slices/projectsSlice";

import defaultImage from "../../../../assets/images/projectImg.png";

const ChooseProjectImage = () => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(50);
  const [imageURL, setImageURL] = useState(defaultImage);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const dispatch = useAppDispatch();
  const { createProjectState, chooseProfileImg } =
    useAppSelector(getProjectsState);
  const handleFiles = (selectedFiles) => {
    const fileArray = Array.from(selectedFiles) as File[];
    const updateDocument = [...fileArray];
    dispatch(setChooseProfileImg(updateDocument));
    if (fileArray.length > 0) {
      const file = fileArray[0];
      setImageURL(URL.createObjectURL(file));
    }
  };

  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSelectImage = async (img: string) => {
    try {
      const response = await fetch(img);
      const blob = await response.blob();
      console.log("🚀 ~ handleSelectImage ~ blob:", blob);
      const arrayBuffer = await blob.arrayBuffer();
      console.log("🚀 ~ handleSelectImage ~ blob:", arrayBuffer);
    } catch (error) {}
  };
  return (
    <JobCardWrapper header="Choose a project image">
      <Stack direction={"row"} gap={"25px"} alignItems={"flex-start"}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 117,
              height: 117,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: `${value}px`,
                height: `${value}px`,
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={imageURL}
                alt=""
              />
            </Box>
          </Box>
          <div className="slider-container">
            <input
              type="range"
              min={50}
              max={117}
              step={1}
              value={value}
              onChange={handleChange}
              className="sliderInput thumb-image"
            />
          </div>
        </Stack>
        <Stack gap={"16px"}>
          <AtsTitleText
            text={
              "Upload a logo for your project here or choose an image from our library"
            }
            fs={14}
            fw={400}
            textColor="#3c3c3c"
          />
          <Stack
            direction={"row"}
            columnGap={"21px"}
            rowGap={"21px"}
            flexWrap={"wrap"}
          >
            <Box
              sx={{
                width: 83.6,
                height: 83.6,
                borderRadius: "10px",
                // border: BORDERS.GRAY,
              }}
            >
              <label
                htmlFor="chooseVideo"
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  border: "1px dashed #3366cc",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginBottom: 0,
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <AtsIcon
                  icon="icon-gallery"
                  height="35px"
                  fs={35}
                  color={COLORS.LIGHT_GRAY}
                  fw={400}
                />

                <AtsTitleText
                  text={"Upload"}
                  fs={12}
                  fw={600}
                  textColor={COLORS.PRIMARY}
                />
                <input
                  type="file"
                  name=""
                  accept="image/*"
                  id="chooseVideo"
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </label>
            </Box>
            {Array.from(new Array(9)).map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 83.6,
                  height: 83.6,
                  borderRadius: "10px",
                  bgcolor: "lightcoral",
                }}
                onClick={() =>
                  handleSelectImage("../../../../assets/images/projectImg.png")
                }
              >
                <img
                  style={{ width: "100%" }}
                  src={require("../../../../assets/images/projectImg.png")}
                  alt=""
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </JobCardWrapper>
  );
};

export default ChooseProjectImage;
