import { Box, Grid, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate, useNavigation } from "react-router-dom";
import { FileIcon, MarkerIcon, TimeIcon } from "../../../assets/icons/Icons";
import FlagredIcon from "../../../assets/icons/flagRed.svg";
import AtsCard from "../../../components/AtsCard";
import AtsText from "../../../components/AtsText";
import { BORDERS, COLORS } from "../../../theme";
import { JobListingResponse } from "../../../types/jobsTypes";
import FontUtil from "../../../utils/FontUtil";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { commaSeparatedAddress } from "../../../utils/stringUtils";
import { useAppDispatch } from "../../../redux/store";
import { setSelectedJob } from "../../../redux/slices/jobSlice";
import { countryAbbreviations } from "../../../utils/countryAbbreviations";

interface IPostedCardProps {
  index: number;
  isBottomHide?: boolean;
  job: JobListingResponse;
}

const PostedCard = ({ index, job, isBottomHide }: IPostedCardProps) => {
  const { font11, font12, font16, font14 } = FontUtil();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getJobPriority = (priority: string) => {
    if (priority === "High") {
      return "HIGH";
    }
    return priority;
  };
  return (
    <Grid
      key={index}
      sx={{ width: "100%" }}
      item
      xs={12}
      sm={4}
      md={4}
      lg={4}
      xl={4}
    >
      <motion.div
        whileHover={{ scale: 1.02, translateY: -5 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <AtsCard>
          <Stack gap={1} px={1}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"20px"}
              sx={{ minHeight: 17 }}
              py={1}
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    bgcolor: getJobStatus(job.job_status).color,
                    borderRadius: "20px",
                  }}
                ></Box>
                <AtsTitleText
                  fs={11}
                  fw={700}
                  textColor={getJobStatus(job.job_status).color}
                  text={getJobStatus(job.job_status).text}
                />
              </Stack>
              {job?.job_priority === "High" && (
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <img src={FlagredIcon} alt="Nvidia Logo" />
                  <AtsText
                    sx={{
                      ...font11,
                      fontWeight: 700,
                      color: "#e63030",
                      textTransform: "uppercase",
                    }}
                    text={`${getJobPriority(job?.job_priority)} PRIORITY`}
                  />
                </Stack>
              )}
            </Stack>
            <Stack
              sx={{
                height: "44px",
              }}
            >
              <AtsText
                text={job?.title}
                sx={{
                  ...font16,
                  fontWeight: 600,
                  color: "#231f20",
                }}
              />
            </Stack>
            <Stack
              pt={2}
              pb={3}
              sx={{
                ...(!isBottomHide && { borderBottom: BORDERS.LIGHT_GRAY }),
              }}
              gap={1}
            >
              <Stack
                sx={{ height: "22px" }}
                direction={"row"}
                alignItems={"center"}
                gap={1.5}
              >
                <Box
                  sx={{
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <AtsIcon
                    icon="icon-location"
                    height="16px"
                    color={COLORS.LIGHT_GRAY}
                  />
                </Box>
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                    clear: "both",
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "220px",
                  }}
                  text={commaSeparatedAddress({
                    location: job.job_address_locality,
                    country: job?.job_address_country
                      ? countryAbbreviations[job?.job_address_country]
                      : "",
                  })}
                />
              </Stack>
              <Stack
                sx={{ height: "22px" }}
                direction={"row"}
                gap={1}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <AtsIcon
                    icon="icon-clock"
                    height="16px"
                    color={COLORS.LIGHT_GRAY}
                  />
                </Box>
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                  }}
                  text={`${job.job_type}`}
                />
              </Stack>
              <Stack
                sx={{ height: "22px" }}
                direction={"row"}
                gap={1}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <AtsIcon
                    icon="icon-document-text"
                    fs={16}
                    height="16px"
                    color={COLORS.LIGHT_GRAY}
                  />
                </Box>
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                  }}
                  text={`${job.nid}`}
                />
              </Stack>
              <Stack
                sx={{ height: "22px" }}
                direction={"row"}
                gap={1}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <AtsIcon
                    icon="icon-monitor"
                    fs={16}
                    height="16px"
                    color={COLORS.LIGHT_GRAY}
                  />
                </Box>
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                  }}
                  text={`${job.work_mode}`}
                />
              </Stack>
            </Stack>
            {!isBottomHide && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                py={"5px"}
              >
                <AtsText
                  sx={{
                    ...font12,
                    fontWeight: 500,
                    color: COLORS.DARK_GRAY,
                  }}
                  text={`0 Submissions`}
                />
                <AtsText
                  sx={{
                    ...font12,
                    fontWeight: 600,
                    color: COLORS.PRIMARY,
                    cursor: "pointer",
                  }}
                  text={`View Job`}
                  onClick={() => {
                    dispatch(setSelectedJob(job?.nid));
                    navigate(`/jobs`);
                  }}
                />
              </Stack>
            )}
          </Stack>
        </AtsCard>
      </motion.div>
    </Grid>
  );
};

export default PostedCard;

export const getJobStatus = (status) => {
  if (status === "Open") {
    return {
      color: COLORS.GREEN,
      text: "ACTIVE",
    };
  } else if (status === "Offer Out") {
    return {
      color: COLORS.ORANGE,
      text: "REVIEWING",
    };
  } else if (status === "Filled") {
    return {
      color: COLORS.RED,
      text: "INACTIVE",
    };
  } else {
    return {
      color: COLORS.RED,
      text: "ARCHIVED",
    };
  }
};
