import axios from "axios";
import { API_GATEWAY } from "../utils/url";
import { toast } from "react-toastify";

export const configureAxiosDefaults = () => {
  axios.defaults.baseURL = API_GATEWAY;
};

//request interceptor
// axios.interceptors.request.use(
//   (config: any) => {
//     if (!config.headers["Authorization"]) {
//       config.headers["Authorization"] = `Bearer ${localStorage.getItem(
//         "accessToken"
//       )}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        toast.error(
          `An error occurred: ${error.response.status} - ${
            error.response.data.message || "Unknown error"
          }`
        );
        localStorage.clear();
        window.location.href = "/login";
      } else if (error.response.status === 401) {
        toast.error("Unauthorized: Please log in again.");
        localStorage.clear();
        window.location.href = "/login";
      } else {
        toast.error(
          `An error occurred: ${error.response.status} - ${
            error.response.data.message || "Unknown error"
          }`
        );
      }
    } else if (error.request) {
      toast.error("No response from server. Please try again later.");
    } else {
      toast.error("An unexpected error occurred: " + error.message);
    }
    return Promise.reject(error);
  }
);
