import { Box, IconButton, Popover, Stack } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import {
  Appointment,
  AppointmentStatusCode,
  EVENT_STATUS_BG_COLORS,
  EVENT_STATUS_COLORS,
  EventItem,
} from "../../../types/calendarTypes";
import { formatTimeRange } from "../../../utils/dateUtils";
import CtoInterview from "./CtoInterview";
import InvoiceDue from "./InvoiceDue";
import InvoiceOverdue from "./InvoiceOverdue";
import Contract from "./Contract";
import { Close } from "@mui/icons-material";

const AppointmentEvent = ({
  appointment,
  isMonthView,
  isAllday,
}: {
  appointment: EventItem;
  isMonthView?: boolean;
  isAllday?: boolean;
}) => {
  const { data, allDay, start, end } = appointment;
  const { status, text, length } = data.appointment as Appointment;
  const background = EVENT_STATUS_BG_COLORS[status as AppointmentStatusCode];
  const color = EVENT_STATUS_COLORS[status as AppointmentStatusCode];
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Stack
        gap={"5px"}
        p={1}
        sx={{
          bgcolor: background,
          borderRadius: "5px",
          ...(isMonthView || isAllday
            ? { overflow: "hidden" }
            : { height: "100%" }),
        }}
        onClick={handleClick}
      >
        <Stack
          // sx={{ height: "100%" }}
          direction={"row"}
          // alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack>
            <AtsTitleText textColor={color} fs={12} text={text} />
          </Stack>
          <Stack>
            <AtsTitleText textColor={color} fs={12} text={length} />
          </Stack>
        </Stack>
        {status === AppointmentStatusCode.due && (
          <AtsTitleText
            fs={12}
            text={"#00800002"}
            textColor={COLORS.LIGHT_GRAY}
          />
        )}
        {status === AppointmentStatusCode.interview && (
          <AtsTitleText
            fs={12}
            text={formatTimeRange(start, end)}
            textColor={COLORS.LIGHT_GRAY}
          />
        )}
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "10px !important",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            p: "22px",
            width: 300,
            bgcolor: "white",
            boxShadow: "0 4px 24px 0 rgba(0, 0, 0, 0.24)",
            borderRadius: "8px",
          }}
        >
          <Stack>
            <AtsTitleText text={text} fs={20} fw={600} />
            <Stack
              pt={"20px"}
              pb={"15px"}
              sx={{
                ...(status === AppointmentStatusCode.interview && {
                  borderBottom: BORDERS.GRAY,
                }),
              }}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                <AtsIcon
                  icon="icon-calendar-1"
                  color={COLORS.PRIMARY}
                  height="16px"
                />
                <AtsTitleText
                  text={moment(start).format("ddd,MMMM D")}
                  textColor={COLORS.PRIMARY}
                />
              </Stack>
              {status === AppointmentStatusCode.interview && (
                <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                  <AtsIcon
                    icon="icon-clock"
                    color={COLORS.PRIMARY}
                    height="16px"
                  />
                  <AtsTitleText
                    text={formatTimeRange(start, end)}
                    textColor={COLORS.PRIMARY}
                  />
                </Stack>
              )}
            </Stack>
            {status === AppointmentStatusCode.Overdue ? (
              <InvoiceOverdue />
            ) : status === AppointmentStatusCode.due ? (
              <InvoiceDue />
            ) : status === AppointmentStatusCode.contractStart ||
              status === AppointmentStatusCode.contractEnd ? (
              <Contract contractStatus={status} />
            ) : (
              <CtoInterview />
            )}
          </Stack>
          <Box
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
            }}
          >
            <IconButton color="inherit" onClick={handleClose}>
              <Close sx={{ color: COLORS.LIGHT_GRAY, fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default AppointmentEvent;
