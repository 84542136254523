import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

const FontUtil = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery(theme.breakpoints.up("xxl"));

  const font10 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 9 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 10 };
    } else if (isLarge) {
      return { fontSize: 12 };
    } else {
      return { fontSize: 10 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font11 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 9 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 11 };
    } else if (isLarge) {
      return { fontSize: 12 };
    } else {
      return { fontSize: 11 };
    }
  }, [isTablet, isDesktop, isLarge]);

  const font12 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 10 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 12 };
    } else if (isLarge) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 12 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font13 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 12 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 13 };
    } else if (isLarge) {
      return { fontSize: 14 };
    } else {
      return { fontSize: 13 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);
  const font14 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 12 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 14 };
    } else if (isLarge) {
      return { fontSize: 16 };
    } else {
      return { fontSize: 14 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font16 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 14 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 16 };
    } else if (isLarge) {
      return { fontSize: 17 };
    } else {
      return { fontSize: 16 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);
  const font17 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 15 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 17 };
    } else if (isLarge) {
      return { fontSize: 18 };
    } else {
      return { fontSize: 17 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font18 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 16 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 18 };
    } else if (isLarge) {
      return { fontSize: 19 };
    } else {
      return { fontSize: 18 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);
  const font20 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 18 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 20 };
    } else if (isLarge) {
      return { fontSize: 21 };
    } else {
      return { fontSize: 20 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);
  const font22 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 20 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 22 };
    } else if (isLarge) {
      return { fontSize: 23 };
    } else {
      return { fontSize: 22 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font24 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 22 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 24 };
    } else if (isLarge) {
      return { fontSize: 25 };
    } else {
      return { fontSize: 24 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font34 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 32 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 34 };
    } else if (isLarge) {
      return { fontSize: 35 };
    } else {
      return { fontSize: 32 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font30 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 28 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 30 };
    } else if (isLarge) {
      return { fontSize: 32 };
    } else {
      return { fontSize: 30 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font36 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 34 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 36 };
    } else if (isLarge) {
      return { fontSize: 37 };
    } else {
      return { fontSize: 36 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  const font48 = useMemo(() => {
    if (isMobile) {
      return { fontSize: 46 };
    } else if (isTablet || isDesktop) {
      return { fontSize: 48 };
    } else if (isLarge) {
      return { fontSize: 49 };
    } else {
      return { fontSize: 48 };
    }
  }, [isMobile, isTablet, isDesktop, isLarge]);

  return {
    font10,
    font11,
    font12,
    font13,
    font14,
    font16,
    font17,
    font18,
    font20,
    font22,
    font24,
    font30,
    font34,
    font36,
    font48,
  };
};

export default FontUtil;
