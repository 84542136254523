import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { TaskCollapseIcon } from "../../assets/icons/Icons";
import AtsText from "../../components/AtsText";
import { getAuthState } from "../../redux/slices/authSlice";
import { getDashboardState } from "../../redux/slices/dashboardSlice";
import { useAppSelector } from "../../redux/store";
import EmployerSupport from "./EmployerSupport/EmployerSupport";
import Conversations from "./EmployerSupport/modules/Conversations";
import Interviews from "./Interviews/Interviews";
import RecentlyPostedJobs from "./PostedJobs/RecentlyPostedJobs";
import ResourcesTips from "./ResourcesTips/ResourcesTips";
import TaskSidebar from "./Taskbar/components/TaskSidebar";
import Taskbar from "./Taskbar/Taskbar";
import TopPriorities from "./TopPriorities/TopPriorities";

const Dashboard = () => {
  const { isOpenChatWindow } = useAppSelector(getDashboardState);
  const { userInfo } = useAppSelector(getAuthState);
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className="main-container"
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <div
        // ref={domNode}
        style={{
          overflowX: "hidden",
          height: "100%",
          flex: 1,
        }}
      >
        <Box
          sx={{
            mt: 4,
            maxWidth: isLargeXl ? "1150px" : "786px",
            mx: "auto",
            px: 2,
            position: "relative",
          }}
        >
          <AtsText
            text={`Welcome back, ${userInfo?.first_name ?? "User"}!`}
            sx={{ fontSize: 32, fontWeight: 600, color: "#231f20" }}
          />

          <TopPriorities />
          <Box pt={8} pb={4}>
            <Interviews />
          </Box>
          <Box py={4}>
            <RecentlyPostedJobs />
          </Box>
          <Box py={4}>
            <EmployerSupport />
          </Box>
          <Box py={4}>
            <ResourcesTips />
          </Box>
          <AnimatePresence>
            {isOpenChatWindow && (
              <motion.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                exit={{ y: "100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 22,
                  width: 430,
                  background: "white",
                  zIndex: 1000,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: 600,
                }}
              >
                <Conversations />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </div>
      {!isMobile && <Taskbar />}
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            right: 0,
            top: 90,
            bgcolor: "white",
            width: "23px",
            boxShadow: "0 0 50px 0 rgba(82, 63, 105, .15)",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <TaskCollapseIcon />
          <Drawer
            sx={{}}
            PaperProps={{
              sx: {
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                width: isMobile ? "80%" : "70%",
                overflow: "hidden",
                backgroundColor: "#e9ecf2",
              },
            }}
            anchor="right"
            open={isOpen}
            onClose={() => setIsOpen(!isOpen)}
          >
            <TaskSidebar isOpen={isOpen} />
          </Drawer>
        </Box>
      )}
    </div>
  );
};

export default Dashboard;
