import { ArrowForwardIos } from "@mui/icons-material";
import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import AtsText from "../../../../../../../../../components/AtsText";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import MakeAnOfferDialog from "./MakeAnOfferDialog";
import { useState } from "react";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { setIsOpenMakeAnOfferDialog } from "../../../../../../../../../redux/slices/makeAnOfferSlice";

const NewContract = () => {
  const { font14, font10, font18 } = FontUtil();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useAppDispatch();

  const contractData = [
    {
      key: "Your Offer-Rate",
      value: "$65 / Per Hr",
    },
    {
      key: "Duration",
      value: "June 24, 2024 to July 07, 2024",
    },
    {
      key: "How many hours per week?",
      value: "40",
    },
    {
      key: "How many days per week?",
      value: "5",
    },
    {
      key: "How many hours a day?",
      value: "8 Hrs",
    },
  ];
  return (
    <Stack mt={4}>
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={8.5} xl={9}>
          <Stack
            sx={{
              maxWidth: isLargeScreen
                ? "80%"
                : isDesktopScreen
                ? "85%"
                : "100%",
            }}
          >
            <Stack
              direction={"row"}
              gap={0.5}
              sx={{
                bgcolor: BG_COLORS.PRIMARY,
                px: 3,
                py: 1.5,
                borderRadius: 2,
              }}
            >
              <AtsText
                sx={{
                  ...font14,
                  color: COLORS.BLACK,
                  fontWeight: 400,
                }}
                text={"Hourly Rate: "}
              />
              <AtsText
                sx={{
                  ...font14,
                  color: COLORS.BLACK,
                  fontWeight: 600,
                }}
                text={" $40 - $100 "}
              />
              <AtsText
                sx={{
                  ...font14,
                  color: COLORS.BLACK,
                  fontWeight: 400,
                }}
                text={" / Per Hr"}
              />
            </Stack>

            <Stack mt={3}>
              <Grid container spacing={3} py={2}>
                {contractData.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <Stack gap={0.5}>
                      <AtsText
                        sx={{
                          ...font10,
                          fontWeight: 500,
                          color: "#6d6d6d",
                        }}
                        text={item.key}
                      />
                      <AtsText
                        sx={{
                          ...font14,
                          fontWeight: 600,
                          color: "#231f20",
                        }}
                        text={item.value}
                      />
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3.5} xl={3}>
          <Stack
            sx={{ px: 3, py: 2, bgcolor: BG_COLORS.GRAY, borderRadius: 2 }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <AtsText
                sx={{ ...font18, fontWeight: 600, color: COLORS.BLACK }}
                text="Summary"
              />
              <AtsText
                sx={{ ...font10, fontWeight: 600, color: COLORS.LIGHT_GRAY }}
                text="You won't be charged yet"
              />
            </Stack>

            <Stack gap={3} sx={{ pt: 2, pb: 2 }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                    borderBottom: "1px solid",
                  }}
                  text="Rate per hour"
                />
                <AtsText
                  sx={{
                    ...font14,
                    color: COLORS.BLACK,
                    fontWeight: 500,
                  }}
                  text="$65.00"
                />
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                    borderBottom: "1px solid",
                  }}
                  text="40 Hrs x 2 Wk"
                />
                <AtsText
                  sx={{
                    ...font14,
                    color: COLORS.BLACK,
                    fontWeight: 500,
                  }}
                  text="$5,200.00"
                />
              </Stack>
              <Stack sx={{ pt: 1.3 }}>
                <div className="deviderBorder"></div>
              </Stack>
            </Stack>
            <Stack gap={3.5} pb={2}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <AtsText
                  sx={{
                    ...font14,
                    fontWeight: 400,
                    color: COLORS.BLACK,
                  }}
                  text="Total"
                />
                <AtsText
                  sx={{
                    ...font14,
                    color: COLORS.BLACK,
                    fontWeight: 500,
                  }}
                  text="$5,200.00"
                />
              </Stack>

              <AtsRoundedButton
                text="Make an Offer"
                endIcon={<ArrowForwardIos />}
                onClick={() => {
                  dispatch(setIsOpenMakeAnOfferDialog(true));
                }}
              />
              <Stack>
                <AtsText
                  sx={{
                    ...font10,
                    fontWeight: 400,
                    color: COLORS.LIGHT_GRAY,
                  }}
                  text="Longer engagements are billed every two weeks. For shorter engagements, you will be charged at time of completion."
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <MakeAnOfferDialog />
    </Stack>
  );
};

export default NewContract;
