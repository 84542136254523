import { Container, Stack } from "@mui/material";
import {
  CalenderActiveIcon,
  TimerIcon,
} from "../../../../../../../../assets/icons/Icons";
import CandidateImg from "../../../../../../../../assets/images/candidate.png";
import AtsText from "../../../../../../../../components/AtsText";
import { getInterviewState } from "../../../../../../../../redux/slices/interviewSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../theme";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";

const InterviewScheduled = () => {
  const { count, selectedDate, selectedSlot } =
    useAppSelector(getInterviewState);
  return (
    <>
      <Stack
        sx={{
          border: BORDERS.GRAY,
          borderRadius: 2,
          mt: 2,
          height: "440px",
        }}
      >
        <Container maxWidth="sm">
          <Stack mt={7}>
            <AtsText
              text={"Success! Your interview is scheduled."}
              sx={{ fontSize: 16, fontWeight: 500, textAlign: "center" }}
            />
            <Stack pt={2} gap={1}>
              <Stack
                sx={{
                  borderRadius: 2,
                  bgcolor: BG_COLORS.GREEN,
                  px: 3,
                  py: 1,
                }}
              >
                <AtsText
                  text={
                    "A Calendar invitation has been sent to your email address"
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: COLORS.GREEN,
                    textAlign: "center",
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  border: BORDERS.GRAY,
                  borderRadius: 2,
                  px: 3,
                  py: 4,
                }}
              >
                <Stack gap={2}>
                  <AtsText
                    text={"Meeting Details"}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: COLORS.LIGHT_GRAY,
                    }}
                  />
                  <Stack direction={"row"}>
                    <Stack gap={2} sx={{ flex: 1 }}>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsText
                          text={`${count} Mins`}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <CalenderActiveIcon />
                        <AtsText
                          text={dateFormatDDDDMMMMD(selectedDate)}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsText
                          text={selectedSlot}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      gap={2}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        borderLeft: BORDERS.GRAY,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "center" }}
                      >
                        <img
                          src={CandidateImg}
                          alt="Nvidia Logo"
                          style={{
                            height: "auto",
                            marginRight: "10px",
                            width: "66px",
                            borderRadius: "100%",
                          }}
                        />
                      </Stack>
                      <AtsText
                        text={"Trevor Cunningham"}
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: COLORS.BLACK,
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={1}>
                    <AtsText
                      text={"Web Conferencing Details"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: COLORS.LIGHT_GRAY,
                      }}
                    />
                    <Stack direction={"row"} gap={1}>
                      <AtsText
                        text={
                          "A meeting link is created on the email shared by you"
                        }
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: COLORS.BLACK,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default InterviewScheduled;
