import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import Chart from "react-apexcharts";

const timeDataMap = {
  0: {
    data: [23, 34, 45, 56, 67],
    categories: ["Mon", "Tue", "Wed", "Thu", "Fri"],
  },
  1: { data: [67], categories: ["Jan"] },
  2: { data: [65, 54, 43], categories: ["Jan", "Feb", "Mar"] },
  3: {
    data: [44, 55, 41, 55, 22, 43],
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  },
};

const RecentActivity = () => {
  const [selectedTime, setSelectedTime] = useState(0);
  const generateSeries = () => {
    const { data } = timeDataMap[selectedTime];
    return [
      { name: "Profiles Viewed", data },
      { name: "Jobs Created", data: data.map((d, i) => d - (i % 2) * 10) },
      { name: "Hired", data: data.map((d, i) => d - (i % 3) * 20) },
    ];
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
      fontFamily: "Inter",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 2,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    xaxis: { categories: timeDataMap[selectedTime].categories },
    yaxis: { title: { text: "" } },
    fill: { opacity: 1, colors: ["#2ba899", "#3366cc", "#63abfd"] },
    colors: ["#2ba899", "#3366cc", "#63abfd"],
    tooltip: { y: { formatter: (val: number) => `${val}k` } },
    legend: {
      markers: { shape: "circle", size: 5, offsetX: -2, offsetY: 0 },
      offsetX: 20,
      offsetY: 10,
      horizontalAlign: "left",
      itemMargin: {
        horizontal: 10, // Add horizontal spacing between items
        vertical: 5, // Add vertical spacing between items
      },
    },
  };

  const handleClick = (index: number) => setSelectedTime(index);
  return (
    <>
      <Box pb={"16px"}>
        <AtsTitleText text={"Recent activity"} fs={18} fw={600} />
      </Box>
      <Box
        pb={"15px"}
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsTitleText
          text={"You last opened this project 1w ago"}
          fs={12}
          fw={400}
          textColor={"#000"}
        />
      </Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 6 }}
        pt={"20px"}
      >
        <Box
          sx={{
            flex: { xs: "1 1 auto", md: "0 0 55%" },
            mb: { xs: 2, md: 0 },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <AtsTitleText
              text={"Activity"}
              fs={14}
              fw={600}
              textColor={COLORS.DARK_GRAY}
            />
            <Stack direction={"row"} alignItems={"center"}>
              {["1W", "1M", "3M", "6M"].map((tab, index) => (
                <Stack
                  key={index}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"8px"}
                    px={"5px"}
                    mx={"5px"}
                    pb={"3px"}
                    sx={{
                      cursor: "pointer",
                      height: 13,
                      borderBottom:
                        index === selectedTime
                          ? "1px solid var(--primary)"
                          : "1px solid transparant",
                    }}
                    onClick={() => handleClick(index)}
                  >
                    <AtsTitleText
                      text={tab}
                      fs={11}
                      fw={600}
                      textColor={
                        index === selectedTime ? COLORS.PRIMARY : "#c4c4c4"
                      }
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Chart
            options={options}
            series={generateSeries()}
            type="bar"
            height={192}
          />
        </Box>
        <Stack
          sx={{
            flex: { xs: "1 1 auto", md: "0 0 45%" },
          }}
          gap={2}
        >
          <AtsTitleText
            text={"Collaborator activity"}
            fs={14}
            fw={600}
            textColor={COLORS.DARK_GRAY}
          />
          <Stack gap={"20px"}>
            {renderCollaborator()}
            {renderCollaborator()}
            {renderCollaborator()}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RecentActivity;

const renderCollaborator = () => {
  return (
    <Stack gap={"14px"} direction={"row"} alignItems={"center"}>
      <Avatar src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" />
      <Stack>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <span
            style={{
              fontWeight: 600,
            }}
          >
            Daniel Anderson
          </span>{" "}
          created a new job
        </Typography>
        <AtsTitleText
          text={"49m ago"}
          fw={400}
          fs={11}
          textColor={COLORS.LIGHT_GRAY}
        />
      </Stack>
    </Stack>
  );
};
