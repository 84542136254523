import { Box, Stack } from "@mui/material";
import React from "react";
import AtsText from "../../../../../../../../../components/AtsText";
import { COLORS } from "../../../../../../../../../theme";

const InterviewDetails = () => {
  const details = [
    {
      key: "Interview Type",
      value: "Technical Round One",
    },
    {
      key: "Web Conferencing Details",
      value: "Google Meet",
    },
    {
      key: "Meeting Name",
      value: "CTO - Interview Round One",
    },
    {
      key: "Name Of Interviewer",
      value: "Daniel Anderson",
    },
    {
      key: "Email Of Interviewer",
      value: "daniel.anderson@gmail.com",
    },
    {
      key: "Details Of Meeting",
      value:
        "This round focuses on assessing the ability to set a technology direction",
    },
  ];
  return (
    <Box
      sx={{
        py: 3,
      }}
    >
      {details.map((detail, index) => (
        <Stack
          key={index}
          direction={"row"}
          sx={{
            mb: 1.5,
          }}
        >
          <Stack sx={{ width: "50%" }}>
            <AtsText
              text={detail.key}
              sx={{ fontSize: 14, color: COLORS.LIGHT_GRAY }}
            />
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <AtsText
              text={detail.value}
              sx={{ fontSize: 14, color: COLORS.BLACK }}
            />
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default InterviewDetails;
