import { ReactNode } from "react";
import {
  AdminCenterIcon,
  AvailabilityIcon,
  BillingIcon,
  BookmarkIcon,
  CalenderIcon,
  CommunityIcon,
  HelpIcon,
  HomeIcon,
  InsightsIcon,
  JobsIcon,
  MessagingIcon,
  MyTeamIcon,
  ProjectsIcon,
  UserIcon,
  UserManagementIcon,
} from "../assets/icons/Icons";
import Dashboard from "../pages/Dashboard/Dashboard";
import { ATS_ROUTES } from "./routes";
import Jobs from "../pages/Jobs/Jobs";
import Projects from "../pages/Projects/Projects";
import Calendar from "../pages/Calendar/Calendar";
import Messaging from "../pages/Messaging/Messaging";
import Insights from "../pages/Insights/Insights";
import Availability from "../pages/Availability/Availability";
import Billing from "../pages/Billing/Billing";
import AdminCenter from "../pages/AdminCenter/AdminCenter";
import Help from "../pages/Help/Help";
import Submissions from "../pages/Jobs/Submissions";
import PostOpportunity from "../pages/PostOpportunity/PostOpportunity";
import UserManagement from "../pages/UserManagement/UserManagement";
import UserRoles from "../pages/UserRoles/UserRoles";
import AtsIcon from "../components/AtsIcon";
import { Outlet } from "react-router-dom";
import NoAccess from "../pages/NoAccess";
import CreateNewProject from "../pages/Projects/CreateNewProject";
import ProjectDetails from "../pages/Projects/ProjectDetails";

export interface RoutesProps {
  path: string;
  label: string;
  component: JSX.Element;
  icon: ReactNode;
  activIcon: ReactNode;
  isHide?: boolean;
  isMatch?: string[];
  access?: string[];
  children?: RoutesProps[];
}

export const ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.DASHBOARD,
    label: "Dashboard",
    component: <Dashboard />,
    icon: <HomeIcon />,
    activIcon: <HomeIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.JOBS,
    label: "Jobs",
    component: <Jobs />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.SUBMISSIONS,
    label: "Jobs",
    component: <Submissions />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.PROJECT_DETAILS,
    label: "",
    component: <ProjectDetails />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.POST_OPPORTUNITY,
    label: "",
    component: <PostOpportunity />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.CREATE_PROJECT,
    label: "",
    component: <CreateNewProject />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.NO_ACCESS,
    label: "",
    component: <NoAccess />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.PROJECTS,
    label: "Projects",
    component: <Projects />,
    icon: <ProjectsIcon />,
    activIcon: <ProjectsIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.CALENDAR,
    label: "Calendar",
    component: <Calendar />,
    icon: <CalenderIcon />,
    activIcon: <CalenderIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.MESSAGING,
    label: "Messaging",
    component: <Messaging />,
    icon: <MessagingIcon />,
    activIcon: <MessagingIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.INSIGHTS,
    label: "Insights",
    component: <Insights />,
    icon: <InsightsIcon />,
    activIcon: <InsightsIcon strokeColor="#36C" />,
  },
];
export const SUB_ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.AVAILABILITY,
    label: "Availability",
    component: <Availability />,
    icon: <AvailabilityIcon />,
    activIcon: <AvailabilityIcon strokeColor="#36C" />,
    access: ["Admin", "Team Member"],
  },
  {
    path: ATS_ROUTES.BILLING,
    label: "Billing",
    component: <Billing />,
    icon: <BillingIcon />,
    activIcon: <BillingIcon strokeColor="#36C" />,
    access: ["Admin", "Team Member"],
  },
  {
    path: "",
    label: "Admin Center",
    component: <Outlet />,
    icon: <AdminCenterIcon />,
    access: ["Admin"],
    activIcon: <AdminCenterIcon strokeColor="#36C" />,
    isMatch: [ATS_ROUTES.USER_MANAGEMENT, ATS_ROUTES.USER_ROLES],
    children: [
      {
        path: ATS_ROUTES.USER_MANAGEMENT,
        label: "User Management",
        component: <UserManagement />,
        icon: <AtsIcon icon="icon-people" fw={500} fs={18} color="#000" />,
        activIcon: <AtsIcon icon="icon-people" color="#36C" fw={500} fs={18} />,
      },
      {
        path: ATS_ROUTES.USER_ROLES,
        label: "User Roles",
        component: <UserRoles />,
        icon: <AtsIcon icon="icon-data" fw={500} fs={18} color="#000" />,
        activIcon: <AtsIcon icon="icon-data" color="#36C" fw={500} fs={18} />,
      },
    ],
  },
  {
    path: ATS_ROUTES.HELP,
    label: "Help",
    component: <Help />,
    icon: <HelpIcon />,
    activIcon: <HelpIcon strokeColor="#36C" />,
    access: ["Admin", "Team Member"],
  },
];
export const USER_ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.MY_PROFILE,
    label: "My Profile",
    component: <Availability />,
    icon: <UserIcon />,
    activIcon: <UserIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.MY_TEAM,
    label: "My Team",
    component: <Billing />,
    icon: <MyTeamIcon />,
    activIcon: <MyTeamIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.GETTING_STARTED,
    label: "Getting Started",
    component: <AdminCenter />,
    icon: <BookmarkIcon />,
    activIcon: <BookmarkIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.COMMUNITY,
    label: "Community",
    component: <AdminCenter />,
    icon: <CommunityIcon />,
    activIcon: <CommunityIcon strokeColor="#36C" />,
  },
];
