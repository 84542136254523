import { Box, Stack } from "@mui/material";
import AtsTabs from "../../../../../../../../components/AtsTabs";
import AtsText from "../../../../../../../../components/AtsText";
import {
  getMakeAnOfferState,
  setIsOfferDetails,
  setSelectedOfferTab,
} from "../../../../../../../../redux/slices/makeAnOfferSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { COLORS } from "../../../../../../../../theme";
import FontUtil from "../../../../../../../../utils/FontUtil";
import Offers from "./module/Offers";
import DirectHire from "./module/DirectHire";
import NewContract from "./module/NewContract";

const MakeAnOfferTab = () => {
  const { font20 } = FontUtil();
  const dispatch = useAppDispatch();
  const { selectedOfferTab } = useAppSelector(getMakeAnOfferState);

  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <AtsText
              text="Make An Offer"
              sx={{ ...font20, fontWeight: 600, color: COLORS.BLACK }}
            />
          </Stack>
        </Stack>
        <AtsTabs
          value={selectedOfferTab}
          onChange={(event, newValue) => {
            dispatch(setSelectedOfferTab(newValue));
            dispatch(setIsOfferDetails(false));
          }}
          tabData={["New Contract", "Direct Hire", "Offers"]}
        />
        <Box
          sx={{
            height: "calc(100% - 80px)",
            overflow: "scroll",
            pr: 1,
          }}
        >
          {selectedOfferTab === 2 ? (
            <Offers />
          ) : selectedOfferTab === 1 ? (
            <DirectHire />
          ) : (
            <NewContract />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default MakeAnOfferTab;
