import { Stack } from "@mui/material";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsIcon from "../../../components/AtsIcon";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";

const location = [
  "United States",
  "United Kingdom",
  "South Africa",
  "Australia",
  "Germany",
];

const FilterBudgetRange = () => {
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
        <AtsCustomButton px={0}>
          <AtsIcon icon="icon-arrow-down-1" height="16px" fw={600} />
        </AtsCustomButton>
        <AtsTitleText
          text={"Budget Range"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
    </>
  );
};

export default FilterBudgetRange;
