import React from "react";
import { IColumnProps } from "../../../../../../types/commonType";
import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  FilterIcon,
  InvoiceListIcon,
} from "../../../../../../assets/icons/Icons";
import { BORDERS, COLORS } from "../../../../../../theme";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import { useAppDispatch } from "../../../../../../redux/store";
import { setIsInvoiceView } from "../../../../../../redux/slices/invoicesSlice";
import AtsTextInput from "../../../../../../components/AtsTextInput";

interface InvoiceProps {
  uid: string;
  date: string;
  amount: string;
  status: string;
}

const invoicesData: InvoiceProps[] = [
  {
    uid: "#00000002",
    date: "Jul 19, 2024  |  09:34 am",
    amount: "$3245.00",
    status: "Not Paid",
  },
  {
    uid: "#00000003",
    date: "Jul 19, 2024  |  09:34 am",
    amount: "$3245.00",
    status: "Not Paid",
  },
  {
    uid: "#00000004",
    date: "Jul 19, 2024  |  09:34 am",
    amount: "$3245.00",
    status: "Paid",
  },
  {
    uid: "#00000005",
    date: "Jul 19, 2024  |  09:34 am",
    amount: "$3245.00",
    status: "Paid",
  },
];
const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "uid",
    header: "Invoice no.",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "date",
    header: "Invoice Date",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "amount",
    header: "Amount",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const InvoicesList = () => {
  const dispatch = useAppDispatch();

  const invoiceDetails = () => {
    dispatch(setIsInvoiceView(true));
  };
  const customizeRow = (column: IColumnProps, invoice: InvoiceProps) => {
    switch (column.key) {
      case "uid":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack>
                <InvoiceListIcon />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={500}
                  textColor={"#000"}
                  text={invoice[column.key]}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "date":
      case "amount":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={invoice[column.key]}
              />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={12}
                fw={600}
                text={invoice.status}
                buttonVariant={getStatusOfInvoice(invoice.status)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton px={0.6} onClick={() => invoiceDetails()}>
              <AtsIcon icon="icon-eye" height="16px" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = invoicesData.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Stack gap={"12px"} pt={"10px"} pb={"24px"}>
        <AtsTitleText
          text={"412 invoices"}
          fs={18}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Box width={232}>
          <AtsTextInput
            placeholder="Search invoice no."
            endAdornment={<AtsIcon icon="icon-search-normal-1" />}
            height="42px"
          />
        </Box>
      </Stack>
      <Box>
        <AtsDataTable
          isTopBorder
          data={invoicesData}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={false}
        />
      </Box>
    </>
  );
};

export default InvoicesList;

const getStatusOfInvoice = (status: string) => {
  if (status === "Paid") {
    return "greenContained";
  }
  return "primaryContained";
};
