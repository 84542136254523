import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@mui/icons-material";
import { Box, Popover, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { FilterIcon } from "../../../../../../assets/icons/Icons";
import AtsCheckbox from "../../../../../../components/AtsCheckbox";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { TaskStatusEnum } from "../../../../../../enums/commonEnums";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../theme";
import { IColumnProps } from "../../../../../../types/commonType";
import { useClickOutSide } from "../../../../../../utils/common";
import { toLowerCase } from "../../../../../../utils/stringUtils";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";

interface TasksProps {
  uid: string;
  taskName: string;
  assignees: string[];
  createdOn: string;
}

const invoicesData: TasksProps[] = [
  {
    uid: "1",
    taskName: "Design",
    assignees: [],
    createdOn: "Aug 21, 2024",
  },
  {
    uid: "2",
    taskName: "Project Management",
    assignees: [],
    createdOn: "Aug 21, 2024",
  },
  {
    uid: "3",
    taskName: "UX Design",
    assignees: [],
    createdOn: "Aug 21, 2024",
  },
];
const LEAD_COLUMN: IColumnProps[] = [
  // {
  //   key: "uid",
  //   header: "Invoice no.",
  //   width: "300px",
  //   minWidth: "300px",
  // },
  {
    key: "taskName",
    header: "Task Name",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "assignees",
    header: "Assignees",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "createdOn",
    header: "Created on",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const assigneesData = ["Alex Novak", "Jason Antonio", "Himesh Smith"];

const Tasks = () => {
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [tasks, setTasks] = useState<TasksProps[]>(invoicesData);
  const [indexNumber, setIndexNumber] = useState<number>(0);

  const taskStatuses = Object.keys(TaskStatusEnum).map((status) => ({
    title: toLowerCase(status),
    type: status,
  }));

  const handleTaskNameChange = (value: string, uid: string) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.uid === uid ? { ...task, taskName: value } : task
      )
    );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleAddAssignees = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    uid: string
  ) => {
    setHoveredRow(null);
    setIndexNumber(index);
    // setShow(!show);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (assigne: string) => {
    const preTask = [...tasks];
    const taskObj = tasks[indexNumber] as TasksProps;
    if (taskObj.assignees.includes(assigne)) {
      const updateAssigne: string[] = taskObj.assignees.filter(
        (assign) => assign !== assigne
      );
      const updateTask = {
        ...taskObj,
        assignees: updateAssigne,
      } as TasksProps;

      preTask.splice(indexNumber, 1, updateTask);
      setTasks(preTask);
    } else {
      const updateTask = {
        ...taskObj,
        assignees: [...taskObj.assignees, assigne],
      } as TasksProps;

      preTask.splice(indexNumber, 1, updateTask);
      setTasks(preTask);
    }

    // setActiveDropdown(null);
  };

  const customizeRow = (
    column: IColumnProps,
    invoice: TasksProps,
    index: number
  ) => {
    switch (column.key) {
      case "taskName":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              {hoveredRow === invoice.uid ? (
                <Box>
                  <input
                    className="inputstyle"
                    style={{
                      color: COLORS.BLACK,
                      fontWeight: 400,
                      borderRadius: "8px",
                      border: BORDERS.GRAY,
                      fontSize: "14px",
                      height: "38px",
                      padding: "0px 6px",
                      // width: "100%",
                      background: "white",
                    }}
                    type="text"
                    value={invoice.taskName}
                    onChange={(e) =>
                      handleTaskNameChange(e.target.value, invoice.uid)
                    }
                  />
                </Box>
              ) : (
                <AtsTitleText
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                  text={invoice[column.key]}
                />
              )}
            </Stack>
          </td>
        );
      case "assignees":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack
              // ref={domNode}
              py={1}
              direction={"row"}
              alignItems={"center"}
              gap={"12px"}
              sx={{
                height: 11,
                bgcolor: BG_COLORS.PRIMARY,
                px: "16px",
                borderRadius: "38px",
                width: "fit-content",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={(e) =>
                handleAddAssignees(
                  e as React.MouseEvent<HTMLDivElement>,
                  index,
                  invoice.uid
                )
              }
            >
              {invoice.assignees.length === 0 ? (
                <AtsTitleText text={"Anyone"} textColor={COLORS.PRIMARY} />
              ) : (
                <Stack direction={"row"} alignItems={"center"} gap={"3px"}>
                  {invoice.assignees.slice(0, 2).map((item, index) => (
                    <AtsTitleText
                      key={index}
                      text={`${item},`}
                      textColor={COLORS.PRIMARY}
                    />
                  ))}
                  {invoice.assignees.length > 2 && (
                    <AtsTitleText
                      text={`+${invoice.assignees.length - 2}`}
                      textColor={COLORS.PRIMARY}
                    />
                  )}
                </Stack>
              )}
              <AtsCustomButton px={0} py={0} hoverColor={BG_COLORS.PRIMARY}>
                <AtsIcon
                  icon="icon-arrow-down-1"
                  fw={500}
                  height="16px"
                  color={COLORS.PRIMARY}
                />
              </AtsCustomButton>
            </Stack>

            <Popover
              key={invoice.uid}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                ".MuiPopover-paper": {
                  borderRadius: "10px !important",
                  border: BORDERS.GRAY,
                  // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                },
              }}
            >
              <Box
                sx={{
                  width: 352,
                  height: 278,
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    px: "20px",
                    py: "20px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    height={36}
                    sx={{
                      px: "16px",
                      border: BORDERS.GRAY,
                      borderRadius: "38px",
                    }}
                  >
                    <input
                      type="text"
                      className="customInput"
                      style={{
                        width: "100%",
                      }}
                    />
                    <AtsIcon icon="icon-search-normal-1" height="16px" />
                  </Stack>
                </Box>
                <Box
                  sx={{
                    px: "20px",
                    py: "15px",
                    borderTop: BORDERS.GRAY,
                    borderBottom: BORDERS.GRAY,
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <AtsTitleText
                      text={"Show"}
                      fs={14}
                      fw={400}
                      textColor="#000"
                    />
                    <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                      <AtsTitleText
                        text={"Active"}
                        fs={14}
                        fw={400}
                        textColor="#000"
                      />
                      <AtsIcon
                        icon="icon-arrow-down-1"
                        fw={500}
                        height="16px"
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    height: "calc(100% - 147px)",
                    overflow: "auto",
                    px: "20px",
                    py: "10px",
                  }}
                >
                  <Stack gap={"10px"} sx={{}}>
                    {assigneesData.map((assignes) => {
                      return (
                        <Stack
                          key={assignes}
                          direction={"row"}
                          alignItems={"center"}
                          gap={"5px"}
                        >
                          <AtsCheckbox
                            checked={tasks[indexNumber].assignees.includes(
                              assignes
                            )}
                            onChange={() => handleChange(assignes)}
                          />
                          <AtsTitleText
                            text={assignes}
                            fs={14}
                            fw={400}
                            textColor="#000"
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
            </Popover>
          </td>
        );
      case "createdOn":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={invoice[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton px={0.6}>
              <MoreVert />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = tasks.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
              backgroundColor: value.uid === hoveredRow ? "#f2f6ff" : "white",
            }}
            onMouseEnter={() => setHoveredRow(value.uid)}
            onMouseLeave={() => {
              if (!open) setHoveredRow(null);
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  return (
    <>
      <Stack pb={"24px"}>
        <AtsTitleText
          text={"12 Tasks"}
          fs={18}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Stack direction={"row"} sx={{ pb: "24px" }}>
        <JobsSwitch
          width="40px"
          jobs={taskStatuses}
          active={selectedStatus}
          onChange={(value) => {
            setSelectedStatus(value);
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ pb: "24px" }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          height={30}
          width={213}
          sx={{
            px: "16px",
            border: BORDERS.GRAY,
            borderRadius: "38px",
          }}
        >
          <input
            type="text"
            className="customInput"
            style={{
              width: "100%",
            }}
          />
          <AtsIcon icon="icon-search-normal-1" height="16px" />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"13px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            height={30}
            width={300}
            sx={{
              px: "16px",
              border: BORDERS.GRAY,
              borderRadius: "38px",
            }}
          >
            <input
              type="text"
              className="customInput"
              placeholder="Add new task"
              style={{
                width: "100%",
              }}
            />
          </Stack>
          <AtsRoundedButton text="Add" buttonHeight={32} buttonWidth={84} />
        </Stack>
      </Stack>
      <Box>
        <AtsDataTable
          // isTopBorder
          data={tasks}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={false}
        />
      </Box>
    </>
  );
};

export default Tasks;

// const CustomDropdown = ({ visible, assignees, onSelect }) => {
//   if (!visible) return null;

//   return (
//     <Box
//       sx={{
//         position: "absolute",
//         top: 30,
//         left: 0,
//         zIndex: 1,
//         width: "352px",
//         backgroundColor: "#fff",
//         borderRadius: "10px",
//         border: BORDERS.GRAY,
//         boxShadow: "0 6px 6px 0 rgba(0, 0, 0, 0.16)",
//         // padding: "10px",
//       }}
//     >
//       <Box
//         sx={{
//           width: 352,
//           height: 278,
//           borderRadius: "10px",
//         }}
//       >
//         <Box
//           sx={{
//             px: "20px",
//             py: "20px",
//           }}
//         >
//           <Stack
//             direction={"row"}
//             alignItems={"center"}
//             height={36}
//             sx={{
//               px: "16px",
//               border: BORDERS.GRAY,
//               borderRadius: "38px",
//             }}
//           >
//             <input
//               type="text"
//               className="customInput"
//               style={{
//                 width: "100%",
//               }}
//             />
//             <AtsIcon icon="icon-search-normal-1" height="16px" />
//           </Stack>
//         </Box>
//         <Box
//           sx={{
//             px: "20px",
//             py: "15px",
//             borderTop: BORDERS.GRAY,
//             borderBottom: BORDERS.GRAY,
//           }}
//         >
//           <Stack
//             direction={"row"}
//             alignItems={"center"}
//             justifyContent={"space-between"}
//           >
//             <AtsTitleText text={"Show"} fs={14} fw={400} textColor="#000" />
//             <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
//               <AtsTitleText text={"Active"} fs={14} fw={400} textColor="#000" />
//               <AtsIcon icon="icon-arrow-down-1" fw={500} height="16px" />
//             </Stack>
//           </Stack>
//         </Box>
//         <Box
//           sx={{
//             height: "calc(100% - 147px)",
//             overflow: "auto",
//             px: "20px",
//             py: "10px",
//           }}
//         >
//           <Stack gap={"10px"} sx={{}}>
//             {assigneesData.map((assignes) => {
//               return (
//                 <Stack
//                   key={assignes}
//                   direction={"row"}
//                   alignItems={"center"}
//                   gap={"5px"}
//                 >
//                   <AtsCheckbox
//                     checked={assignees.includes(assignes)}
//                     onChange={() => onSelect(assignes)}
//                   />
//                   <AtsTitleText
//                     text={assignes}
//                     fs={14}
//                     fw={400}
//                     textColor="#000"
//                   />
//                 </Stack>
//               );
//             })}
//           </Stack>
//         </Box>
//       </Box>
//     </Box>
//   );
// };
