import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import StepWrapper from "../../components/common/StepWrapper";
import {
  getPostOpportunityState,
  setIsQuestionRoute,
} from "../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ATS_ROUTES } from "../../router/routes";
import "./css/PostOpportunity.css";
import AlmostThere from "./module/AlmostThere";
import ChooseIndustry from "./module/ChooseIndustry";
import ChooseJobTitle from "./module/ChooseJobTitle";
import DaysPerWeek from "./module/DaysPerWeek";
import EducationalRequrement from "./module/EducationalRequrement";
import HoursPerDay from "./module/HoursPerDay";
import HoursPerWeek from "./module/HoursPerWeek";
import JobBenefits from "./module/JobBenefits";
import JobDescribes from "./module/JobDescribes";
import JobDescription from "./module/JobDescription";
import JobLocation from "./module/JobLocation";
import JobPriority from "./module/JobPriority";
import JobResourceTime from "./module/JobResourceTime";
import JobSkills from "./module/JobSkills";
import JobStep1 from "./module/JobStep1";
import JobStep2 from "./module/JobStep2";
import JobStep3 from "./module/JobStep3";
import JobTerm from "./module/JobTerm";
import JobType from "./module/JobType";
import OfficeTime from "./module/OfficeTime";
import OpportunityPosted from "./module/OpportunityPosted";
import Projects from "./module/Projects";
import Question from "./module/Question";
import ReviewListing from "./module/ReviewListing";
import Stepper from "./module/Stepper";
import VicePresidentBusinessDevlopment from "./module/VicePresidentBusinessDevlopment";
import WorkMode from "./module/WorkMode";
import YearsOfExperience from "./module/YearsOfExperience";
import YourBudget from "./module/YourBudget";

const PostOpportunity = () => {
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    activeStep,
    activeStep2,
    activeStep3,
    isPosted,
    isQuestionRoute,
    jobDetailsById,
  } = useAppSelector(getPostOpportunityState);

  const JobLocationMemo = useMemo(() => <JobLocation />, []);
  const OfficeTimeMemo = useMemo(() => <OfficeTime />, []);
  const WorkModeMemo = useMemo(() => <WorkMode />, []);
  const JobPriorityMemo = useMemo(() => <JobPriority />, []);
  const JobResourceTimeMemo = useMemo(() => <JobResourceTime />, []);
  const JobTermMemo = useMemo(() => <JobTerm />, []);
  const JobTypeMemo = useMemo(() => <JobType />, []);
  const JobDescribesMemo = useMemo(() => <JobDescribes />, []);
  const JobStep1Memo = useMemo(() => <JobStep1 />, []);
  const ChooseIndustryMemo = useMemo(() => <ChooseIndustry />, []);
  const JobSkillsMemo = useMemo(() => <JobSkills />, []);
  const JobDescriptionMemo = useMemo(() => <JobDescription />, []);
  const ChooseJobTitleMemo = useMemo(() => <ChooseJobTitle />, []);
  const JobStep2Memo = useMemo(() => <JobStep2 />, []);
  const ProjectsMemo = useMemo(() => <Projects />, []);
  const VicePresidentBusinessDevlopmentMemo = useMemo(
    () => <VicePresidentBusinessDevlopment />,
    []
  );
  const EducationalMemo = useMemo(() => <EducationalRequrement />, []);
  const YearsOfExperienceMemo = useMemo(() => <YearsOfExperience />, []);
  const ReviewListingMemo = useMemo(() => <ReviewListing />, []);
  const AlmostThereMemo = useMemo(() => <AlmostThere />, []);
  const JobBenefitsMemo = useMemo(() => <JobBenefits />, []);
  const YourBudgetMemo = useMemo(() => <YourBudget />, []);
  const HoursPerDayMemo = useMemo(() => <HoursPerDay />, []);
  const DaysPerWeekMemo = useMemo(() => <DaysPerWeek />, []);
  const HoursPerWeekMemo = useMemo(() => <HoursPerWeek />, []);
  const JobStep3Memo = useMemo(() => <JobStep3 />, []);

  const memoMap = {
    "Direct hire": {
      1: JobStep1Memo,
      2: JobDescribesMemo,
      3: JobTypeMemo,
      4: JobTermMemo,
      5: JobPriorityMemo,
      6: WorkModeMemo,
      7: OfficeTimeMemo,
      8: JobLocationMemo,
    },
    default: {
      1: JobStep1Memo,
      2: JobDescribesMemo,
      3: JobTypeMemo,
      4: JobTermMemo,
      5: JobResourceTimeMemo,
      6: HoursPerWeekMemo,
      7: DaysPerWeekMemo,
      8: HoursPerDayMemo,
      9: JobPriorityMemo,
      10: WorkModeMemo,
      11: OfficeTimeMemo,
      12: JobLocationMemo,
    },
  };

  const step2Map = {
    1: JobStep2Memo,
    2: ChooseJobTitleMemo,
    3: JobDescriptionMemo,
    4: JobSkillsMemo,
    5: ChooseIndustryMemo,
  };

  const step3Map = {
    1: JobStep3Memo,
    2: EducationalMemo,
    3: YearsOfExperienceMemo,
    4: YourBudgetMemo,
    5: JobBenefitsMemo,
    6: AlmostThereMemo,
    7: ReviewListingMemo,
    8: VicePresidentBusinessDevlopmentMemo,
    9: ProjectsMemo,
  };

  const renderComponent = () => {
    const stepsMap =
      jobDetailsById.job_type === "Direct hire"
        ? memoMap["Direct hire"]
        : memoMap.default;

    if (activeStep <= getStep1(jobDetailsById.job_type) && activeStep2 === 0) {
      return stepsMap[activeStep] || JobStep1Memo;
    } else if (
      activeStep === getStep1(jobDetailsById.job_type) &&
      activeStep2 <= 5 &&
      activeStep3 === 0
    ) {
      return step2Map[activeStep2] || JobStep2Memo;
    } else if (activeStep2 === 5 && activeStep3 <= 9) {
      return step3Map[activeStep3] || JobStep3Memo;
    }
  };

  return (
    <StepWrapper
      headerText="Post an Opportunity"
      backText="Back to jobs"
      backRoute={() => navigate(ATS_ROUTES.JOBS)}
      questionAction={() => dispatch(setIsQuestionRoute(true))}
      isQuestionRoute={isQuestionRoute}
      isFinalStep={isPosted}
      QuestionComponent={Question}
      FinalStepComponent={OpportunityPosted}
      StepperComponent={Stepper}
      renderStepComponent={renderComponent}
      isImgShow={activeStep !== 1 && activeStep2 !== 1 && activeStep3 !== 1}
    />
  );
};

export default PostOpportunity;

export const getStep1 = (type: string) => {
  return type === "Direct hire" ? 8 : 12;
};
