import { Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import InvoiceComponent from "./InvoiceComponent";

const invoiceOverDueData = [
  {
    invoiceNumber: "#0080002",
    amount: "$ 3250.00",
  },
  {
    invoiceNumber: "#0080003",
    amount: "$ 1250.00",
  },
];

const InvoiceOverdue = () => {
  return (
    <Stack
      gap={"20px"}
      sx={{
        height: 235,
        overflow: "auto",
      }}
    >
      {invoiceOverDueData.map((item, index) => {
        return (
          <InvoiceComponent
            invoiceNumber={item.invoiceNumber}
            amount={item.amount}
            key={index}
            renderBottomView={
              <Stack
                pt={"15px"}
                pb={"5px"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <AtsTitleText
                  text={"View invoice"}
                  fs={12}
                  fw={500}
                  textColor={COLORS.PRIMARY}
                />
                <AtsTitleText
                  text={"Pay now"}
                  fs={12}
                  fw={500}
                  textColor={COLORS.PRIMARY}
                />
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default InvoiceOverdue;
