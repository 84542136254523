import { Box, Stack } from "@mui/material";
import React from "react";
import AtsText from "../../../../../../../../../components/AtsText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";
import {
  AboveAverageIcon,
  AverageIcon,
  BelowAverageIcon,
  ExceptionalIcon,
  UnsatisfactoryIcon,
} from "../../../../../../../../../assets/icons/Icons";

interface IProps {
  title: string;
  des: string;
  rating: number;
  comments: string;
  onChange: (comment: string) => void;
}

const SkillsComponent = ({
  title,
  des,
  rating,
  comments,
  onChange,
}: IProps) => {
  return (
    <Stack gap={1}>
      <AtsText
        text={title}
        sx={{ fontSize: 14, fontWeight: 600, color: "#000" }}
      />
      <AtsText
        text={des}
        sx={{ fontSize: 12, fontWeight: 400, color: "#000" }}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack direction={"row"} gap={0.5}>
          {Array.from(new Array(5)).map((_, index) => (
            <Box
              sx={{
                width: 35,
                height: 35,
                borderRadius: 1,
                border: renderImojiComponent(index + 1, rating).border,
                bgcolor: renderImojiComponent(index + 1, rating).bgColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {renderImojiComponent(index + 1, rating).icon}
            </Box>
          ))}
        </Stack>
        {renderTextComponent(rating)}
      </Stack>
      <Stack sx={{ mt: 1 }}>
        <AtsTextInput
          isTextArea
          multiline
          rows={2}
          header={"Comments"}
          value={comments}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => onChange(event.target.value)}
        />
      </Stack>
    </Stack>
  );
};

export default SkillsComponent;

const renderTextComponent = (rating: number) => {
  return (
    <Stack direction={"row"} gap={0.5}>
      <AtsText
        text={"Your Rating:"}
        sx={{ fontSize: 12, fontWeight: 400, color: "#000" }}
      />
      <AtsText
        text={
          rating === 5
            ? "Exceptional"
            : rating === 4
            ? "Above Average"
            : rating === 3
            ? "Average"
            : rating === 2
            ? "Below Average"
            : "Unsatisfactory"
        }
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color:
            rating === 5
              ? "#098c35"
              : rating === 4
              ? "#4eb111"
              : rating === 3
              ? "#ec7249"
              : rating === 2
              ? "#ec7249"
              : "#df3232",
        }}
      />
    </Stack>
  );
};

const renderImojiComponent = (index: number, rating: number) => {
  const ratingsMap = {
    5: {
      icon: <ExceptionalIcon />,
      border: "#098c35",
      bgColor: "#098c35",
      lightBg: "#daf3e5",
    },
    4: {
      icon: <AboveAverageIcon />,
      border: "#4eb111",
      bgColor: "#4eb111",
      lightBg: "#ecffe1",
    },
    3: {
      icon: <AverageIcon />,
      border: "#ffcd34",
      bgColor: "#ffcd34",
      lightBg: "#fff8e3",
    },
    2: {
      icon: <BelowAverageIcon />,
      border: "#ec7249",
      bgColor: "#ec7249",
      lightBg: "#ffeee8",
    },
    1: {
      icon: <UnsatisfactoryIcon />,
      border: "#df3232",
      bgColor: "#df3232",
      lightBg: "#ecffe1",
    },
  };

  const defaultStyles = {
    border: "1px solid #c4c4c4",
    bgColor: "#e6e6e6",
  };

  if (index === rating) {
    const { icon, border, bgColor } = ratingsMap[rating];
    return { icon, border, bgColor };
  }

  if (rating >= 1 && rating <= 5) {
    const { border, lightBg } = ratingsMap[rating];
    if (index < rating) {
      return {
        icon: index,
        border: `1px solid ${border}`,
        bgColor: lightBg,
      };
    }
  }

  return {
    icon: index,
    ...defaultStyles,
  };
};
